import { FormItem, Multiselect, Typography } from '@frontend/design_system'
import { TERMS_AND_CONDITIONS_FORM_VALUES } from 'texts/termsAndConditions'
import { FormItemChildren } from 'types/form'
import { COR_ITEMS } from 'constants/countryOfResidence'

export const COR_RULES = [
  {
    type: 'required',
    message: TERMS_AND_CONDITIONS_FORM_VALUES.COR.REQUIRED_MESSAGE,
  },
]

export const CorBtn = () => (
  <FormItem id={TERMS_AND_CONDITIONS_FORM_VALUES.COR.ID} rules={COR_RULES}>
    {({ value, error, onChange }: FormItemChildren) => (
      <Multiselect
        value={value}
        placeholder={TERMS_AND_CONDITIONS_FORM_VALUES.COR.PLACEHOLDER}
        error={error}
        tooltip={
          <Typography name="caption1WMedium">
            {TERMS_AND_CONDITIONS_FORM_VALUES.COR.TOOLTIP}
          </Typography>
        }
        items={COR_ITEMS}
        onChange={onChange}
        label={TERMS_AND_CONDITIONS_FORM_VALUES.COR.LABEL}
        isRequired
      />
    )}
  </FormItem>
)
