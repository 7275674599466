import { locationsApi } from 'api'
import { Confirmation } from 'ui/molecules/Confirmation'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { URLS } from 'constants/urls'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import {
  LOCATION_DETAILS_TITLE,
  LOCATION_EDIT_CONFIRMATION,
  LOCATION_EDIT_TITLE,
  SUCCESS_ACTIVATE,
  SUCCESS_INACTIVATE,
} from 'texts/locationEdit'
import { SUCCESSFUL_CHANGE_MODAL } from 'texts/profileConfirmModal'
import { Container } from 'ui/molecules/Container'
import { FormValues } from '../LocationDetailsForm/types'
import { LocationDetailsForm } from '../LocationDetailsForm'
import { HirePointData } from 'api/locations/types'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { Typography } from 'ui/atoms/Typography'
import { DELETE_LOCATION_MODAL } from 'texts/locationList'
import { ACCOUNT_STATUSES } from 'api/auth/constants'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useApiRequest } from 'hooks/useApiRequest'
import { ApproveBtn } from 'ui/components/ApproveBtn'
import { useCompanyId } from 'hooks/useCompanyId'

export const LocationEdit = () => {
  const params = useParams()
  const { accountStatus } = useAppSelector(userDataSelector)
  const [initialData, setInitialData] = useState<Partial<HirePointData>>({})
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const isOperator = useAppSelector(isOperatorSelector)
  const isAccountApproved =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED

  const { apiRequest: updateHirePoint, loading: updateHirePointLoading } =
    useApiRequest(
      (hirePointId, values) =>
        locationsApi.updateHirePoint(Number(hirePointId), values),
      undefined,
      undefined,
      undefined,
      false
    )
  const { apiRequest: changeHirePointStatus } = useApiRequest(
    (hirePointId, status) =>
      locationsApi.changeHirePointState(Number(hirePointId), status)
  )
  const { apiRequest: fetchHirePointInfo, loading: fetchHirePointInfoLoading } =
    useApiRequest((hirePointId) => locationsApi.getHirePointInfo(hirePointId))

  const companyId = useCompanyId()

  const hirePointId = params.id

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'locations'
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false)
  }

  const getHirePointInfo = async () => {
    const response = await fetchHirePointInfo(Number(hirePointId))
    if (response) {
      setInitialData(response.data.hirePointInfo)
    }
  }

  const handleConfirm = async () => {
    const response = await changeHirePointStatus(
      hirePointId,
      !initialData.isActive
    )
    if (response) {
      dispatch(
        setNotificationMessage({
          notificationMessage: initialData.isActive
            ? SUCCESS_INACTIVATE
            : SUCCESS_ACTIVATE,
        })
      )
      getHirePointInfo()
      setIsConfirmationModalOpen(false)
    }
  }

  useEffect(() => {
    if (hirePointId) {
      getHirePointInfo()
    }
  }, [])

  const handleSubmit = async (data: Partial<FormValues>) => {
    const values = {
      ...data,
      waitingHours: {
        waitingHours: data.waitingHours,
        waitingHourPrice: data.waitingHourPrice,
        waitingHourRange: data.waitingHourRange,
        waitingHourFeeType: data.waitingHourFeeType,
      },
      companyID: Number(companyId),
    }
    delete values.phoneCor

    const response = await updateHirePoint(Number(hirePointId), values)
    if (response) {
      dispatch(
        setNotificationMessage({
          notificationMessage: SUCCESSFUL_CHANGE_MODAL.TITLE,
        })
      )
      if (isAccountApproved) {
        const path = isOperator
          ? `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/${companyId}`
          : `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}`
        navigate(path)
      } else {
        navigate(`${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/add`)
      }
    }
  }

  const header = (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: `${
              LOCATION_EDIT_CONFIRMATION.CONFIRMATION_MODAL.QUESTION
            } ${initialData?.locationName}${
              initialData?.isActive
                ? LOCATION_EDIT_CONFIRMATION.CONFIRMATION_MODAL.INACTIVE
                : LOCATION_EDIT_CONFIRMATION.CONFIRMATION_MODAL.ACTIVATE
            }`,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: initialData.isActive
              ? `${DELETE_LOCATION_MODAL.DEACTIVATE} ${initialData.locationName}`
              : `${DELETE_LOCATION_MODAL.ACTIVATE} ${initialData.locationName}`,
          }}
        />
      )}
      <Typography name="H5">{LOCATION_EDIT_TITLE}</Typography>
    </>
  )
  return (
    <Container
      title={header}
      breadcrumbList={breadcrumbList}
      currentPageLabel={LOCATION_DETAILS_TITLE}
      withNavigation={isOperator}
      rightBlock={(id: string) => <ApproveBtn companyId={id} />}
      loading={fetchHirePointInfoLoading}
    >
      <LocationDetailsForm
        formValues={initialData}
        handleSubmit={handleSubmit}
        isLoading={updateHirePointLoading}
        isEditing
      />
    </Container>
  )
}
