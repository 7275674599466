import { useMemo } from 'react'
import { COR_ITEMS } from 'constants/countryOfResidence'
import {
  ADDED_SUCCESS,
  FUEL_POLICY,
  FUEL_POLICY_FORM_VALUES,
  UPDATED_SUCCESS,
} from 'texts/termsAndConditions'
import { Block } from '../Block'
import { CorBtn } from '../CorBtn'
import {
  Form,
  FormItem,
  RadioButton,
  Typography,
} from '@frontend/design_system'
import { IFormValues } from './types'
import { FormChildren, FormItemChildren } from 'types/form'
import { RADIO_BUTTONS } from './constants'
import styles from './styles.module.scss'
import { ActionButtons } from '../ActionButtons'
import { IContentProps } from 'modules/TermsAndConditions/types'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { prepareResponseItems, prepareCorForSelect } from 'utils/form'
import { useDispatch } from 'react-redux'
import { termsApi } from 'api'
import { useApiRequest } from 'hooks/useApiRequest'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useCompanyId } from 'hooks/useCompanyId'

export const FuelPolicy = ({ reload, data, isHistoryExist }: IContentProps) => {
  const dispatch = useDispatch()
  const companyId = useCompanyId()
  const newTermConditionRequest = useApiRequest(
    (body) => termsApi.newTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const updateTermConditionRequest = useApiRequest(
    (body) => termsApi.updateTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )

  const initValues = useMemo(() => {
    const initData: IFormValues = {
      cor: !isHistoryExist ? COR_ITEMS : [],
      fuelPolicy: FUEL_POLICY_FORM_VALUES.FULL_TO_FULL.ID,
    }

    if (data) {
      initData.cor = prepareCorForSelect(data.countriesOfResidence)
      initData.fuelPolicy = data.fuelPolicy || initData.fuelPolicy
    }

    return initData
  }, [data])

  const onSubmit = async (values: IFormValues, validate: boolean) => {
    if (validate) {
      const body = {
        termConditionTypeId: TERM_CONDITIONS_TYPES.FuelPolicy,
        companyId,
        countriesOfResidence: prepareResponseItems(values.cor),
        fuelPolicy: values.fuelPolicy,
      }

      if (data) {
        await updateTermConditionRequest.apiRequest({
          ...body,
          termConditionSettingId: data.id,
        })
        dispatch(
          setNotificationMessage({
            notificationMessage: UPDATED_SUCCESS,
          })
        )
      } else {
        await newTermConditionRequest.apiRequest(body)
        dispatch(
          setNotificationMessage({
            notificationMessage: ADDED_SUCCESS,
          })
        )
      }

      reload()
    }
  }

  return (
    <Block title={FUEL_POLICY}>
      <Form initValues={initValues} onSubmit={onSubmit} key={data?.id}>
        {({ isDirty }: FormChildren) => (
          <>
            <CorBtn />
            <FormItem id={FUEL_POLICY_FORM_VALUES.FUEL.ID}>
              {({ value, onChange }: FormItemChildren) =>
                RADIO_BUTTONS.map(({ label, id, description }) => (
                  <div key={id} className={styles['radio-btn']}>
                    <RadioButton
                      labelTypographyName="body2WMedium"
                      id={id}
                      label={label}
                      onChange={onChange}
                      checked={id === value}
                    />
                    <Typography
                      name="body2WMedium"
                      Tag="p"
                      className={styles.description}
                    >
                      {description}
                    </Typography>
                  </div>
                ))
              }
            </FormItem>
            <ActionButtons
              isOffset
              disabled={data && !isDirty}
              onCancel={data && reload}
              loading={
                newTermConditionRequest.loading ||
                updateTermConditionRequest.loading
              }
            />
          </>
        )}
      </Form>
    </Block>
  )
}
