import { UserRoles } from 'constants/roles'
import { RootState } from 'redux/store'

export const userDataSelector = (state: RootState) => state.authReducer.userData

export const errorMessageSelector = (state: RootState) =>
  state.authReducer.error?.message

export const errorCodeSelector = (state: RootState) =>
  state.authReducer.error?.code

export const userRoleSelector = (state: RootState) =>
  state.authReducer.userData.roles[0]

export const supplierCompanyIdSelector = (state: RootState) =>
  state.authReducer.userData.companyId

export const isOperatorSelector = (state: RootState) =>
  state.authReducer.userData.roles[0] === UserRoles.Operator

export const isSupplierSelector = (state: RootState) =>
  state.authReducer.userData.roles[0] === UserRoles.Supplier

export const authIsLoadedSelector = (state: RootState) =>
  state.authReducer.isLoaded

export const accountStatusSelector = (state: RootState) =>
  state.authReducer.userData.accountStatus

export const isReadyToApproveSelector = (state: RootState) =>
  (accountStatusSelector(state) === 'Registered' ||
    accountStatusSelector(state) === 'Pending') &&
  isOperatorSelector(state)

export const supplierCurrencySelector = (state: RootState) =>
  state.authReducer.userData.currency
