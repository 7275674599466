import { HEAD_ITEMS } from 'texts/bookingHistory'

export const TABLE_HEAD_ITEMS = [
  {
    value: HEAD_ITEMS.RESERVATION_NUMBER,
  },
  {
    value: HEAD_ITEMS.PARTNER_NUMBER,
  },
  {
    value: HEAD_ITEMS.STATUS,
  },
  {
    value: HEAD_ITEMS.RESERVED_DATE,
  },
  {
    value: HEAD_ITEMS.CONFIRMATION_DATE,
  },
  {
    value: HEAD_ITEMS.PICKUP_LOCATION,
  },
  {
    value: HEAD_ITEMS.PICKUP_DATE,
  },
  {
    value: HEAD_ITEMS.DROPOFF_LOCATION,
  },
  {
    value: HEAD_ITEMS.DROPOFF_DATE,
  },
  {
    value: HEAD_ITEMS.DURATION_IN_DAYS,
  },
  {
    value: HEAD_ITEMS.CAR_PRICE,
  },
  {
    value: HEAD_ITEMS.COMPANY_CLASS,
  },
  {
    value: HEAD_ITEMS.SIPP_CODE,
  },
  {
    value: HEAD_ITEMS.EQUIPMENT,
  },
  {
    value: HEAD_ITEMS.CLIENT_NAME_AND_SURNAME,
  },
]
export const MOCK_BODY_ITEMS = [
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
  {
    items: ['bla1', 'bla2'],
  },
]
