import { WaitingHourFeeType, WaitingHourRange } from 'api/locations/types'

export const WAITING_HOURS_RADIO_BUTTONS = {
  Both: WaitingHourRange.Both,
  PickUp: WaitingHourRange.Pickup,
  DropOff: WaitingHourRange.Dropoff,
}

export const WAITING_HOURS_FEE_RADIO_BUTTONS = {
  NoFee: WaitingHourFeeType.NoFee,
  Fee: WaitingHourFeeType.Fee,
}
