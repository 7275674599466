import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { Button, Badge } from '@frontend/design_system'
import { Typography } from 'ui/atoms/Typography'
import { SignUpFormData } from 'modules/auth/types'
import { SIGNUP_NOTIFICATION_MESSAGE, SIGNUP_TEXT_FIELDS } from 'texts/signup'
import { Form } from 'ui/molecules/Form'
import { registration } from 'redux/registration/thunks'
import { login } from 'redux/login/thunks'
import { useNavigate } from 'react-router-dom'
import { handleRedirectToMain } from 'utils/handleRedirectToMain'
import {
  errorMessageSelector,
  registrationCodeSelector,
  registrationEmailSelector,
} from 'redux/registration/selectors'
import { AuthFormFields } from '../../components/AuthFormFields'
import { UserRoles } from 'constants/roles'
import { initialFormValues } from './constants'
import { useMemo } from 'react'
import { PasswordChecklist } from 'ui/atoms/InputPassword/components/PasswordChecklist'
import {
  minimum8Regex,
  must1DigitalRegex,
  must1LowerAndUpperRegex,
} from 'constants/regex'
import { PASSWORD_SCALE } from 'texts/uiTexts'
import styles from './styles.module.scss'
import { setNotificationMessage } from 'redux/notifications/slice'
import { ArrowUpRightIcon } from 'ui/icons'
import { URLS } from 'constants/urls'

export const SignUpForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const registrationEmail = useAppSelector(registrationEmailSelector)
  const code = useAppSelector(registrationCodeSelector)
  const errorMessage = useAppSelector(errorMessageSelector)

  const onSuccess = (role: UserRoles) => {
    handleRedirectToMain(role, navigate)
    dispatch(
      setNotificationMessage({
        notificationMessage: SIGNUP_NOTIFICATION_MESSAGE,
      })
    )
  }

  const loginAfterRegistration = (email?: string, password?: string) => {
    dispatch(login({ email, password, onSuccess: (role) => onSuccess(role) }))
  }

  const onSubmit = async (data: SignUpFormData, validate: boolean) => {
    if (validate) {
      dispatch(
        registration({
          code,
          password: data.password,
          onSuccess: () =>
            loginAfterRegistration(registrationEmail, data.password),
        })
      )
    }
  }

  const handleRedirectToSignIn = () => {
    navigate(URLS.SIGNIN)
  }

  const passwordScaleRules = useMemo(
    () => [
      {
        label: PASSWORD_SCALE.RULES.MIN_CHARACTERS,
        rule: minimum8Regex,
      },
      {
        label: PASSWORD_SCALE.RULES.UPPER_AND_LOWER_CASE,
        rule: must1LowerAndUpperRegex,
      },
      {
        label: PASSWORD_SCALE.RULES.ONE_NUMBER,
        rule: must1DigitalRegex,
      },
    ],
    []
  )

  const passwordScale = {
    label: PASSWORD_SCALE.LABEL,
    labelWeak: PASSWORD_SCALE.WEAK,
    labelMedium: PASSWORD_SCALE.MEDIUM,
    labelStrong: PASSWORD_SCALE.STRONG,
    rules: passwordScaleRules,
  }

  return (
    <div className={styles['signup-form']} data-testid="signup-form">
      <div className={styles['link-wrapper']}>
        <Button
          variant="link"
          label={SIGNUP_TEXT_FIELDS.BACK_TO_SIGN_IN}
          dataTestId="back-to-signin-button"
          onClick={handleRedirectToSignIn}
          iconLeft={
            <ArrowUpRightIcon rotate={180} color="blue700" size="medium" />
          }
        />
      </div>
      <Typography Tag="h3" name="H3" className={styles.header}>
        {SIGNUP_TEXT_FIELDS.TITLE}
      </Typography>
      <Typography Tag="span" name="Button2" color="grey500">
        {SIGNUP_TEXT_FIELDS.SUBTITLE}
      </Typography>
      <Form
        onSubmit={onSubmit}
        className={styles['form-fields']}
        initValues={{ ...initialFormValues, email: registrationEmail || '' }}
      >
        {({ values }) => (
          <>
            <AuthFormFields formType="signup" passwordScale={passwordScale} />
            <PasswordChecklist
              value={values.password as string}
              passwordScale={passwordScale}
            />
            {errorMessage && (
              <Badge
                message={errorMessage}
                className={styles.badge}
                type="error"
                dataTestId="error-badge"
              />
            )}
            <div className={styles['button-wrapper']}>
              <Button
                label={SIGNUP_TEXT_FIELDS.BUTTON}
                htmlType="submit"
                disabled={!(values.password && values.confirmPassword)}
                size="large"
                dataTestId="signup-form-submit-button"
              />
            </div>
          </>
        )}
      </Form>
    </div>
  )
}
