import { useState } from 'react'
import { CustomLink } from 'ui/atoms/CustomLink/CustomLink'
import { URLS } from 'constants/urls'
import { ArrowUpRightIcon } from 'ui/icons'
import { NewPasswordForm } from './components/NewPasswordForm/NewPasswordForm'
import { RecieveCodeForm } from './components/RecieveCodeForm/RecieveCodeForm'
import { VerifyAccountForm } from './components/VerifyAccountForm/VerifyAccountForm'
import { RESET_PASSWORD_TEXT_FIELDS } from 'texts/resetPassword'
import { FormStep, ResetPasswordData, ResetPasswordProps } from './types'
import styles from './styles.module.scss'

export const ResetPassword = ({
  activeStep,
  onChangeStep,
}: ResetPasswordProps) => {
  const [resetPasswordData, setResetPasswordData] = useState<ResetPasswordData>(
    {
      email: '',
      passwordResetToken: '',
      timeToResend: 0,
      verificationAttemptsLeft: 0,
    }
  )

  const handleRecieveCodeFormSuccess =
    () =>
    (
      step: FormStep,
      data: {
        email: string
        timeToResend: number
        verificationAttemptsLeft: number | null
      }
    ) => {
      onChangeStep(step)
      setResetPasswordData({
        ...resetPasswordData,
        ...data,
      })
    }

  const codeSentSuccess = (token: string) => {
    onChangeStep('new-password')
    setResetPasswordData({
      ...resetPasswordData,
      passwordResetToken: token,
    })
  }
  let activeForm: JSX.Element

  switch (activeStep) {
    case 'verify-account':
      activeForm = (
        <VerifyAccountForm
          resetPasswordData={resetPasswordData}
          onSuccess={codeSentSuccess}
        />
      )
      break
    case 'new-password':
      activeForm = <NewPasswordForm resetPasswordData={resetPasswordData} />
      break
    default:
      activeForm = (
        <RecieveCodeForm
          resetPasswordData={resetPasswordData}
          onSuccess={handleRecieveCodeFormSuccess()}
        />
      )
  }

  return (
    <div
      className={styles['reset-password']}
      data-testid="reset-password-wrapper"
    >
      <CustomLink href={URLS.SIGNIN} className={styles.link}>
        <div className={styles['icon-link']}>
          <ArrowUpRightIcon color="blue700" size="medium" />
        </div>
        {RESET_PASSWORD_TEXT_FIELDS.LINK}
      </CustomLink>
      <div className={styles['form-wrapper']}>{activeForm}</div>
    </div>
  )
}
