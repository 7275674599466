export const PRICE_LIST = {
  ADD_BUTTON: 'Add price list',
  LAST_UPDATE: 'Last updated:',
  TOOLTIP:
    'Here you can add seasonal or daily pricing information for your car rental services.',
  COR_TOOLTIP: "Customer's сountry of residence",
}

export const PRICE_LIST_DELETE_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    QUESTION: 'Are you sure you want to delete this price list?',
  },
  SUCCESS_MODAL: {
    TITLE: 'Price list was successfully deleted',
  },
}

export const RATES = 'Prices'

export const headItems = [
  'Country',
  'Location',
  'Prices',
  'COR',
  'Season range',
  'Daily range',
  'Start period',
  'End period',
]
