import { createSlice } from '@reduxjs/toolkit'
import { login, fetchUserData } from './thunks'
import { LoginState } from './types'
import { UserRoles } from 'constants/roles'

const initialState: LoginState = {
  userData: {
    displayName: '',
    roles: [],
    companyId: 0,
    profileUrl: '',
    sessionId: '',
    userName: '',
    currency: '',
  },
  isLoading: false,
  error: { message: '', code: '' },
  isLoaded: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.error = action.payload
    },
    resetUserData(state) {
      state.userData = initialState.userData
    },
    setPageStatus(state, action) {
      if (state.userData.sectionsStatuses) {
        state.userData.sectionsStatuses = {
          ...state.userData.sectionsStatuses,
          ...action.payload,
        }
      }
    },
    setAccountStatus(state, action) {
      state.userData.accountStatus = action.payload
    },
    setSectionStatuses(state, action) {
      state.userData.sectionsStatuses = action.payload
    },
    setSupplierCurrency(state, action) {
      state.userData.currency = action.payload
    },
    resetSectionStatuses(state) {
      state.userData.sectionsStatuses = initialState.userData.sectionsStatuses
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
        state.error = initialState.error
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        const { meta, ...userData } = action.payload
        state.userData = {
          ...userData,
          companyId: Number(meta.companyId) || 0,
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(fetchUserData.pending, (state) => {
        state.isLoading = true
        state.isLoaded = false
        state.error = initialState.error
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        state.userData = {
          ...action.payload,
          userName: action.payload.login,
          accountStatus:
            action.payload.roles[0] === UserRoles.Operator
              ? undefined
              : action.payload.accountStatus,
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = action.payload
      })
  },
})

export const authReducer = authSlice.reducer
export const {
  setErrorMessage,
  resetUserData,
  setPageStatus,
  setAccountStatus,
  setSectionStatuses,
  setSupplierCurrency,
  resetSectionStatuses,
} = authSlice.actions
