import { DashboardApiTypes } from 'api'
import { TOTAL, DIAGRAMS } from 'texts/dashboard'
import { ApexOptions } from 'apexcharts'
import { DS_COLORS } from '@frontend/design_system/src/constants/designSystemColors'
import {
  GroupItem,
  DiagramOptions,
  GroupPerDayItem,
  TotalRevenue,
  GroupCarRentalItem,
} from './types'
import {
  COMPLETED_TYPE,
  SCHEDULED_TYPE,
  NOT_AVAILABLE,
} from './components/CarRentalDays/constants'
import {
  MONTHS,
  ONE_GROUP,
  AFTER_DOT,
  MONTH_LENGTH,
  TWO_GROUP,
} from './constants'
import { formatNumberWithCommas } from 'utils/formatters'

const DONUT_COLORS = [
  DS_COLORS.blue900Master,
  DS_COLORS.blue700,
  DS_COLORS.blue500,
  DS_COLORS.blue300,
  DS_COLORS.blue200,
  DS_COLORS.blue100,
  DS_COLORS.green900,
  DS_COLORS.green700Master,
  DS_COLORS.green500,
  DS_COLORS.green300,
  DS_COLORS.green200,
  DS_COLORS.green100,
  DS_COLORS.yellow900,
  DS_COLORS.yellow700,
  DS_COLORS.yellow500,
  DS_COLORS.yellow300,
  DS_COLORS.yellow200,
  DS_COLORS.yellow100,
  DS_COLORS.red900,
  DS_COLORS.red700,
  DS_COLORS.red500,
  DS_COLORS.red300,
  DS_COLORS.red200,
  DS_COLORS.red100,
  DS_COLORS.grey900,
  DS_COLORS.grey500,
]
const ONE_GROUP_COLORS = [DS_COLORS.blue700, DS_COLORS.blue300]
const TWO_GROUP_COLORS = [
  DS_COLORS.blue900Master,
  DS_COLORS.blue700,
  DS_COLORS.blue500,
  DS_COLORS.blue200,
]
const THREE_GROUP_COLORS = [
  DS_COLORS.yellow700,
  DS_COLORS.green700Master,
  DS_COLORS.blue700,
  DS_COLORS.blue300,
  DS_COLORS.yellow500,
  DS_COLORS.blue500,
  DS_COLORS.green500,
  DS_COLORS.yellow300,
  DS_COLORS.blue200,
  DS_COLORS.green300,
]

export const groupedCarRentalItems = (
  items: DashboardApiTypes.CarRentalDaysItem[]
) => {
  const years: string[] = []
  const groupItems = items.reduce<Record<string, GroupCarRentalItem>>(
    (acc, item) => {
      const completed =
        item.breakdownType === COMPLETED_TYPE ? item.rentalDays : NOT_AVAILABLE
      const scheduled =
        item.breakdownType === SCHEDULED_TYPE ? item.rentalDays : NOT_AVAILABLE
      const year = item.month.split('-')[0]
      if (!years.includes(year)) {
        years.push(year)
      }

      if (acc[item.month]) {
        if (completed !== NOT_AVAILABLE) {
          acc[item.month].completed = completed
        }
        if (scheduled !== NOT_AVAILABLE) {
          acc[item.month].scheduled = scheduled
        }
      } else {
        acc[item.month] = {
          date: item.month,
          scheduled,
          completed,
        }
      }

      return acc
    },
    {}
  )

  return { yearsCount: years.length, groupItems }
}

const formatApexSeries = (
  data: (GroupItem | GroupPerDayItem)[]
): ApexOptions['series'] =>
  data.map(({ name, group, data }) => ({
    name,
    group,
    data: data.map((itemData) => {
      if (!itemData) return null

      if (typeof itemData === 'object' && 'value' in itemData) {
        return itemData.value
      }

      return typeof itemData === 'string' ? parseFloat(itemData) : itemData
    }),
  }))

export const formatCarRentalData = (
  data: DashboardApiTypes.CarRentalDaysResponse | null
) => {
  let result: GroupItem[] = []
  let groupCount = 0

  data?.items.forEach((item) => {
    const [year, month] = item.month.split('-')
    const monthIndex = parseInt(month, 10) - 1

    let group = result.find(
      (itemRes) => itemRes.name === item.breakdownType && itemRes.group === year
    )

    if (!group) {
      group = {
        name: item.breakdownType,
        group: year,
        data: Array(MONTHS.length).fill(null),
      }
      result.push(group)
      groupCount++
    }

    group.data[monthIndex] = item.rentalDays
  })

  const uniqueGroups = new Set(result.map((item) => item.group))
  groupCount = uniqueGroups.size

  if (groupCount > ONE_GROUP) {
    result = result.map((item) => ({
      ...item,
      name: `${item.name} ${item.group}`,
    }))
  }

  return {
    formatData: formatApexSeries(result),
    groupCount,
  }
}

export const formatPerDayData = (
  data: DashboardApiTypes.RevenuePerDayResponse | null
) => {
  {
    let revenuePerDayResult: GroupPerDayItem[] = []
    let revenueRentalDaysResult: GroupPerDayItem[] = []
    let revenueResult: GroupPerDayItem[] = []
    let categories: string[] = []
    let groupCount = 0

    if (data?.items && data.items.length > MONTH_LENGTH) {
      categories = MONTHS
      data.items.forEach((item) => {
        const [year, month] = item.date.split('-')
        const monthIndex = parseInt(month, 10) - 1

        const group: GroupPerDayItem | undefined = revenuePerDayResult.find(
          (itemRes: GroupPerDayItem) => itemRes.group && itemRes.group === year
        )

        if (!group) {
          revenuePerDayResult.push({
            name: DIAGRAMS.REVENUE_PER_DAY,
            group: year,
            data: Array(MONTHS.length).fill(null),
            counts: Array(MONTHS.length).fill(0),
          })
          revenueRentalDaysResult.push({
            name: DIAGRAMS.RENTAL_DAYS,
            group: year,
            data: Array(MONTHS.length).fill(null),
            counts: [],
          })
          revenueResult.push({
            name: DIAGRAMS.REVENUE,
            group: year,
            data: Array(MONTHS.length).fill(null),
            counts: [],
          })
          groupCount++
        }

        const findItem = revenuePerDayResult.findIndex(
          (item) => item.group === year
        )
        if (revenueResult[findItem].data[monthIndex] === null) {
          revenuePerDayResult[findItem].data[monthIndex] = item.revenuePerDay
          revenuePerDayResult[findItem].counts[monthIndex] = 1
          revenueRentalDaysResult[findItem].data[monthIndex] = item.rentalDays
          revenueResult[findItem].data[monthIndex] = item.revenue
        } else {
          revenuePerDayResult[findItem].data[monthIndex] = Number(
            Number(revenuePerDayResult[findItem].data[monthIndex]) +
              item.revenuePerDay
          ).toFixed(AFTER_DOT)
          revenuePerDayResult[findItem].counts[monthIndex] =
            Number(revenuePerDayResult[findItem].counts[monthIndex]) + 1
          revenueRentalDaysResult[findItem].data[monthIndex] = Number(
            Number(revenueRentalDaysResult[findItem].data[monthIndex]) +
              item.rentalDays
          ).toFixed(AFTER_DOT)
          revenueResult[findItem].data[monthIndex] = Number(
            Number(revenueResult[findItem].data[monthIndex]) + item.revenue
          ).toFixed(AFTER_DOT)
        }
      })
      revenuePerDayResult = revenuePerDayResult.map((itemPerDay) => ({
        ...itemPerDay,
        data: itemPerDay.data.map((item, index) => {
          if (item && itemPerDay.counts[index] !== null) {
            const count = itemPerDay.counts[index] || 1
            return Number(Number(item) / count).toFixed(AFTER_DOT)
          }
          return item
        }),
      }))
    } else {
      revenuePerDayResult.push({
        name: DIAGRAMS.REVENUE_PER_DAY,
        data: [],
        counts: [],
      })
      revenueResult.push({
        name: DIAGRAMS.REVENUE,
        data: [],
        counts: [],
      })
      revenueRentalDaysResult.push({
        name: DIAGRAMS.RENTAL_DAYS,
        data: [],
        counts: [],
      })
      data?.items.forEach((item) => {
        const [, month, day] = item.date.split('-')
        categories.push(`${day}.${month}`)
        revenuePerDayResult[0].data.push(item.revenuePerDay || null)
        revenueResult[0].data.push(item.revenue || null)
        revenueRentalDaysResult[0].data.push(item.rentalDays || null)
      })
    }

    if (groupCount > ONE_GROUP) {
      revenuePerDayResult = revenuePerDayResult.map((item) => ({
        ...item,
        name: `${item.name} ${item.group}`,
      }))
      revenueResult = revenueResult.map((item) => ({
        ...item,
        name: `${item.name} ${item.group}`,
      }))
      revenueRentalDaysResult = revenueRentalDaysResult.map((item) => ({
        ...item,
        name: `${item.name} ${item.group}`,
      }))
    }

    return {
      revenuePerDay: formatApexSeries(revenuePerDayResult),
      revenue: formatApexSeries(revenueResult),
      rentalDays: formatApexSeries(revenueRentalDaysResult),
      groupCount,
      categories,
    }
  }
}

export const formatPerUnitData = (
  data: DashboardApiTypes.RevenuePerUnitResponse | null
) => {
  const revenuePerDayResult: (string | number | null)[] = []
  const revenueReservationsResult: (string | number | null)[] = []
  const revenueResult: (string | number | null)[] = []
  const categories: string[] = []

  data?.items.forEach((item) => {
    categories.push(item.carType)
    revenuePerDayResult.push(item.revenuePerReservation || null)
    revenueResult.push(item.revenue || null)
    revenueReservationsResult.push(item.reservations || null)
  })

  return {
    revenuePerDay: formatApexSeries([
      {
        name: DIAGRAMS.REVENUE_PER_INIT,
        data: revenuePerDayResult,
      },
    ]),
    revenue: formatApexSeries([
      {
        name: DIAGRAMS.REVENUE,
        data: revenueResult,
      },
    ]),
    reservations: formatApexSeries([
      {
        name: DIAGRAMS.RESERVATIONS,
        data: revenueReservationsResult,
      },
    ]),
    categories,
  }
}

export const formatRevenueTotalData = (
  data: DashboardApiTypes.RevenuePerUnitResponse | null
): TotalRevenue => {
  const revenueResult: number[] = []
  const categories: string[] = []

  data?.items.forEach((item) => {
    categories.push(item.carType)
    revenueResult.push(Number(item.revenue))
  })

  return {
    revenue: revenueResult,
    categories,
  }
}

const getColors = (groupCount: number) => {
  if (groupCount <= ONE_GROUP) {
    return ONE_GROUP_COLORS
  }
  if (groupCount <= TWO_GROUP) {
    return TWO_GROUP_COLORS
  }
  return THREE_GROUP_COLORS
}

const getDonutOption = (
  categoryOptions: string[],
  total: string
): ApexOptions => ({
  chart: {
    type: 'donut',
  },
  labels: categoryOptions,
  plotOptions: {
    pie: {
      donut: {
        size: '60%',
        background: 'transparent',
        labels: {
          show: true,
          name: {
            show: true,
            fontSize: '16px',
            fontFamily: 'Inter, Roboto, sans-serif',
            fontWeight: 800,
            color: '#000',
            offsetY: -10,
            formatter: function (val: string) {
              return val
            },
          },
          total: {
            show: true,
            fontSize: '16px',
            fontFamily: 'Inter, Roboto, sans-serif',
            fontWeight: 800,
            color: '#000',
            label: `${TOTAL}:`,
            formatter: () => total || '',
          },
        },
      },
    },
  },
  colors: DONUT_COLORS,
  stroke: {
    show: true,
    width: 5,
    colors: ['#ffffff'],
  },
  dataLabels: {
    enabled: true,
    dropShadow: {
      enabled: false,
    },
  },
  legend: {
    show: true,
    position: 'right',
    horizontalAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Inter, Roboto, sans-serif',
    fontWeight: 500,
    labels: {
      colors: ['#373D3F'],
      useSeriesColors: false,
    },
    markers: {
      size: 10,
      offsetX: -6,
    },
    itemMargin: {
      vertical: 8,
    },
    offsetX: 50,
    offsetY: 0,
  },
  states: {
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none',
      },
    },
  },
})

export const getDiagramOptions = ({
  categoryOptions,
  groupCount = 0,
  textAfter = '',
  textBefore = '',
  isDonut = false,
  total = '',
}: DiagramOptions): ApexOptions => {
  const isOneGroup = groupCount <= ONE_GROUP
  const isShortDiagram = categoryOptions.length <= MONTHS.length

  if (isDonut) {
    return getDonutOption(categoryOptions, total)
  }
  return {
    chart: {
      type: 'bar',
      stacked: true,
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
      },
    },
    colors: getColors(groupCount),
    dataLabels: {
      enabled: isOneGroup && isShortDiagram ? true : false,
      offsetY: 2,
      textAnchor: 'middle',
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      fontSize: '14px',
      fontFamily: 'Inter, Roboto, sans-serif',
      fontWeight: 500,
      labels: {
        colors: ['#373D3F'],
        useSeriesColors: false,
      },
      markers: {
        size: 8,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
      lineCap: 'round',
    },
    xaxis: {
      type: 'category',
      categories: categoryOptions,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        rotate: -90,
      },
    },
    grid: {
      show: true,
      borderColor: '#e0e0e0',
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.65,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'darken',
          value: 0.35,
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val: string | number) =>
          `${textBefore}${formatNumberWithCommas(val)}${textAfter}`,
      },
    },
  }
}
