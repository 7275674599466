export const SESSION_EXPIRED_CODE = 'SessionExpired'
export const INVALID_CAPTCHA_CODE = '29'

export const errorCodes = {
  EarlyVerificationAttempt: 'The link has already been sent',
  Default: 'Something went wrong',
  Unauthorized: 'Your email or password is incorrect',
  AdditionalEquipmentExistException: 'Such equipment already exists.',
  [SESSION_EXPIRED_CODE]:
    'Your session expired as there was no activity. Please sign in again.',
  [INVALID_CAPTCHA_CODE]: 'Please complete the captcha above',
  CarWithAditionalEquipmentsException: 'Car has attached extras',
  NotAllowedAction: 'You have exceeded the limit for initial approval.',
  ApplicationInReview: 'Your application is being reviewed.',
  CompanyCannotBeClosed:
    'Profile has active locations. Please deactivate all active locations and try again.',
}
