import { createAsyncThunk } from '@reduxjs/toolkit'
import { authApi, AuthApiTypes } from 'api'
import { ErrorResponse } from 'api/types'
import { setShortSupplierData } from 'redux/profile/slice'
import { LoginRequest, RejectValue } from './types'
import { getErrorMessage } from 'utils/getErrorMessage'

export const login = createAsyncThunk<
  AuthApiTypes.LoginResponse,
  LoginRequest,
  RejectValue
>(
  'auth/login',
  async ({ email, password, onSuccess, captchaToken }, { rejectWithValue }) => {
    try {
      const loginResponse = await authApi.login(email, password, captchaToken)
      if (onSuccess) {
        onSuccess(
          loginResponse.data.roles[0],
          loginResponse.data.meta.companyId
        )
      }
      return loginResponse.data
    } catch (error) {
      const { errorCode, message } = (error as ErrorResponse).responseStatus
      return rejectWithValue({
        message: getErrorMessage(errorCode) || message,
        code: errorCode,
      })
    }
  }
)

export const fetchUserData = createAsyncThunk<
  AuthApiTypes.UserInfoResponse,
  void,
  RejectValue
>('auth/fetchUserData', async (_, { rejectWithValue, dispatch }) => {
  try {
    const userDataResponse = await authApi.getUserInfo()
    dispatch(setShortSupplierData(userDataResponse.data))
    return userDataResponse.data
  } catch (error) {
    const { errorCode, message } = (error as ErrorResponse).responseStatus
    return rejectWithValue({
      message: getErrorMessage(errorCode) || message,
      code: errorCode,
    })
  }
})
