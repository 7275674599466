import { createSlice } from '@reduxjs/toolkit'
import { FiltersState } from './types'
import { getSelectedFiltersCount, getSelectedFiltersList } from 'utils/filters'

export const initialState: FiltersState = {
  filters: {
    activityHistory: {},
    priceList: {},
    stopSales: {},
    applications: {},
    profileList: {},
    profileArchive: {},
    locations: {},
    equipments: {},
    cars: {},
  },
  selectedFiltersCount: {
    activityHistory: 0,
    priceList: 0,
    stopSales: 0,
    applications: 0,
    profileList: 0,
    profileArchive: 0,
    locations: 0,
    equipments: 0,
    cars: 0,
  },
  selectedFilters: {
    activityHistory: {},
    priceList: {},
    stopSales: {},
    applications: {},
    profileList: {},
    profileArchive: {},
    locations: {},
    equipments: {},
    cars: {},
  },
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterItems(state, action) {
      const key = action.payload.key as keyof typeof initialState.filters
      state.filters[key] = action.payload.items
      state.selectedFiltersCount[key] = getSelectedFiltersCount(
        action.payload.items
      )
      state.selectedFilters[key] = getSelectedFiltersList(state.filters[key])
    },
    selectAllFiltersByType(state, action) {
      const filterKey = action.payload
        .filterKey as keyof typeof initialState.filters
      state.filters[filterKey][action.payload.key] = state.filters[filterKey][
        action.payload.key
      ].map((el) => ({ value: el.value, selected: action.payload.isSelect }))
      state.selectedFiltersCount[filterKey] = getSelectedFiltersCount(
        state.filters[filterKey]
      )
      state.selectedFilters[filterKey] = getSelectedFiltersList(
        state.filters[filterKey]
      )
    },
    resetSelectedFilters(state) {
      state.selectedFilters = initialState.selectedFilters
      state.selectedFiltersCount = initialState.selectedFiltersCount
    },
    resetStopSalesFilters(state) {
      state.selectedFilters.stopSales = initialState.selectedFilters.stopSales
      state.selectedFiltersCount.stopSales =
        initialState.selectedFiltersCount.stopSales
    },
    resetPriceListFilters(state) {
      state.selectedFilters.priceList = initialState.selectedFilters.priceList
      state.selectedFiltersCount.priceList =
        initialState.selectedFiltersCount.priceList
    },
  },
})

export const filtersReducer = filtersSlice.reducer
export const {
  resetSelectedFilters,
  setFilterItems,
  selectAllFiltersByType,
  resetPriceListFilters,
  resetStopSalesFilters,
} = filtersSlice.actions
