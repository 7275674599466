import { referencesApi } from 'api'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'ui/atoms/Button'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import { Select } from 'ui/atoms/Select'
import { Form } from 'ui/molecules/Form'
import {
  EQUIPMENT_DETAILS,
  EQUIPMENT_DETAILS_FORM_VALUES,
} from 'texts/equipmentDetails'
import { Multiselect } from '@frontend/design_system'
import { RADIO_BUTTONS } from './constants'
import { RadioButton } from 'ui/atoms/RadioButton'
import { useSelectValues } from 'hooks/useSelectValues'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { EquipmentDetailsData, EquipmentDetailsFormProps } from './types'
import { useApiRequest } from 'hooks/useApiRequest'
import { PriceForm } from './components/PriceForm'
import { useValidationRules } from './hooks/useValidationRules'
import { useCompanyId } from 'hooks/useCompanyId'
import styles from './styles.module.scss'

export const EquipmentDetailsForm = ({
  initialValues,
  handleSubmit,
  isLoading,
}: EquipmentDetailsFormProps) => {
  const [equipmentDetailsData, setEquipmentDetailsData] =
    useState<EquipmentDetailsData>({
      locations: [],
      cars: [],
      extras: [],
    })

  const navigate = useNavigate()

  const companyId = useCompanyId()
  const validationRules = useValidationRules()

  const getAllCompanyHirePointsRequest = useApiRequest(() =>
    referencesApi.getAllCompanyHirePoints(String(companyId))
  )
  const getAllExtraTypesRequest = useApiRequest((companyId) =>
    referencesApi.getAllExtraTypes(companyId)
  )

  const onSubmit = (data: Record<string, unknown>, validate: boolean) => {
    if (validate) {
      handleSubmit(data)
    }
  }

  useEffect(() => {
    if (companyId) {
      const fetchLocations = async () => {
        const [locations, extras] = await Promise.all([
          getAllCompanyHirePointsRequest.apiRequest(),
          getAllExtraTypesRequest.apiRequest(companyId),
        ])
        if (locations && extras) {
          setEquipmentDetailsData((prevState) => ({
            ...prevState,
            locations: locations.data.items,
            extras: extras.data.items,
          }))
        }
      }
      fetchLocations()
    }
  }, [companyId])

  const locationsSelectValues = useSelectValues(equipmentDetailsData.locations)
  const extrasSelectValues = useSelectValues(equipmentDetailsData.extras)

  return (
    <Form
      initValues={initialValues}
      onSubmit={onSubmit}
      className={styles['form-wrapper']}
    >
      {({ values, isDirty }) => (
        <>
          <ContentWrapper
            className={styles.form}
            title={EQUIPMENT_DETAILS.FORM_TITLE}
          >
            <>
              <div className={styles['form-items-row']}>
                <FormItem
                  id={EQUIPMENT_DETAILS_FORM_VALUES.EQUIPMENT_NAME.ID}
                  className={styles['form-item']}
                  rules={
                    validationRules[
                      EQUIPMENT_DETAILS_FORM_VALUES.EQUIPMENT_NAME.ID
                    ]
                  }
                >
                  {({ value, error, onChange }) => (
                    <Select
                      size="large"
                      error={error}
                      items={extrasSelectValues}
                      placeholder={
                        EQUIPMENT_DETAILS_FORM_VALUES.EQUIPMENT_NAME.PLACEHOLDER
                      }
                      onChange={onChange}
                      isRequired
                      label={EQUIPMENT_DETAILS_FORM_VALUES.EQUIPMENT_NAME.LABEL}
                      selectedValue={value}
                    />
                  )}
                </FormItem>
                <FormItem
                  id={EQUIPMENT_DETAILS_FORM_VALUES.LOCATION.ID}
                  className={styles['form-item']}
                  rules={
                    validationRules[EQUIPMENT_DETAILS_FORM_VALUES.LOCATION.ID]
                  }
                >
                  {({ value, error, onChange }) => (
                    <Multiselect
                      error={error}
                      onChange={onChange}
                      value={value}
                      label={EQUIPMENT_DETAILS_FORM_VALUES.LOCATION.LABEL}
                      placeholder={
                        EQUIPMENT_DETAILS_FORM_VALUES.LOCATION.PLACEHOLDER
                      }
                      unitName={{
                        plural: EQUIPMENT_DETAILS.SEARCH_VALUE,
                      }}
                      items={locationsSelectValues}
                      withAllSelect
                      isRequired
                    />
                  )}
                </FormItem>
              </div>
              <PriceForm formValues={values} />
              <div>
                <FormItem
                  id={EQUIPMENT_DETAILS_FORM_VALUES.SUM_FOR.ID}
                  className={styles['radio-buttons']}
                >
                  {({ value, onChange, onChangeField }) => (
                    <>
                      {RADIO_BUTTONS.map(({ label, id }) => (
                        <RadioButton
                          labelTypographyName="Button2"
                          key={id}
                          id={id}
                          label={label}
                          onChange={(value) => {
                            onChange(value)
                            onChangeField(
                              '',
                              EQUIPMENT_DETAILS_FORM_VALUES.MAX_AMOUNT.ID
                            )
                          }}
                          checked={id === value}
                        />
                      ))}
                    </>
                  )}
                </FormItem>
              </div>
            </>
          </ContentWrapper>
          <div className={styles.buttons}>
            <div className={styles['button-wrapper']}>
              <Button
                variant="outline"
                size="large"
                onClick={() => navigate(-1)}
                label={EQUIPMENT_DETAILS.BUTTONS.CANCEL_BUTTON_LABEL}
              />
            </div>
            <div className={styles['button-wrapper']}>
              <Button
                htmlType="submit"
                size="large"
                label={EQUIPMENT_DETAILS.BUTTONS.SAVE_BUTTON_LABEL}
                loading={isLoading}
                disabled={!isDirty}
              />
            </div>
          </div>
        </>
      )}
    </Form>
  )
}
