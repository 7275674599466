import { createSlice } from '@reduxjs/toolkit'
import { SortingState } from './types'

const initialState: SortingState = {
  sortingData: {
    activityHistory: [],
    applications: [],
    profileList: [],
    priceList: [],
    profileArchive: [],
    locations: [],
    equipments: [],
    cars: [],
    stopSales: [],
  },
}

export const sortingSlice = createSlice({
  name: 'sorting',
  initialState,
  reducers: {
    setSorting(state, action) {
      const filterKey = action.payload
        .filterKey as keyof typeof initialState.sortingData
      const existedKeyIndex = state.sortingData[filterKey].findIndex(
        (el) => el.key === action.payload.item.key
      )
      if (existedKeyIndex !== -1) {
        state.sortingData[filterKey][existedKeyIndex].type =
          action.payload.item.type
      } else {
        state.sortingData[filterKey].push(action.payload.item)
      }
    },
    resetSorting(state) {
      state.sortingData = initialState.sortingData
    },
  },
})

export const sortingReducer = sortingSlice.reducer
export const { setSorting, resetSorting } = sortingSlice.actions
