import { useState } from 'react'
import { FORM_FIELDS_VALUES } from 'texts/formFields'
import { authApi } from 'api'
import { Badge, Input, Button } from '@frontend/design_system'
import { ErrorResponse } from 'api/types'
import { RecieveCodeFormProps } from './types'
import { emailRules } from 'texts/validateRules'
import { RecieveCodeFormData } from 'modules/auth/types'
import { Typography } from 'ui/atoms/Typography'
import { Form } from 'ui/molecules/Form'
import {
  RESET_PASSWORD_TEXT_FIELDS,
  emailNotFoundMessage,
} from 'texts/resetPassword'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import {
  VERIFICATION_ATTEMPTS_ERROR_CODE,
  initialFormValues,
} from './constants'
import { getErrorMessage } from 'utils/getErrorMessage'
import { errorCodes } from 'constants/errorCodes'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useApiRequest } from 'hooks/useApiRequest'
import styles from './styles.module.scss'

export const RecieveCodeForm = ({
  onSuccess,
  resetPasswordData,
}: RecieveCodeFormProps) => {
  const [errorMessage, setErrorMessage] = useState('')

  const dispatch = useDispatch()
  const { apiRequest } = useApiRequest((email) =>
    authApi.verifyUserEmail(email)
  )

  const onSubmit = async (data: RecieveCodeFormData, validate: boolean) => {
    setErrorMessage('')
    if (validate && data.email) {
      try {
        const sendVerificationCodeResponse =
          await authApi.sendVerificationCodeRequest(data.email)
        const {
          timeToResend,
          verificationAttemptsLeft,
          success,
          errorMessage,
        } = sendVerificationCodeResponse.data

        if (!success && errorMessage) {
          setErrorMessage(errorMessage)
          return
        }

        onSuccess('verify-account', {
          ...resetPasswordData,
          email: data.email,
          timeToResend,
          verificationAttemptsLeft,
        })
      } catch (error) {
        const errorCode = (error as ErrorResponse).responseStatus.errorCode
        if (errorCode === VERIFICATION_ATTEMPTS_ERROR_CODE) {
          dispatch(
            setNotificationMessage({
              notificationMessage:
                RESET_PASSWORD_TEXT_FIELDS.RECIEVE_CODE_FORM
                  .REACHED_MAX_ATTEMPTS_COUNT,
              type: 'error',
              timer: 0,
              additionalMessage:
                RESET_PASSWORD_TEXT_FIELDS.RECIEVE_CODE_FORM.CONTACT_SUPPORT,
            })
          )
          return
        }
        const errorMessage = getErrorMessage(errorCode)
        if (errorMessage && errorMessage !== errorCodes.Default) {
          setErrorMessage(errorMessage)
        } else {
          setErrorMessage((error as ErrorResponse).responseStatus.message)
        }
      }
    }
  }

  const handleSkipRecieveCode = async (email: string) => {
    const response = await apiRequest(email)
    if (response?.data.isAccountExists) {
      onSuccess('verify-account', {
        ...resetPasswordData,
        email,
        timeToResend: 0,
        verificationAttemptsLeft: null,
        isRecieveCodeSkiped: true,
      })
    } else {
      setErrorMessage(emailNotFoundMessage(email))
    }
  }

  return (
    <>
      <Typography Tag="h3" name="H3" className={styles.header}>
        {RESET_PASSWORD_TEXT_FIELDS.RECIEVE_CODE_FORM.TITLE}
      </Typography>
      <Typography Tag="span" name="Button2" color="grey500">
        {RESET_PASSWORD_TEXT_FIELDS.RECIEVE_CODE_FORM.SUBTITLE}
      </Typography>
      <Form
        onSubmit={onSubmit}
        className={styles['form-fields']}
        initValues={initialFormValues}
      >
        {({ values }) => (
          <>
            <div className={styles['input-wrapper']}>
              <FormItem id={FORM_FIELDS_VALUES.EMAIL.ID} rules={emailRules}>
                {({ error, onChange, value }) => (
                  <Input
                    value={value}
                    error={error}
                    onChange={onChange}
                    size="large"
                    type="email"
                    autoComplete="on"
                    id={FORM_FIELDS_VALUES.EMAIL.ID}
                    iconLeft="MailIcon"
                    label={FORM_FIELDS_VALUES.EMAIL.LABEL}
                    iconSize="small"
                    placeholder={FORM_FIELDS_VALUES.EMAIL.PLACEHOLDER}
                    typographyName="Button2"
                    dataTestId="recieve-code-form-email-input"
                  />
                )}
              </FormItem>
            </div>
            {errorMessage && (
              <Badge
                message={errorMessage}
                className={styles.badge}
                type="error"
                dataTestId="error-badge"
              />
            )}
            <div className={styles.buttons}>
              <div className={styles['button-wrapper']}>
                <Button
                  label={
                    RESET_PASSWORD_TEXT_FIELDS.RECIEVE_CODE_FORM.BUTTON_LABEL
                  }
                  htmlType="submit"
                  dataTestId="recieve-code-form-submit-button"
                  size="large"
                  disabled={!values.email}
                />
              </div>
              <div className={styles['button-wrapper']}>
                <Button
                  label={
                    RESET_PASSWORD_TEXT_FIELDS.RECIEVE_CODE_FORM.LINK_LABEL
                  }
                  size="large"
                  onClick={() => handleSkipRecieveCode(values.email as string)}
                  variant="link"
                  disabled={!values.email}
                  dataTestId="skip-recieve-code-button"
                />
              </div>
            </div>
          </>
        )}
      </Form>
    </>
  )
}
