import { useEffect, useState } from 'react'
import { useApiRequest } from './useApiRequest'
import { referencesApi, ReferencesApiTypes } from 'api'

export const useGetProfileReferences = (values: Record<string, unknown>) => {
  const [currencies, setCurrencies] =
    useState<ReferencesApiTypes.CurrenciesResponse>({ items: [] })
  const [countries, setCountries] =
    useState<ReferencesApiTypes.CountriesResponse>({ items: [] })
  const getCurrenciesRequest = useApiRequest(() =>
    referencesApi.getCurrencies()
  )

  const getCountriesRequest = useApiRequest(() => referencesApi.getCountries())

  useEffect(() => {
    const fetchData = async () => {
      const [currencies, countries] = await Promise.all([
        getCurrenciesRequest.apiRequest(),
        getCountriesRequest.apiRequest(),
      ])
      if (currencies && countries) {
        setCurrencies(currencies.data)
        if (values.companyCurrencyName === '') {
          values.companyCurrencyName = currencies.data.items[0].name
        }
        setCountries(countries.data)
        if (!values.companyCountryID) {
          values.companyCountryID = ''
        }
      }
    }
    fetchData()
  }, [])

  return { countries, currencies }
}
