import { AuthLayout } from 'layouts/AuthLayout'
import { ResetPassword } from 'modules/auth/ResetPassword'
import { FormStep } from 'modules/auth/ResetPassword/types'
import { WelcomeBlockView } from 'modules/auth/WelcomeBlockView'
import { Header } from 'modules/auth/WelcomeBlockView/components/Header'
import { useState } from 'react'
import { WELCOME_BLOCK_HEADER } from 'texts/welcomeBlock'

export const ResetPasswordPage = () => {
  const [activeStep, setActiveStep] = useState<FormStep>('recieve-code')

  return (
    <AuthLayout
      WelcomeBlock={
        <WelcomeBlockView
          withCarousel={false}
          Header={() => (
            <Header
              title={WELCOME_BLOCK_HEADER[activeStep].TITLE}
              subtitle={WELCOME_BLOCK_HEADER[activeStep].SUBTITLE}
            />
          )}
        />
      }
      Form={
        <ResetPassword activeStep={activeStep} onChangeStep={setActiveStep} />
      }
    />
  )
}
