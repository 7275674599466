import { getRequiredMessage } from 'utils/getRequiredMessage'

export const PRICE_LIST_DETAILS_FORM = {
  TITLE: 'Price list',
  SEASON_RANGE: 'Season range <mark>*</mark>',
  DAILY_RANGE: 'Daily range <mark>*</mark>',
  GO_TO_RATES: 'Go to prices',
}

export const PRICE_LIST_DETAILS_FORM_VALUES = {
  LOCATION: {
    ID: 'location',
    PLACEHOLDER: 'Please select your location',
    LABEL: 'Location',
    REQUIRED_MESSAGE: getRequiredMessage('location'),
  },
  YEAR: {
    ID: 'year',
    PLACEHOLDER: 'Please input your year',
    LABEL: 'Year',
    REQUIRED_MESSAGE: getRequiredMessage('year'),
  },
  SIPP: {
    ID: 'sipp',
    PLACEHOLDER: 'Please enter you internal car class',
    LABEL: 'Internal Car Class (SIPP)',
    REQUIRED_MESSAGE: getRequiredMessage('SIPP'),
  },
  APPLY_FOR: {
    ID: 'applyFor',
    LABEL: 'Apply prices by',
  },
  COMMENT: {
    ID: 'comment',
    LABEL: 'Your comment',
    PLACEHOLDER: 'Please enter your comment',
  },
}

export const RATES = {
  ADD_CAR_CLASS: 'Add car class',
  ADD_CAR_MODEL: 'Add car model',
  SEASON_RANGES: 'Season ranges',
  COR: "Customer's сountry of residence",
  COUNTRY: 'Country',
  LOCATION: 'Location',
  IMPORT: 'Import',
  EXPORT: 'Export',
  IMPORT_TOOLTIP: 'File formats: .xlsx, .xlsm.',
}

export const RATES_EXPORT_MODAL = {
  QUESTION:
    'Your changes have not been saved. Do you want to save changes before exporting prices?',
  TITLE: 'Unsaved changes',
  CONFIRM_LABEL: 'Save and export',
  DECLINE_LABEL: 'Export without saving',
}

export const RATES_IMPORT_CONFIRM_MODAL = {
  CONTENT:
    'When you import the file, all currently added car models and prices will be replaced with the car models and prices listed in the file.',
  TITLE: 'Are you sure you want to import the file?',
}

export const CAR_CLASS_MODAL = {
  CARS: {
    ID: 'cars',
    LABEL: 'Car class',
    PLACEHOLDER: 'Please select car class',
  },
  ADD: 'Add',
}

export const ADD_CAR_MODAL = {
  INPUT_PLACEHOLDER: 'Search for car model',
  ALL: 'Select all',
  NOT_FOUND_TITLE: 'No cars to add',
  NOT_FOUND_DESCRIPTION: 'Go to Cars page to add a vehicle.',
  NOT_FOUND_BUTTON: 'Go to Cars',
  SELECTED_ADD: 'All',
}

export const ratesHeadItems = ['Car class', 'Car model']
