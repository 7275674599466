import { RootState } from 'redux/store'

export const locationsErrorMessageSelector = (state: RootState) =>
  state.locationsReducer.errorMessage

export const locationsSelector = (state: RootState) =>
  state.locationsReducer.locations

export const citiesSelector = (state: RootState) =>
  state.locationsReducer.cities

export const countriesSelector = (state: RootState) =>
  state.locationsReducer.countries

export const mapDataSelector = (state: RootState) =>
  state.locationsReducer.mapData

export const markerPositionSelector = (state: RootState) =>
  state.locationsReducer.markerPosition

export const locationTypeSelector = (state: RootState) =>
  state.locationsReducer.locationType
