import { forwardRef } from 'react'
import { TableHeadProps } from './types'
import { Typography } from 'ui/atoms/Typography'
import { Filter } from 'ui/atoms/Filter'
import styles from './styles.module.scss'

export const TableHead = forwardRef<HTMLTableCellElement, TableHeadProps>(
  (
    {
      item,
      mouseDown,
      containerWidth,
      itemsCount,
      isLastChild,
      isEmptyTable,
      initItemsWidth,
      index,
      hasVerticalScroll,
    },
    ref
  ) => {
    const initItemWidth = () => {
      const borderWidth = 1
      if (initItemsWidth?.length) {
        const defaultColumnWidthCount =
          itemsCount - initItemsWidth.filter((el) => !!el).length
        const filledWidth = initItemsWidth.reduce(
          (acc, i) => acc + Number(i),
          0
        )
        const remainingWidth = containerWidth - filledWidth
        const scrollWidth = 8
        if (hasVerticalScroll && isLastChild) {
          return initItemsWidth?.[index]
            ? `${Number(initItemsWidth[index]) - scrollWidth}px`
            : `${remainingWidth / defaultColumnWidthCount - scrollWidth}px`
        }

        return initItemsWidth?.[index]
          ? `${initItemsWidth[index]}px`
          : `${
              Math.floor(remainingWidth / defaultColumnWidthCount) - borderWidth
            }px`
      } else {
        return `${containerWidth / itemsCount}px`
      }
    }

    return (
      <Typography
        Tag="th"
        ref={ref}
        name="Subtitle3"
        className={styles.head}
        style={{ minWidth: initItemWidth() }}
      >
        <div className={styles.item}>
          {!isLastChild && (
            <div
              className={styles['item-left-border']}
              onMouseDown={mouseDown}
            />
          )}
          {item.value}
          {(item.filterSettings?.withFiltering ||
            item.filterSettings?.withSorting) && (
            <div className={styles.filter}>
              <Filter
                {...item.filterSettings}
                isLastChild={isLastChild}
                isEmptyTable={isEmptyTable}
              />
            </div>
          )}
        </div>
      </Typography>
    )
  }
)
