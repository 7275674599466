import { useMemo } from 'react'
import {
  Form,
  Badge,
  FormItem,
  RadioButton,
  HtmlTypography,
} from '@frontend/design_system'
import {
  ADDED_SUCCESS,
  INSURANCE_EXLUDED_NOTE,
  INSURANCE_INCLUDED,
  INSURANCE_INCLUDED_DESCRIPTION,
  INSURANCE_INCLUDED_FORM_VALUES,
  INSURANCE_INCLUDED_NOTE,
  UPDATED_SUCCESS,
} from 'texts/termsAndConditions'
import { COR_ITEMS } from 'constants/countryOfResidence'
import { IFormValues } from './types'
import { Block } from '../Block'
import { CorBtn } from '../CorBtn'
import styles from './styles.module.scss'
import { Row } from '../Row'
import { FormChildren, FormItemChildren } from 'types/form'
import { RADIO_BUTTONS } from './constants'
import { ActionButtons } from '../ActionButtons'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { useDispatch } from 'react-redux'
import { useApiRequest } from 'hooks/useApiRequest'
import { termsApi } from 'api'
import { prepareCorForSelect, prepareResponseItems } from 'utils/form'
import { IContentProps } from 'modules/TermsAndConditions/types'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useCompanyId } from 'hooks/useCompanyId'

export const InsuranceIncluded = ({
  reload,
  data,
  isHistoryExist,
}: IContentProps) => {
  const dispatch = useDispatch()
  const companyId = useCompanyId()
  const newTermConditionRequest = useApiRequest(
    (body) => termsApi.newTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const updateTermConditionRequest = useApiRequest(
    (body) => termsApi.updateTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )

  const initValues = useMemo(() => {
    const initData: IFormValues = {
      cor: !isHistoryExist ? COR_ITEMS : [],
      insurance: INSURANCE_INCLUDED_FORM_VALUES.INCLUDED.ID,
    }

    if (data) {
      initData.cor = prepareCorForSelect(data.countriesOfResidence)
      initData.insurance = data.insurance || initData.insurance
    }

    return initData
  }, [data])
  const onSubmit = async (values: IFormValues, validate: boolean) => {
    if (validate) {
      const body = {
        termConditionTypeId: TERM_CONDITIONS_TYPES.InsuranceIncluded,
        companyId,
        countriesOfResidence: prepareResponseItems(values.cor),
        insurance: values.insurance,
      }

      if (data) {
        await updateTermConditionRequest.apiRequest({
          ...body,
          termConditionSettingId: data.id,
        })
        dispatch(
          setNotificationMessage({
            notificationMessage: UPDATED_SUCCESS,
          })
        )
      } else {
        await newTermConditionRequest.apiRequest(body)
        dispatch(
          setNotificationMessage({
            notificationMessage: ADDED_SUCCESS,
          })
        )
      }

      reload()
    }
  }

  return (
    <Block title={INSURANCE_INCLUDED}>
      <Form
        initValues={initValues}
        onSubmit={onSubmit}
        className={styles.form}
        key={data?.id}
      >
        {({ values, isDirty }: FormChildren) => (
          <>
            <Badge
              message={
                values[INSURANCE_INCLUDED_FORM_VALUES.INSURANCE.ID] ===
                INSURANCE_INCLUDED_FORM_VALUES.INCLUDED.ID
                  ? INSURANCE_INCLUDED_NOTE
                  : INSURANCE_EXLUDED_NOTE
              }
              type="info"
              typographyName="Button1"
            />
            <div className={styles.cor}>
              <CorBtn />
            </div>
            <FormItem id={INSURANCE_INCLUDED_FORM_VALUES.INSURANCE.ID}>
              {({ value, onChange }: FormItemChildren) => (
                <Row>
                  {RADIO_BUTTONS.map(({ label, id }) => (
                    <RadioButton
                      labelTypographyName="body2WMedium"
                      key={id}
                      id={id}
                      label={label}
                      onChange={onChange}
                      checked={id === value}
                    />
                  ))}
                </Row>
              )}
            </FormItem>
            {values.insurance ===
              INSURANCE_INCLUDED_FORM_VALUES.INCLUDED.ID && (
              <HtmlTypography
                name="body2WMedium"
                html={INSURANCE_INCLUDED_DESCRIPTION}
                className={styles.description}
              />
            )}
            <ActionButtons
              isOffset
              disabled={data && !isDirty}
              onCancel={data && reload}
              loading={
                newTermConditionRequest.loading ||
                updateTermConditionRequest.loading
              }
            />
          </>
        )}
      </Form>
    </Block>
  )
}
