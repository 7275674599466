import { PaymentPeriod } from 'api/fleet/types'
import { RADIO_BUTTON_LABELS } from 'texts/equipmentDetails'

export const initialFormValues = {
  price: '',
  maxAmount: '',
  hirePoints: [],
  priceFor: '1',
  extraId: '',
}

export const RADIO_BUTTONS = [
  { label: RADIO_BUTTON_LABELS.RENT, id: String(PaymentPeriod.Rent) },
  { label: RADIO_BUTTON_LABELS.DAY, id: String(PaymentPeriod.Day) },
]
