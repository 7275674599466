import { LocationsApiTypes } from 'api'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  LOCATION_TABS,
  LOCATION_ADDING_FORM_VALUES,
  LIMIT_INFO,
} from 'texts/locationDetails'
import { Form } from 'ui/molecules/Form'
import { ContactInformationForm } from './components/ContactInformationForm'
import { userDataSelector, isOperatorSelector } from 'redux/login/selectors'
import { LocationsTable } from '../LocationsTable'
import { ContactInformationFormProps } from './components/ContactInformationForm/types'
import { useAppSelector } from 'redux/hooks'
import { ACCOUNT_STATUSES } from 'api/auth/constants'
import { WaitingHoursForm } from './components/WaitingHoursForm'
import { WorkingHoursForm } from './components/WorkingHoursForm'
import { Typography } from '@frontend/design_system'
import { InfoIcon } from 'ui/icons'
import { LeftSide } from './components/LeftSide'
import { RightSide } from './components/RightSide'
import { FormValues, LocationDetailsFormProps } from './types'
import { initialFormValues, WORKING_HOURS_INITIAL_VALUES } from './constants'
import {
  PickupOptions,
  WaitingHourFeeType,
  WaitingHourRange,
} from 'api/locations/types'
import { Location } from '../../hooks/useSelectLocation'
import countryOfResidence from 'constants/countryOfResidence'
import { ReservationForm } from './components/ReservationForm'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { useDriverAgeRangeValidation } from 'ui/components/DriverAgeRanges/hooks/useDriverAgeRangeValidation'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { setMapData } from 'redux/locations/slice'
import { LOCATION_TYPE_AIRPORT } from './components/ContactInformationForm/constants'
import { locationTypeSelector } from 'redux/locations/selectors'

export const LocationDetailsForm = ({
  handleSubmit,
  formValues,
  isLoading,
  isEditing,
  error,
  resetError,
}: LocationDetailsFormProps) => {
  const dispatch = useDispatch()
  const {
    latitude,
    longitude,
    phone,
    street,
    waitingHours,
    postalCode,
    waitingHourPrice,
    building,
    countryId,
    cityId,
    locationId,
    region,
    email,
    minimumPeriodDay,
    maximumPeriodDay,
    pickupOption,
    pickUpInstruction,
    dropOffInstruction,
    driverAgeRange,
    startReservationHour,
    currencyName,
    waitingHourFeeType,
  } = initialFormValues
  const workingHoursFormRef = useRef<HTMLDivElement>(null)
  const [formKey, setFormKey] = useState(0)
  const { accountStatus, currency } = useAppSelector(userDataSelector)
  const isOperator = useAppSelector(isOperatorSelector)
  const locationType = useAppSelector(locationTypeSelector)
  const showLimitInfoAndTable =
    !isOperator && accountStatus === ACCOUNT_STATUSES.REGISTERED
  const [isWorkingHoursChanged, setWorkingHoursChanged] = useState(false)
  const [workingHours, setWorkingHours] =
    useState<LocationsApiTypes.WorkingHours>(
      WORKING_HOURS_INITIAL_VALUES.map(
        (workHour) =>
          formValues?.workingHours?.find(
            ({ dayOfWeek }) => dayOfWeek === workHour.dayOfWeek
          ) || workHour
      )
    )
  const [currentLocation, setCurrentLocation] = useState<Location>({
    country: {
      name: formValues?.countryName || '',
      id: formValues?.countryId || countryId,
    },
    city: {
      name: formValues?.cityName || '',
      id: formValues?.cityId || cityId,
    },
    location: {
      name: formValues?.locationName || '',
      id: formValues?.locationId || locationId,
    },
  })
  const [activeKey, setActiveKey] = useState(LOCATION_TABS.INFORMATION)

  const [workingHoursError, setWorkingHoursError] = useState('')
  const [driverAgeRangeError, setDriverAgeRangeError] = useState('')

  useEffect(() => {
    dispatch(setMapData(null))
  }, [])

  const checkDriverAgeRange = useDriverAgeRangeValidation(true)

  const prepareWorkingHours = () => {
    let invalidDate = false
    let workingHoursOverlap = false
    let incompleteInfo = false
    let workingHoursPairsOverlap = false

    const result = workingHours.filter(
      ({ dayStart, dayEnd, dayStart2, dayEnd2 }) => {
        const getDateHours = (time: string) => {
          const [hour, minute] = time.split(':')

          return new Date().setHours(+hour, +minute)
        }

        if (dayStart && dayEnd) {
          if (
            !invalidDate &&
            getDateHours(dayEnd) - getDateHours(dayStart) <= 0
          ) {
            invalidDate = true
          }
        }

        if (dayStart2 && dayEnd2) {
          if (
            !invalidDate &&
            getDateHours(dayEnd2) - getDateHours(dayStart2) <= 0
          ) {
            invalidDate = true
          }
        }

        if (dayStart && dayEnd && dayStart2 && dayEnd2) {
          if (!invalidDate && !(dayEnd < dayStart2 || dayEnd2 <= dayStart)) {
            workingHoursOverlap = true
          }

          if (
            dayEnd <= dayStart ||
            dayStart2 <= dayEnd ||
            dayEnd2 <= dayStart2
          ) {
            workingHoursPairsOverlap = true
          }
        }

        if (
          (dayStart && !dayEnd) ||
          (!dayStart && dayEnd) ||
          (dayStart2 && !dayEnd2) ||
          (!dayStart2 && dayEnd2)
        ) {
          incompleteInfo = true
        }

        return dayStart && dayEnd
      }
    )

    if (!result.length) {
      setWorkingHoursError(
        LOCATION_ADDING_FORM_VALUES.WORKING_HOURS.REQUIRED_MESSAGE
      )

      return []
    } else if (incompleteInfo) {
      setWorkingHoursError(
        LOCATION_ADDING_FORM_VALUES.WORKING_HOURS.INCOMPLETE_INFORMATION
      )

      return []
    } else if (invalidDate) {
      setWorkingHoursError(
        LOCATION_ADDING_FORM_VALUES.WORKING_HOURS.VALIDATION_MESSAGE
      )

      return []
    } else if (workingHoursOverlap) {
      setWorkingHoursError(
        LOCATION_ADDING_FORM_VALUES.WORKING_HOURS.OVERLAP_MESSAGE
      )
      return []
    } else if (workingHoursPairsOverlap) {
      setWorkingHoursError(
        'The first set of opening hours must be earlier than the second one.'
      )
      return []
    }

    return result
  }

  const validateWorkingHours = () => {
    const preparedWorkingHours = prepareWorkingHours()

    if (!preparedWorkingHours.length) {
      workingHoursFormRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
    return preparedWorkingHours
  }

  const validateDriverAgeRange = (data: Partial<FormValues>) => {
    const driverAgeRangeError = checkDriverAgeRange(data?.driverAgeRange)
    if (driverAgeRangeError) {
      setDriverAgeRangeError(driverAgeRangeError)
    }
    return driverAgeRangeError
  }

  const onSubmit = (data: Partial<FormValues>, validate: boolean) => {
    const preparedWorkingHours = validateWorkingHours()
    const driverAgeRangeError = validateDriverAgeRange(data)

    if (validate && preparedWorkingHours.length && !driverAgeRangeError) {
      handleSubmit({
        ...data,
        locationId: currentLocation.location.id,
        phoneAreaCode: data.phoneCor?.code
          ? String(data.phoneCor?.phoneCode)
          : '',
        phoneCountryCode: data.phoneCor?.code
          ? String(data.phoneCor?.code)
          : '',
        dropoffHours: preparedWorkingHours,
        workingHours: preparedWorkingHours,
        pickupOption:
          locationType === LOCATION_TYPE_AIRPORT
            ? (data.pickupOption as PickupOptions)
            : undefined,
      })
    }
  }

  const initValues = useMemo(
    () => ({
      latitude: String(formValues?.latitude ?? latitude),
      longitude: String(formValues?.longitude ?? longitude),
      countryId: String(currentLocation.country.id),
      cityId: String(currentLocation.city.id),
      locationId: String(currentLocation.location.id),
      building: formValues?.building || building,
      phone: formValues?.phone || phone,
      phoneCor: countryOfResidence.find(
        (el) => el.code === formValues?.phoneCountryCode
      ),
      street: formValues?.street || street,
      waitingHours: String(
        formValues?.waitingHours?.waitingHours || waitingHours
      ),
      waitingHourPrice: String(
        formValues?.waitingHours?.waitingHourPrice || waitingHourPrice
      ),
      waitingHourRange:
        formValues?.waitingHours?.waitingHourRange || WaitingHourRange.Both,
      waitingHourFeeType:
        formValues?.waitingHours?.waitingHourFeeType || waitingHourFeeType,
      postalCode: formValues?.postalCode || postalCode,
      currencyName: formValues?.currencyName || currency || currencyName,
      region: formValues?.region || region,
      email: formValues?.email || email,
      minimumPeriodDay: formValues?.minimumPeriodDay
        ? String(formValues?.minimumPeriodDay)
        : minimumPeriodDay,
      maximumPeriodDay: formValues?.maximumPeriodDay
        ? String(formValues?.maximumPeriodDay)
        : maximumPeriodDay,
      pickupOption: formValues?.pickupOption || pickupOption,
      startReservationHour: formValues?.startReservationHour
        ? String(formValues?.startReservationHour)
        : startReservationHour,
      pickUpInstruction: formValues?.pickUpInstruction || pickUpInstruction,
      dropOffInstruction: formValues?.dropOffInstruction || dropOffInstruction,
      driverAgeRange: formValues?.driverAgeRange || driverAgeRange,
    }),
    [formValues, currentLocation]
  )

  const handleChangeLocation = (
    location: ContactInformationFormProps['currentLocation']
  ) => {
    setCurrentLocation(location)
  }

  const handleWorkingHoursChange = (
    workingHours: LocationsApiTypes.WorkingHours
  ) => {
    setWorkingHoursError('')
    setWorkingHoursChanged(true)
    setWorkingHours(workingHours)
  }

  const handleResetDriverAgeError = () => setDriverAgeRangeError('')

  const locationTabs = (values: Record<string, unknown>) => [
    {
      tab: LOCATION_TABS.INFORMATION,
      key: LOCATION_TABS.INFORMATION,
      children: (
        <ContactInformationForm
          currentLocation={currentLocation}
          changeLocation={handleChangeLocation}
          locationId={formValues?.locationId}
          changeActiveKey={setActiveKey}
          values={values}
        />
      ),
    },
    {
      tab: LOCATION_TABS.WORKING_HOURS,
      key: LOCATION_TABS.WORKING_HOURS,
      children: (
        <WorkingHoursForm
          workingHours={workingHours}
          onWorkingHoursChange={handleWorkingHoursChange}
          error={workingHoursError}
          ref={workingHoursFormRef}
          changeActiveKey={setActiveKey}
          validateWorkingHours={validateWorkingHours}
        />
      ),
    },
    {
      tab: LOCATION_TABS.RESERVATION,
      key: LOCATION_TABS.RESERVATION,
      children: (
        <ReservationForm
          formValues={values}
          driverAgeRangeError={driverAgeRangeError}
          resetDriverAgeError={handleResetDriverAgeError}
          changeActiveKey={setActiveKey}
          validateDriverAgeRange={validateDriverAgeRange}
          validateWorkingHours={validateWorkingHours}
        />
      ),
    },
    {
      tab: LOCATION_TABS.WAITING_HOURS,
      key: LOCATION_TABS.WAITING_HOURS,
      children: (
        <WaitingHoursForm
          withFee={values.waitingHourFeeType === WaitingHourFeeType.Fee}
        />
      ),
    },
  ]

  useDidUpdate(() => {
    if (error && resetError) {
      setFormKey((prev) => prev + 1)
      setCurrentLocation({
        country: {
          name: '',
          id: '',
        },
        city: {
          name: '',
          id: '',
        },
        location: {
          name: '',
          id: '',
        },
      })
      setWorkingHours(
        WORKING_HOURS_INITIAL_VALUES.map(
          (workHour) =>
            formValues?.workingHours?.find(
              ({ dayOfWeek }) => dayOfWeek === workHour.dayOfWeek
            ) || workHour
        )
      )
      setWorkingHoursChanged(false)
      resetError()
    }
  }, [error])

  return (
    <>
      <Form
        initValues={initValues}
        onSubmit={onSubmit}
        key={formKey}
        className={styles['form-wrapper']}
      >
        {({ values, isDirty }) => (
          <>
            {showLimitInfoAndTable && (
              <div className={styles.info}>
                <InfoIcon color="blue700" size="small" />
                <Typography
                  name="body1WMedium"
                  color="blue700"
                  className={styles['contact-item-info-value']}
                >
                  {LIMIT_INFO}
                </Typography>
              </div>
            )}
            <div className={styles.container}>
              <LeftSide
                changeActiveKey={setActiveKey}
                activeKey={activeKey}
                tabs={locationTabs(values)}
                driverAgeRangeError={driverAgeRangeError}
                workingHoursError={workingHoursError}
              />
              <RightSide
                activeKey={activeKey}
                formValues={values}
                isDirty={!!isDirty}
                isWorkingHoursChanged={isWorkingHoursChanged}
                workingHours={workingHours}
                isLoading={isLoading}
                isEditing={isEditing}
              />
            </div>
            {showLimitInfoAndTable && <LocationsTable isDirty={!!isDirty} />}
          </>
        )}
      </Form>
    </>
  )
}
