import { FleetApiTypes } from 'api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  PRICE_LIST_DETAILS,
  PRICE_LIST_DETAILS_FORM_VALUES,
} from 'texts/priceListDetails'
import {
  OriginButton,
  Typography,
  Multiselect,
  Form,
  FormItem,
  Input,
  Textarea,
  Badge,
  Select,
} from '@frontend/design_system'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import arrowRight from 'assets/img/arrowRight.webp'
import { FormValues, RateDetailsFormProps } from './types'
import { APPLY_BY, initialFormValues } from './constants'
import { CANCEL } from 'texts/uiTexts'
import { Ranges } from './components/Ranges'
import { useSelectValues } from 'hooks/useSelectValues'
import {
  dailyRangeOverlapValidation,
  seasonRangeOverlapValidation,
  seasonRangeDateValidation,
  seasonRangeEmptyDateValidation,
  dailyRangeGapsValidation,
  dailyRangeEmptyValidation,
  seasonRangeDifferentYearValidation,
  dailyRangeFirstRangeValidation,
} from './utils'
import { allSymbolsAndEnglishLettersRegex } from 'constants/regex'
import styles from './styles.module.scss'
import { FormChildren, FormItemChildren } from 'types/form'
import { COR_ITEMS } from 'constants/countryOfResidence'
import { useCompanyId } from 'hooks/useCompanyId'
import { URLS } from 'constants/urls'

const COR_RULES = [
  {
    type: 'required',
    message: PRICE_LIST_DETAILS_FORM_VALUES.COR.REQUIRED_MESSAGE,
  },
]

export const PriceListDetailsForm = ({
  handleSubmit,
  initialValues = initialFormValues,
  isLoading,
  readOnly,
  submitButtonLabel,
  navigateToRates,
  locations,
}: RateDetailsFormProps) => {
  const [formKey, setFormKey] = useState(0)
  const navigate = useNavigate()

  const isEditForm = !!navigateToRates

  const locationsSelectValues = useSelectValues(locations)

  const companyId = useCompanyId()

  const onSubmit = (data: Partial<FormValues>, validate: boolean) => {
    if (validate) {
      handleSubmit(data)
    }
  }

  useEffect(() => {
    setFormKey(formKey + 1)
  }, [initialValues])

  const rules = useMemo(
    () => ({
      hirePoints: [
        {
          type: 'required',
          message: PRICE_LIST_DETAILS_FORM_VALUES.HIRE_POINTS.REQUIRED_MESSAGE,
        },
      ],
      year: [
        {
          type: 'required',
          message: PRICE_LIST_DETAILS_FORM_VALUES.YEAR.REQUIRED_MESSAGE,
        },
      ],
      seasonRanges: [
        {
          type: 'required',
          message:
            PRICE_LIST_DETAILS_FORM_VALUES.SEASON_RANGES.REQUIRED_MESSAGE,
        },
        {
          type: 'custom',
          value: seasonRangeDateValidation,
        },
        {
          type: 'custom',
          value: seasonRangeEmptyDateValidation,
        },
        {
          type: 'custom',
          value: seasonRangeOverlapValidation,
        },
        {
          type: 'custom',
          value: seasonRangeDifferentYearValidation,
        },
      ],
      dailyRanges: [
        {
          type: 'required',
          message: PRICE_LIST_DETAILS_FORM_VALUES.DAILY_RANGES.REQUIRED_MESSAGE,
        },
        {
          type: 'custom',
          value: dailyRangeEmptyValidation,
        },
        {
          type: 'custom',
          value: dailyRangeOverlapValidation,
        },
        {
          type: 'custom',
          value: dailyRangeGapsValidation,
        },
        {
          type: 'custom',
          value: dailyRangeFirstRangeValidation,
        },
      ],
      comment: [
        {
          type: 'pattern',
          value: allSymbolsAndEnglishLettersRegex,
          message: PRICE_LIST_DETAILS_FORM_VALUES.COMMENT.VALIDATION_MESSAGE,
        },
      ],
    }),
    []
  )

  const handleCancel = () =>
    navigate(`${URLS.FLEET}${URLS.PRICE_LIST}/${companyId}`)

  return (
    <Form
      onSubmit={onSubmit}
      initValues={initialValues}
      className={styles.form}
      key={formKey}
    >
      {({ isDirty }: FormChildren) => (
        <>
          <ContentWrapper
            className={styles.form}
            title={PRICE_LIST_DETAILS.TITLE}
          >
            <>
              <div className={styles['form-items-row']}>
                <FormItem
                  id={PRICE_LIST_DETAILS_FORM_VALUES.HIRE_POINTS.ID}
                  className={styles['form-item']}
                  rules={rules.hirePoints}
                >
                  {({ value, error, onChange }: FormItemChildren) => (
                    <Multiselect
                      error={error}
                      onChange={onChange}
                      items={locationsSelectValues}
                      readonly={readOnly}
                      placeholder={
                        PRICE_LIST_DETAILS_FORM_VALUES.HIRE_POINTS.PLACEHOLDER
                      }
                      label={PRICE_LIST_DETAILS_FORM_VALUES.HIRE_POINTS.LABEL}
                      value={value}
                      isRequired
                      unitName={{
                        plural: PRICE_LIST_DETAILS.SEARCH_VALUE,
                      }}
                    />
                  )}
                </FormItem>
                <FormItem
                  id={PRICE_LIST_DETAILS_FORM_VALUES.YEAR.ID}
                  className={styles['form-item']}
                  rules={rules.year}
                >
                  {({ value, error, onChange }: FormItemChildren) => (
                    <Input
                      value={value}
                      size="large"
                      isRequired
                      placeholder={
                        PRICE_LIST_DETAILS_FORM_VALUES.YEAR.PLACEHOLDER
                      }
                      error={error}
                      maxLength={4}
                      spaceForError="auto"
                      type="integer"
                      disabled={readOnly}
                      onChange={onChange}
                      label={PRICE_LIST_DETAILS_FORM_VALUES.YEAR.LABEL}
                    />
                  )}
                </FormItem>
              </div>
              <div className={styles['form-items-row']}>
                <FormItem
                  id={PRICE_LIST_DETAILS_FORM_VALUES.COR.ID}
                  rules={COR_RULES}
                  className={styles['form-item']}
                >
                  {({ value, error, onChange }: FormItemChildren) => (
                    <Multiselect
                      value={value}
                      placeholder={
                        PRICE_LIST_DETAILS_FORM_VALUES.COR.PLACEHOLDER
                      }
                      error={error}
                      tooltip={
                        <Typography name="caption1WMedium">
                          {PRICE_LIST_DETAILS_FORM_VALUES.COR.TOOLTIP}
                        </Typography>
                      }
                      items={COR_ITEMS}
                      onChange={onChange}
                      label={PRICE_LIST_DETAILS_FORM_VALUES.COR.LABEL}
                      isRequired
                      disabled={readOnly}
                    />
                  )}
                </FormItem>
                <div className={styles['form-item']} />
              </div>
            </>
          </ContentWrapper>
          <ContentWrapper
            className={styles.form}
            title={PRICE_LIST_DETAILS.SEASON_RANGE}
          >
            <FormItem
              id={PRICE_LIST_DETAILS_FORM_VALUES.SEASON_RANGES.ID}
              className={styles['form-item']}
              rules={rules.seasonRanges}
            >
              {({ value, onChange, error }: FormItemChildren) => (
                <Ranges<FleetApiTypes.SeasonRange>
                  ranges={value}
                  readOnly={readOnly}
                  onRangesChange={onChange}
                  type="season"
                  error={error}
                />
              )}
            </FormItem>
          </ContentWrapper>
          <ContentWrapper
            className={styles.form}
            title={PRICE_LIST_DETAILS.DAILY_RANGE}
          >
            <FormItem
              id={PRICE_LIST_DETAILS_FORM_VALUES.DAILY_RANGES.ID}
              className={styles['form-item']}
              rules={rules.dailyRanges}
            >
              {({ value, onChange, error }: FormItemChildren) => (
                <Ranges<FleetApiTypes.DailyRange>
                  ranges={value}
                  readOnly={readOnly}
                  onRangesChange={onChange}
                  type="daily"
                  error={error}
                />
              )}
            </FormItem>
          </ContentWrapper>
          <ContentWrapper className={styles.form}>
            <>
              <FormItem id={PRICE_LIST_DETAILS_FORM_VALUES.PRICE_LIST_TYPE.ID}>
                {({ value, onChange }: FormItemChildren) => (
                  <>
                    <div className={styles['price-list-type-select']}>
                      <Select
                        items={Object.entries(APPLY_BY).map(([key, value]) => ({
                          label: value,
                          value: key,
                        }))}
                        disabled={readOnly}
                        selectedValue={value}
                        noSpaceForError
                        onChange={onChange}
                        label={
                          PRICE_LIST_DETAILS_FORM_VALUES.PRICE_LIST_TYPE.LABEL
                        }
                        size="large"
                      />
                    </div>
                    {isEditForm && value !== initialValues.priceListType && (
                      <Badge
                        type="warning"
                        message={
                          PRICE_LIST_DETAILS_FORM_VALUES.PRICE_LIST_TYPE.BADGE
                        }
                        className={styles['price-list-type-badge']}
                      />
                    )}
                  </>
                )}
              </FormItem>
              <FormItem
                id={PRICE_LIST_DETAILS_FORM_VALUES.COMMENT.ID}
                rules={rules.comment}
              >
                {({ value, onChange, error }: FormItemChildren) => (
                  <Textarea
                    value={value}
                    disabled={readOnly}
                    onChange={onChange}
                    label={PRICE_LIST_DETAILS_FORM_VALUES.COMMENT.LABEL}
                    placeholder={
                      PRICE_LIST_DETAILS_FORM_VALUES.COMMENT.PLACEHOLDER
                    }
                    error={error}
                  />
                )}
              </FormItem>
            </>
          </ContentWrapper>
          <div className={styles.buttons}>
            <div className={styles['button-wrapper']}>
              <OriginButton
                variant="secondary-border"
                size="large"
                onClick={handleCancel}
                label={CANCEL}
              />
            </div>
            <div className={styles['button-wrapper']}>
              <OriginButton
                htmlType="submit"
                size="large"
                label={submitButtonLabel}
                loading={isLoading}
                disabled={!isDirty}
              />
            </div>
            {navigateToRates && (
              <div className={styles['button-wrapper']}>
                <OriginButton
                  variant="link"
                  size="large"
                  onClick={navigateToRates}
                  label={PRICE_LIST_DETAILS.GO_TO_RATES}
                  typographyName="Button1"
                  iconRight={<img src={arrowRight} width={16} height={16} />}
                />
              </div>
            )}
          </div>
        </>
      )}
    </Form>
  )
}
