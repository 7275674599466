import {
  LOCATION_ADDING_FORM_VALUES,
  WAITING_HOURS_PERIOD,
} from 'texts/locationDetails'
import { Input, Typography, RadioButton } from '@frontend/design_system'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import {
  WAITING_HOURS_FEE_RADIO_BUTTONS,
  WAITING_HOURS_RADIO_BUTTONS,
} from './constants'
import styles from './styles.module.scss'
import { useMemo } from 'react'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import {
  onlyNumbersWithPlus,
  onlyNumbersWithDotRegex,
  onlyNumbersWithOneDotRegex,
  allCharactersExceptNumbers,
} from 'constants/regex'

export const WaitingHoursForm = ({ withFee }: { withFee: boolean }) => {
  const waitingHoursRules: Rule[] = useMemo(
    () => [
      {
        type: 'pattern',
        value: onlyNumbersWithPlus,
        message: LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.VALIDATION_MESSAGE,
      },
    ],
    []
  )
  const priceRules: Rule[] = useMemo(
    () => [
      {
        type: 'pattern',
        value: onlyNumbersWithDotRegex,
        message: LOCATION_ADDING_FORM_VALUES.PRICE.VALIDATION_MESSAGE,
      },
    ],
    []
  )

  return (
    <div>
      <div>
        <Typography
          name="Button2"
          Tag="span"
          className={styles['radio-button-label']}
        >
          {LOCATION_ADDING_FORM_VALUES.WAITING_HOURS_FEE_TYPE.LABEL}:
        </Typography>
        <FormItem id={LOCATION_ADDING_FORM_VALUES.WAITING_HOURS_FEE_TYPE.ID}>
          {({ value, onChange, onChangeField }) => (
            <>
              {Object.entries(WAITING_HOURS_FEE_RADIO_BUTTONS).map(
                ([key, label]) => (
                  <span className={styles['radio-button']} key={key}>
                    <RadioButton
                      labelTypographyName="Button2"
                      id={key}
                      key={key}
                      label={label}
                      prefix="fee"
                      onChange={(value: string) => {
                        onChange(value)
                        onChangeField('', LOCATION_ADDING_FORM_VALUES.PRICE.ID)
                      }}
                      checked={key === value}
                    />
                  </span>
                )
              )}
            </>
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.ID}
          className={styles['form-item']}
          rules={waitingHoursRules}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={(value: string) =>
                onChange(value.replace(allCharactersExceptNumbers, ''))
              }
              label={LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.LABEL}
              placeholder={
                LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.PLACEHOLDER
              }
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.PRICE.ID}
          className={styles['form-item']}
          rules={priceRules}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              size="large"
              error={error}
              readonly={!withFee}
              isDisabled={!withFee}
              spaceForError="auto"
              onChange={(value: string) =>
                onChange(value.replace(onlyNumbersWithOneDotRegex, ''))
              }
              label={LOCATION_ADDING_FORM_VALUES.PRICE.LABEL}
              placeholder={
                withFee ? LOCATION_ADDING_FORM_VALUES.PRICE.PLACEHOLDER : ''
              }
            />
          )}
        </FormItem>
      </div>
      <div>
        <Typography
          name="Button2"
          Tag="span"
          className={styles['radio-button-label']}
        >
          {WAITING_HOURS_PERIOD}:
        </Typography>
        <FormItem id={LOCATION_ADDING_FORM_VALUES.WAITING_HOURS_RANGE.ID}>
          {({ value, onChange }) => (
            <>
              {Object.entries(WAITING_HOURS_RADIO_BUTTONS).map(
                ([key, label]) => (
                  <span className={styles['radio-button']} key={key}>
                    <RadioButton
                      labelTypographyName="Button2"
                      id={key}
                      label={label}
                      onChange={onChange}
                      checked={key === value}
                    />
                  </span>
                )
              )}
            </>
          )}
        </FormItem>
      </div>
    </div>
  )
}
