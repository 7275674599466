import { useMemo, useState } from 'react'
import { FORM_FIELDS_VALUES } from 'texts/formFields'
import {
  RESET_PASSWORD_NOTIFICATION_MESSAGE,
  RESET_PASSWORD_TEXT_FIELDS,
} from 'texts/resetPassword'
import { confirmPasswordRules, newPasswordRules } from 'texts/validateRules'
import { NewPasswordFormProps } from './types'
import { authApi } from 'api'
import { ErrorResponse } from 'api/types'
import { useAppDispatch } from 'redux/hooks'
import { login } from 'redux/login/thunks'
import { useNavigate } from 'react-router-dom'
import { Badge, InputPassword, Button } from '@frontend/design_system'
import { setNotificationMessage } from 'redux/notifications/slice'
import { handleRedirectToMain } from 'utils/handleRedirectToMain'
import { Typography } from 'ui/atoms/Typography'
import { Form } from 'ui/molecules/Form'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { NewPasswordFormData } from 'modules/auth/types'
import { initialFormValues } from './constants'
import { PASSWORD_SCALE } from 'texts/uiTexts'
import { PasswordChecklist } from 'ui/atoms/InputPassword/components/PasswordChecklist'
import {
  minimum8Regex,
  must1DigitalRegex,
  must1LowerAndUpperRegex,
} from 'constants/regex'
import styles from './styles.module.scss'

export const NewPasswordForm = ({
  resetPasswordData,
}: NewPasswordFormProps) => {
  const [errorMessage, setErrorMessage] = useState('')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onSubmit = async (data: NewPasswordFormData, validate: boolean) => {
    setErrorMessage('')
    if (validate && data.password) {
      try {
        await authApi.resetPassword(
          resetPasswordData.passwordResetToken,
          data.password
        )
        await dispatch(
          login({
            email: resetPasswordData.email,
            password: data.password,
            onSuccess: (role) => handleRedirectToMain(role, navigate),
          })
        )
        dispatch(
          setNotificationMessage({
            notificationMessage: RESET_PASSWORD_NOTIFICATION_MESSAGE,
          })
        )
      } catch (error) {
        setErrorMessage((error as ErrorResponse).responseStatus.message)
      }
    }
  }

  const passwordScaleRules = useMemo(
    () => [
      {
        label: PASSWORD_SCALE.RULES.MIN_CHARACTERS,
        rule: minimum8Regex,
      },
      {
        label: PASSWORD_SCALE.RULES.UPPER_AND_LOWER_CASE,
        rule: must1LowerAndUpperRegex,
      },
      {
        label: PASSWORD_SCALE.RULES.ONE_NUMBER,
        rule: must1DigitalRegex,
      },
    ],
    []
  )

  const passwordScale = {
    label: PASSWORD_SCALE.LABEL,
    labelWeak: PASSWORD_SCALE.WEAK,
    labelMedium: PASSWORD_SCALE.MEDIUM,
    labelStrong: PASSWORD_SCALE.STRONG,
    rules: passwordScaleRules,
  }

  return (
    <div data-testid="new-password-form">
      <Typography Tag="h3" name="H3" className={styles.header}>
        {RESET_PASSWORD_TEXT_FIELDS.NEW_PASSWORD_FORM.TITLE}
      </Typography>
      <Form
        onSubmit={onSubmit}
        className={styles['form-fields']}
        initValues={initialFormValues}
      >
        {({ values }) => (
          <>
            <div className={styles.form}>
              <FormItem
                id={FORM_FIELDS_VALUES.PASSWORD.ID}
                rules={newPasswordRules}
                className={styles['form-item']}
              >
                {({ error, onChange, value }) => (
                  <InputPassword
                    value={value}
                    error={error}
                    onChange={onChange}
                    size="large"
                    label={
                      <div className={styles['new-password-label']}>
                        {FORM_FIELDS_VALUES.NEW_PASSWORD.LABEL}
                      </div>
                    }
                    placeholder={FORM_FIELDS_VALUES.NEW_PASSWORD.PLACEHOLDER}
                    withCompare
                    passwordScale={passwordScale}
                    dataTestId="new-password-input"
                  />
                )}
              </FormItem>
              <FormItem
                id={FORM_FIELDS_VALUES.CONFIRM_PASSWORD.ID}
                rules={confirmPasswordRules}
                className={styles['form-item']}
              >
                {({ error, onChange, value }) => (
                  <InputPassword
                    value={value}
                    error={error}
                    onChange={onChange}
                    size="large"
                    label={FORM_FIELDS_VALUES.CONFIRM_NEW_PASSWORD.LABEL}
                    placeholder={
                      FORM_FIELDS_VALUES.CONFIRM_NEW_PASSWORD.PLACEHOLDER
                    }
                    dataTestId="confirm-password-input"
                  />
                )}
              </FormItem>
            </div>
            <PasswordChecklist
              value={values.password as string}
              passwordScale={passwordScale}
            />
            {errorMessage && (
              <Badge
                message={errorMessage}
                className={styles.badge}
                type="error"
                dataTestId="error-badge"
              />
            )}
            <div className={styles['button-wrapper']}>
              <Button
                label={
                  RESET_PASSWORD_TEXT_FIELDS.NEW_PASSWORD_FORM.BUTTON_LABEL
                }
                htmlType="submit"
                disabled={!(values.password && values.confirmPassword)}
                size="large"
                dataTestId="new-password-submit-button"
              />
            </div>
          </>
        )}
      </Form>
    </div>
  )
}
