import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Container } from 'ui/molecules/Container'
import { VehicleDetailsForm } from '../VehicleDetailsForm'
import { fleetApi } from 'api/fleet'
import { AddVehicleRequest } from 'api/fleet/types'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useApiRequest } from 'hooks/useApiRequest'
import { SUCCESS_UPDATE_MESSAGE } from 'texts/common'
import { VEHICLE_DETAILS_BREADCRUMB, VEHICLE_TITLE } from 'texts/vehicleDetails'
import { ApproveBtn } from 'ui/components/ApproveBtn'
import { ACCOUNT_STATUSES } from 'api/auth/constants'
import { URLS } from 'constants/urls'
import { useInitVehicleData } from '../../hooks/useInitVehicleData'
import { useCompanyId } from 'hooks/useCompanyId'

export const VehicleEdit = () => {
  const params = useParams()
  const [initialData, setInitialData] = useState<Partial<AddVehicleRequest>>({})
  const isOperator = useAppSelector(isOperatorSelector)
  const { accountStatus } = useAppSelector(userDataSelector)

  const isAccountApproved =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED

  const vehicleId = params.id || ''

  const companyId = useCompanyId()

  const { vehicleDetailsData, isLoaded } = useInitVehicleData({
    supplierId: String(companyId),
  })

  const updateVehicleResponse = useApiRequest(
    (vehicleId, data) => fleetApi.updateVehicleInfo(vehicleId, data),
    undefined,
    true,
    undefined,
    false
  )

  const getVehicleInfoRequest = useApiRequest((vehicleId) =>
    fleetApi.getVehicleInfo(vehicleId)
  )

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'vehicles'
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const getInfo = async () => {
      if (vehicleId) {
        const response = await getVehicleInfoRequest.apiRequest(vehicleId)
        if (response) {
          setInitialData(response.data.vehicleInfo)
        }
      }
    }
    getInfo()
  }, [])

  const handleSubmit = async (data: AddVehicleRequest) => {
    if (vehicleId) {
      const response = await updateVehicleResponse.apiRequest(vehicleId, data)
      if (response) {
        dispatch(
          setNotificationMessage({
            notificationMessage: SUCCESS_UPDATE_MESSAGE,
          })
        )
        if (isAccountApproved) {
          const path = isOperator
            ? `${URLS.FLEET}${URLS.VEHICLES}/${companyId}`
            : `${URLS.FLEET}${URLS.VEHICLES}`
          navigate(path)
        } else {
          navigate(`${URLS.FLEET}${URLS.VEHICLES}/add`)
        }
      }
    }
  }

  return (
    <Container
      title={VEHICLE_TITLE}
      breadcrumbList={breadcrumbList}
      currentPageLabel={VEHICLE_DETAILS_BREADCRUMB}
      withNavigation={isOperator}
      rightBlock={(id: string) => <ApproveBtn companyId={id} />}
      loading={getVehicleInfoRequest.loading && !isLoaded}
    >
      <VehicleDetailsForm
        formValues={initialData}
        handleSubmit={handleSubmit}
        isLoading={updateVehicleResponse.loading}
        vehicleDetailsData={vehicleDetailsData}
        isEditing
      />
    </Container>
  )
}
