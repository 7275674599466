import { useEffect, useState } from 'react'
import { VehicleDetailsData } from '../components/VehicleDetailsForm/types'
import { useApiRequest } from 'hooks/useApiRequest'
import { referencesApi } from 'api'

export const useInitVehicleData = ({
  supplierId,
  isAccountPending,
}: {
  supplierId: string
  isAccountPending?: boolean
}) => {
  const [vehicleDetailsData, setVehicleDetailsData] =
    useState<VehicleDetailsData>({
      carCategories: [],
      cars: [],
      carTypes: [],
      doors: [],
      seats: [],
    })

  const getAllCarGroupsRequest = useApiRequest(() =>
    referencesApi.getAllCarGroups()
  )
  const getAllCarsRequest = useApiRequest(() =>
    referencesApi.getAllCars(supplierId)
  )
  const getAllCarTypesRequest = useApiRequest(() =>
    referencesApi.getAllCarTypes()
  )

  const getCarSeatsRequest = useApiRequest(() => referencesApi.getCarSeats())

  const getCarDoorsRequest = useApiRequest(() => referencesApi.getCarDoors())

  useEffect(() => {
    if (!isAccountPending) {
      const fetchVehicleDetailsData = async () => {
        const [carCategories, cars, carTypes, seats, doors] = await Promise.all(
          [
            getAllCarGroupsRequest.apiRequest(),
            getAllCarsRequest.apiRequest(),
            getAllCarTypesRequest.apiRequest(),
            getCarSeatsRequest.apiRequest(),
            getCarDoorsRequest.apiRequest(),
          ]
        )
        if (carCategories && cars && carTypes && seats && doors) {
          setVehicleDetailsData((prev) => ({
            ...prev,
            carCategories: carCategories.data.items,
            cars: cars.data.items,
            carTypes: carTypes.data.items,
            doors: doors.data.items,
            seats: seats.data.items,
          }))
        }
      }
      fetchVehicleDetailsData()
    }
  }, [])

  return {
    vehicleDetailsData,
    isLoaded:
      getAllCarGroupsRequest.isLoaded &&
      getAllCarsRequest.isLoaded &&
      getAllCarTypesRequest.isLoaded &&
      getCarDoorsRequest.isLoaded &&
      getCarSeatsRequest.isLoaded,
  }
}
