import {
  LOCATION_ADDING_FORM_VALUES,
  PICKUP_OPTIONS,
} from 'texts/locationDetails'
import { useSelector } from 'react-redux'
import { Typography, LazyImage } from '@frontend/design_system'
import {
  locationsSelector,
  countriesSelector,
  citiesSelector,
  mapDataSelector,
  locationTypeSelector,
} from 'redux/locations/selectors'
import filterMapImg from 'assets/img/filterMap.webp'
import { CheckedIcon } from 'ui/icons'
import { ContactInformationBlockProps, ValueItem } from './types'
import { CONTACT_FORM_FIELDS, PICKUP_OPTIONS_VALUES } from '../../constants'
import styles from './styles.module.scss'
import { Map } from './components/Map'
import { LOCATION_TYPE_AIRPORT } from '../ContactInformationForm/constants'

const END_INDEX = -3

export const ContactInformationBlock = ({
  values,
}: ContactInformationBlockProps) => {
  const locations = useSelector(locationsSelector)
  const countries = useSelector(countriesSelector)
  const cities = useSelector(citiesSelector)
  const mapData = useSelector(mapDataSelector)
  const locationType = useSelector(locationTypeSelector)

  const titleFormat = {
    [LOCATION_ADDING_FORM_VALUES.COUNTRY.ID]: (value: ValueItem) =>
      countries.find((item) => item.value === value)?.label,
    [LOCATION_ADDING_FORM_VALUES.CITY.ID]: (value: ValueItem) =>
      cities.find((item) => item.value === value)?.label,
    [LOCATION_ADDING_FORM_VALUES.LOCATION.ID]: (value: ValueItem) =>
      locations.find((item) => item.value === value)?.label,
    [LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.ID]: (value: ValueItem) => {
      const cor = values.phoneCor?.phoneCode
        ? `+${values.phoneCor?.phoneCode}`
        : ''
      return `${cor} ${value}`
    },
    [LOCATION_ADDING_FORM_VALUES.PICKUP_OPTION.ID]: (value: ValueItem) => {
      if (
        typeof value === 'string' &&
        PICKUP_OPTIONS_VALUES[value] !==
          PICKUP_OPTIONS_VALUES[PICKUP_OPTIONS.DEFAULT.ID]
      ) {
        return PICKUP_OPTIONS_VALUES[value]
      }
      return ''
    },
  }

  const renderItem = (id: string, value?: ValueItem) => {
    let formatValue = value

    if (!!titleFormat[id] && value) {
      formatValue = titleFormat[id](value)
    }

    const isChecked =
      id === 'phone' ? formatValue && values.phoneCor?.phoneCode : formatValue

    return (
      <div
        className={styles['contact-item-info']}
        key={CONTACT_FORM_FIELDS[id]}
      >
        <div className={styles['contact-item-info-icon']}>
          <CheckedIcon
            color={isChecked ? 'green700Master' : 'grey500'}
            size="medium"
          />
        </div>

        <Typography
          name="body1WBold"
          className={styles['contact-item-info-block']}
        >
          {CONTACT_FORM_FIELDS[id]}:
          <Typography
            name="body1WMedium"
            className={styles['contact-item-info-value']}
          >
            {formatValue || '-'}
          </Typography>
        </Typography>
      </div>
    )
  }

  return (
    <div>
      {mapData ? (
        <Map mapData={mapData} />
      ) : (
        <div className={styles['filter-map-img']}>
          <LazyImage src={filterMapImg} minWidth={260} minHeight={96} />
        </div>
      )}
      {Object.keys(CONTACT_FORM_FIELDS)
        .slice(0, END_INDEX)
        .map((key: string) => {
          const value = values[key as keyof typeof values]
          if (
            key === LOCATION_ADDING_FORM_VALUES.PICKUP_OPTION.ID &&
            locationType !== LOCATION_TYPE_AIRPORT
          ) {
            return ''
          }
          return renderItem(key, value)
        })}
    </div>
  )
}
