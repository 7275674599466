export const formatValuesToSelectItems = (obj: { [key: string]: string }) =>
  Object.values(obj).map((el) => ({
    value: el,
    label: el,
  }))

export const formatNumberWithCommas = (number: number | string) => {
  const [integerPart, decimalPart] = number.toString().split('.')

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger
}
