import { useState } from 'react'
import { sendRegistrationCode } from 'redux/registration/thunks'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { Badge, Button } from '@frontend/design_system'
import { errorMessageSelector } from 'redux/registration/selectors'
import { Typography } from 'ui/atoms/Typography'
import { CodeInput } from 'ui/molecules/CodeInput'
import { CONFIRMATION_CODE_FIELDS } from 'texts/signup'
import { Props } from './types'
import styles from './styles.module.scss'

export const CodeConfirmation = ({ initialCode }: Props) => {
  const initialState = initialCode || ['', '', '', '']
  const [pinCodeValues, setPinCodeValues] = useState(initialState)
  const dispatch = useAppDispatch()
  const errorMessage = useAppSelector(errorMessageSelector)

  const handleSendCode = async () => {
    const code = pinCodeValues.join('')
    dispatch(sendRegistrationCode(code))
  }

  return (
    <div className={styles['signup-form']}>
      <Typography Tag="h3" name="H3" className={styles.header}>
        {CONFIRMATION_CODE_FIELDS.TITLE}
      </Typography>
      <Typography Tag="span" name="Button2" color="grey500">
        {CONFIRMATION_CODE_FIELDS.SUBTITLE}
      </Typography>
      <div className={styles.pincode}>
        <CodeInput values={pinCodeValues} onChange={setPinCodeValues} />
      </div>
      {errorMessage && (
        <Badge
          message={errorMessage}
          className={styles.badge}
          type="error"
          dataTestId="error-badge"
        />
      )}
      <div className={styles['button-wrapper']}>
        <Button
          label={CONFIRMATION_CODE_FIELDS.BUTTON}
          htmlType="button"
          size="large"
          onClick={handleSendCode}
          dataTestId="send-code-button"
        />
      </div>
    </div>
  )
}
