import { useMemo } from 'react'
import { PAYMENT_INFORMATION_FORM_VALUES } from 'texts/profileDetails'
import { Input } from 'ui/atoms/Input'
import { Select } from 'ui/atoms/Select/Select'
import { getValidationRules } from 'utils/getValidationRules'
import { paymentInformationFormValuesSetting as formValuesSetting } from './constants'
import { FormItem } from '@frontend/design_system'
import { PaymentInformationFormProps } from './types'
import styles from './styles.module.scss'
import { FormItemChildren, FormValuesSetting } from 'types/form'
import { useAppSelector } from 'redux/hooks'
import { userDataSelector } from 'redux/login/selectors'
import { ACCOUNT_STATUSES } from 'api/auth/constants'

export const PaymentInformationForm = ({
  role,
  currencies,
  readOnly,
}: PaymentInformationFormProps) => {
  const { accountStatus } = useAppSelector(userDataSelector)
  const isUserApproved = accountStatus === ACCOUNT_STATUSES.APPROVED

  const selectValues = useMemo(
    () =>
      currencies.items.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [currencies]
  )

  const validationRules = (field: FormValuesSetting['key']) =>
    useMemo(() => getValidationRules(field, role), [role])

  const isDisabledField = (name: string) =>
    readOnly || (isUserApproved && formValuesSetting[name].disabled[role])

  return (
    <>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.LEGAL_NAME.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.legalName)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={
                PAYMENT_INFORMATION_FORM_VALUES.LEGAL_NAME.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={PAYMENT_INFORMATION_FORM_VALUES.LEGAL_NAME.LABEL}
              disabled={isDisabledField('legalName')}
              isRequired={formValuesSetting.legalName.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.CURRENCY.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.currency)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Select
              size="large"
              error={error}
              onChange={onChange}
              label={PAYMENT_INFORMATION_FORM_VALUES.CURRENCY.LABEL}
              items={selectValues}
              selectedValue={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.CURRENCY.PLACEHOLDER}
              isRequired
              disabled={isDisabledField('currency')}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.REGISTRATION_NUMBER.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.registrationNumber)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={
                PAYMENT_INFORMATION_FORM_VALUES.REGISTRATION_NUMBER.PLACEHOLDER
              }
              size="large"
              error={error}
              maxLength={15}
              spaceForError="auto"
              onChange={onChange}
              disabled={isDisabledField('registrationNumber')}
              label={PAYMENT_INFORMATION_FORM_VALUES.REGISTRATION_NUMBER.LABEL}
              isRequired={formValuesSetting.registrationNumber.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.VAT.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.vat)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.VAT.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              maxLength={20}
              onChange={onChange}
              disabled={isDisabledField('vat')}
              label={PAYMENT_INFORMATION_FORM_VALUES.VAT.LABEL}
              isRequired={formValuesSetting.vat.required[role]}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.BANK_NAME.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.bankName)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={
                PAYMENT_INFORMATION_FORM_VALUES.BANK_NAME.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={isDisabledField('bankName')}
              label={PAYMENT_INFORMATION_FORM_VALUES.BANK_NAME.LABEL}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.IBAN.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.iban)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.IBAN.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              maxLength={35}
              onChange={onChange}
              disabled={isDisabledField('iban')}
              label={PAYMENT_INFORMATION_FORM_VALUES.IBAN.LABEL}
              isRequired={formValuesSetting.iban.required[role]}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.SWIFT.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.swift)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.SWIFT.PLACEHOLDER}
              size="large"
              error={error}
              maxLength={15}
              spaceForError="auto"
              onChange={onChange}
              disabled={isDisabledField('swift')}
              label={PAYMENT_INFORMATION_FORM_VALUES.SWIFT.LABEL}
              isRequired={formValuesSetting.swift.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.BANK_ADDRESS.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.bankAddress)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={
                PAYMENT_INFORMATION_FORM_VALUES.BANK_ADDRESS.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={isDisabledField('bankAddress')}
              label={PAYMENT_INFORMATION_FORM_VALUES.BANK_ADDRESS.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.ABA.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.aba)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.ABA.PLACEHOLDER}
              size="large"
              error={error}
              maxLength={9}
              spaceForError="auto"
              onChange={onChange}
              disabled={isDisabledField('aba')}
              label={PAYMENT_INFORMATION_FORM_VALUES.ABA.LABEL}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.EMAIL.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.accountingEmail)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.EMAIL.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={isDisabledField('accountingEmail')}
              label={PAYMENT_INFORMATION_FORM_VALUES.EMAIL.LABEL}
            />
          )}
        </FormItem>
      </div>
    </>
  )
}
