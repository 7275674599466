const statusColumnIndex = 2
const prepaidExtrasColumnIndex = 13
const supplierExtrasColumnIndex = 14
const extrasColumnWidth = '240'
const statusColumnWidth = '80'
const itemsCount = 16

export const prepareColumnsWidth = () => {
  const columns = new Array(itemsCount).fill('')

  columns[statusColumnIndex] = statusColumnWidth
  columns[prepaidExtrasColumnIndex] = extrasColumnWidth
  columns[supplierExtrasColumnIndex] = extrasColumnWidth
  return columns
}
