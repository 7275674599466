import { FILTER_KEYS, SORTING_KEYS } from 'constants/filters'
import { ISortingItemReq, IFilterItemReq } from 'types/table'
import { Filter } from './addQueryString'
import { FiltersState } from 'redux/filters/types'
import { SortingState } from 'redux/sorting/types'

const TAB_SYMBOL = '	'

export const prepareSortingForReq = (
  sortItems: ISortingItemReq[],
  filterKey: keyof SortingState['sortingData']
) =>
  sortItems
    .map((el) => `[${SORTING_KEYS[filterKey]?.[el.key]}]${el.type}`)
    .join(',')

export const prepareFiltersForReq = (
  filterItems: IFilterItemReq,
  count: number,
  filterKey: keyof FiltersState['filters']
): Filter[] =>
  count
    ? Object.entries(filterItems).map(([key, value]) => ({
        name: FILTER_KEYS[filterKey]?.[key],
        type: 'In',
        value: value.join(TAB_SYMBOL),
      }))
    : []
