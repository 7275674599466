import { profileApi } from 'api'
import { URLS } from 'constants/urls'
import { useApiRequest } from 'hooks/useApiRequest'
import { useCompanyId } from 'hooks/useCompanyId'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  setAccountStatus,
  setSectionStatuses,
  setSupplierCurrency,
} from 'redux/login/slice'
import { supplierCompanyNameSelector } from 'redux/profile/selectors'
import { setCompanyName } from 'redux/profile/slice'

export const useCompanyInfo = (isOperator: boolean) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const companyName = useSelector(supplierCompanyNameSelector)
  const params = useParams()
  const supplierCompanyId = useCompanyId()

  const companyId = supplierCompanyId || params.companyId

  const getCompanyInfoRequest = useApiRequest(
    (id) => profileApi.getCompanyShortInfo(id),
    '',
    true
  )

  const getCompanyInfo = async (id: number) => {
    try {
      const response = await getCompanyInfoRequest.apiRequest(id)
      if (response) {
        const { data } = response
        dispatch(setCompanyName(data.companyName || ''))
        dispatch(setAccountStatus(data.accountStatus))
        dispatch(setSectionStatuses(data.sectionsStatuses))
        dispatch(setSupplierCurrency(data.accountCurrency))
      }
    } catch {
      navigate(URLS.PROFILE + URLS.PROFILE_LIST)
    }
  }

  useEffect(() => {
    if (isOperator && companyId) {
      getCompanyInfo(Number(companyId))
    }
  }, [isOperator, companyId])

  return { companyName, companyId, getCompanyInfo }
}
