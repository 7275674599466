export const headItems = [
  'Country',
  'City',
  'Location',
  'Car class',
  'Created date',
  'Start date',
  'End date',
]

export const COUNTRY_INDEX = 0
export const CITY_INDEX = 1
export const LOCATION_INDEX = 2
export const CAR_CLASS_INDEX = 3
export const CREATED_DATE_INDEX = 4
