import { applicationsApi, ApplicationsApiTypes } from 'api'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { URLS } from 'constants/urls'
import { Pagination } from '@frontend/design_system'
import { useApiRequest } from 'hooks/useApiRequest'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  APPLICATION_DUPLICATE,
  APPLICATION_DUPLICATE_HEAD_ITEMS,
  APPLICATION_TITLE,
} from 'texts/applications'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { Container } from 'ui/molecules/Container'
import { Table } from 'ui/molecules/Table'
import { Typography } from 'ui/atoms/Typography'
import { APPLICATION_STATUS } from 'modules/Applications/constants'
import styles from './styles.module.scss'

export const ApplicationsDuplicate = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const { id, status } = useParams()
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [applications, setApplications] = useState<
    ApplicationsApiTypes.ApplicationListResponse['applications'] | null
  >(null)

  const navigate = useNavigate()

  const breadcrumbList = [
    { link: `${URLS.APPLICATIONS}/${status}/${id}`, label: APPLICATION_TITLE },
  ]

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const applicationListRequest = useApiRequest((pageIndex, pageSize, id) =>
    applicationsApi.getApplicationDuplicateList(pageIndex, pageSize, id)
  )

  useEffect(() => {
    const fetchFilteredCompanyList = async () => {
      const applicationListResponse = await applicationListRequest.apiRequest(
        page - 1,
        pageSize,
        id
      )

      if (applicationListResponse) {
        setApplications(applicationListResponse.data.applications)
      }
    }
    fetchFilteredCompanyList()
  }, [page, pageSize])

  const handleRedirectToApplication = (id: string) => {
    navigate(id)
  }

  const formattedBodyItems = useMemo(
    () =>
      applications?.pageItems.map((application) => ({
        items: [
          application.companyName,
          application.submission,
          <Typography
            Tag="a"
            className={styles['email-link']}
            name="Subtitle7"
            color="blue700"
            target="_blank"
            onClick={(e) => {
              e.stopPropagation()
            }}
            href={`mailto:${application.companyEmail}`}
          >
            {application.companyEmail}
          </Typography>,
          application.phoneNumber,
          application.status === APPLICATION_STATUS.WaitingList
            ? '-'
            : application.processTime,
        ],
        onClick: () => handleRedirectToApplication(String(application.Id)),
      })),
    [applications]
  )

  const formattedHeadItems = useMemo(
    () =>
      Object.values(APPLICATION_DUPLICATE_HEAD_ITEMS).map((header) => ({
        value: header,
      })),
    []
  )

  return (
    <Container
      title={APPLICATION_DUPLICATE.TITLE}
      breadcrumbList={breadcrumbList}
      withCompanyName={false}
      currentPageLabel={APPLICATION_DUPLICATE.TITLE}
      loading={applicationListRequest.loading}
    >
      <div>
        <div className={styles.list}>
          <div className={styles.table}>
            <Table
              headItems={formattedHeadItems}
              bodyItems={formattedBodyItems}
              className={styles.thead}
            />
          </div>
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            total={applications?.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </div>
    </Container>
  )
}
