import { getRequiredMessage } from 'utils/getRequiredMessage'
import { DIGITS_RULE, DIGITS_WITH_DOT_RULE, LATIN_LETTER_RULE } from './uiTexts'

export const LOCATION_DETAILS = {
  FORM: {
    CONTACT_FORM_TITLE: 'Contact information',
    WORKING_HOURS_TITLE: 'Opening hours',
    RESERVATION_TITLE: 'Reservation',
    WAITING_HOURS_TITLE: 'Waiting hours',
  },
  BUTTONS: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    SAVE_BUTTON_LABEL: 'Save',
    ADD_LOCATION_LABEL: 'Add location',
  },
}

export const WAITING_HOURS_PERIOD = 'Waiting hours period'
export const BUTTON_MAP_TITLE = 'Get coordinates'

export const LOCATION_ADDING_FORM_VALUES = {
  HIRE_POINT_NAME: {
    ID: 'hirePointName',
    PLACEHOLDER: 'Please enter a hire point name',
    LABEL: 'Hire point name',
    REQUIRED_MESSAGE: getRequiredMessage('hire point name'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  LOCATION: {
    ID: 'locationId',
    PLACEHOLDER: 'Please select a location',
    LABEL: 'Location',
    REQUIRED_MESSAGE: 'Please select a location',
    SEARCH_PLACEHOLDER: 'Search for a location',
  },
  WORKING_HOURS: {
    ID: 'workingHours',
    PLACEHOLDER: 'Please enter working hours',
    LABEL: 'Working hours',
    REQUIRED_MESSAGE:
      'Please enter opening hours, at least one pair of opening and closing times is required.',
    VALIDATION_MESSAGE: 'The closing time must be later than the opening time.',
    OVERLAP_MESSAGE: 'Working hours cannot overlap. Please edit your schedule.',
    INCOMPLETE_INFORMATION: 'Both opening and closing times must be entered.',
    INFO: 'Please enter at least one set of times for each of your working days, or two sets if there’s a break.',
  },
  DROPOFF_HOURS: {
    ID: 'dropoffHours',
    PLACEHOLDER: 'Please enter dropoff hours',
    LABEL: 'Dropoff hours',
    REQUIRED_MESSAGE: getRequiredMessage('dropoff hours'),
  },
  LATITUDE: {
    ID: 'latitude',
    PLACEHOLDER: 'Enter latitude (e.g., 56.92628)',
    LABEL: 'Latitude',
    REQUIRED_MESSAGE: getRequiredMessage('latitude'),
    VALIDATION_MESSAGE: 'Please enter the correct format (e.g., 56.92628).',
    MIN_VALUE_MESSAGE: 'Latitude cannot be 0.',
  },
  LONGITUDE: {
    ID: 'longitude',
    PLACEHOLDER: 'Enter longitude (e.g., 23.99475)',
    LABEL: 'Longitude',
    REQUIRED_MESSAGE: getRequiredMessage('longitude'),
    VALIDATION_MESSAGE: 'Please enter the correct format (e.g., 56.92628).',
    MIN_VALUE_MESSAGE: 'Longitude cannot be 0.',
  },
  CURRENCY: {
    ID: 'currencyName',
    PLACEHOLDER: 'Please select a currency',
    LABEL: 'Currency',
    REQUIRED_MESSAGE: 'Please select a currency',
    SEARCH_PLACEHOLDER: 'Search for a currency',
  },
  PHONE_NUMBER: {
    ID: 'phone',
    PLACEHOLDER: 'Please enter a phone number',
    LABEL: 'Phone number',
    REQUIRED_MESSAGE: getRequiredMessage('phone number'),
  },
  PHONE_COR: {
    ID: 'phoneCor',
  },
  COUNTRY: {
    ID: 'countryId',
    PLACEHOLDER: 'Please select a country',
    LABEL: 'Country',
    REQUIRED_MESSAGE: 'Please select a country',
    SEARCH_PLACEHOLDER: 'Search for a country',
  },
  CITY: {
    ID: 'cityId',
    PLACEHOLDER: 'Please select a city',
    LABEL: 'City',
    REQUIRED_MESSAGE: 'Please select a city',
    SEARCH_PLACEHOLDER: 'Search for a city',
  },
  APARTMENT: {
    ID: 'apartment',
    PLACEHOLDER: 'Please enter apartment',
    LABEL: 'Apartment',
  },
  STREET: {
    ID: 'street',
    PLACEHOLDER: 'Please enter a street',
    LABEL: 'Street',
    REQUIRED_MESSAGE: getRequiredMessage('street'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  STATE: {
    ID: 'region',
    PLACEHOLDER: 'Please enter a state / region',
    LABEL: 'State / Region',
    REQUIRED_MESSAGE: getRequiredMessage('state / region'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  BUILDING: {
    ID: 'building',
    PLACEHOLDER: 'Please enter a building',
    LABEL: 'Building',
    REQUIRED_MESSAGE: getRequiredMessage('building'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  WAITING_HOURS: {
    ID: 'waitingHours',
    PLACEHOLDER: '0',
    LABEL: 'Waiting hours',
    REQUIRED_MESSAGE: getRequiredMessage('waiting hours'),
    VALIDATION_MESSAGE: DIGITS_RULE,
  },
  WAITING_HOURS_FEE_TYPE: {
    ID: 'waitingHourFeeType',
    LABEL: 'Fee types',
  },
  WAITING_HOURS_RANGE: {
    ID: 'waitingHourRange',
    LABEL: 'Waiting hours period',
  },
  POSTAL_CODE: {
    ID: 'postalCode',
    PLACEHOLDER: 'Please enter a postal code',
    LABEL: 'Postal code / Postcode / ZIP code',
    REQUIRED_MESSAGE: getRequiredMessage('Postal code / Postcode / ZIP code'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  PRICE: {
    ID: 'waitingHourPrice',
    PLACEHOLDER: 'e.g. 25, 15.99',
    LABEL: 'Price',
    VALIDATION_MESSAGE: DIGITS_WITH_DOT_RULE,
  },
  EMAIL: {
    ID: 'email',
    PLACEHOLDER: 'Please enter your email',
    LABEL: 'Email',
    REQUIRED_MESSAGE: 'Please enter your email.',
    VALIDATION_MESSAGE: 'Please enter a valid email.',
    TOOLTIP:
      'Your email address is required so that we can send you booking requests.',
  },
  MINIMUM_PERIOD_DAY: {
    ID: 'minimumPeriodDay',
    PLACEHOLDER: '0',
    LABEL: 'Minimum number of rental days',
    VALIDATION_MESSAGE:
      'Minimum number of rental days must be greater or equal to 1 day.',
  },
  MAXIMUM_PERIOD_DAY: {
    ID: 'maximumPeriodDay',
    PLACEHOLDER: '0',
    LABEL: 'Maximum number of rental days',
  },
  PICKUP_OPTION: {
    ID: 'pickupOption',
    LABEL: 'Pick-up procedure',
    PLACEHOLDER: 'Please select pick-up procedure',
  },
  PICKUP_INSTRUCTIONS: {
    ID: 'pickUpInstruction',
    LABEL: 'Pick-up instructions',
    PLACEHOLDER: 'Please enter pick-up instructions',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  DROPOFF_INSTRUCTIONS: {
    ID: 'dropOffInstruction',
    LABEL: 'Drop-off instructions',
    PLACEHOLDER: 'Please enter drop-off instructions',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  START_RESERVATION_HOUR: {
    ID: 'startReservationHour',
    LABEL: 'Max. advance reservation hours for last minute bookings',
    PLACEHOLDER: 'Please enter the number of hours (e.g., 12, 24, 48 or 72)',
    REQUIRED_MESSAGE: 'Please enter start reservation hour.',
    TOOLTIP:
      'Specify the number of hours prior to last minute pickup when the car will no longer be available for booking and should not be displayed on the website.',
  },
  DRIVER_AGE_RANGE: {
    ID: 'driverAgeRange',
    REQUIRED_MESSAGE: 'Please complete all the fields.',
  },
  MIN_MAX_RENTAL_DAYS: {
    ID: 'minMaxPeriodDay',
    VALIDATION_MESSAGE:
      'The maximum number must be equal or greater than the minimum.',
  },
}

export const LIMIT_INFO =
  'The maximum number of locations that can be added for initial approval is 3.'

export const LOCATIONS_TABLE_ITEMS = [
  LOCATION_ADDING_FORM_VALUES.COUNTRY.LABEL,
  LOCATION_ADDING_FORM_VALUES.CITY.LABEL,
  LOCATION_ADDING_FORM_VALUES.LOCATION.LABEL,
  LOCATION_ADDING_FORM_VALUES.STREET.LABEL,
  LOCATION_ADDING_FORM_VALUES.BUILDING.LABEL,
  LOCATION_ADDING_FORM_VALUES.PICKUP_OPTION.LABEL,
]

export const NEXT_BUTTON_TITLE = 'Next'

export const TABLE_INFO = {
  TITLE: 'Your locations',
  SUBTITLE: 'Manage the locations you have added.',
  BUTTON_LABEL: 'Complete',
}

export const LOCATION_DELETE_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    QUESTION: 'Are you sure that you want to delete',
    BUTTON_LABEL: 'Confirm',
    TITLE: 'Delete',
  },
  SUCCESS: 'The location has been successfully deleted',
}

export const SUCCESS_LOCATION_UPDATE =
  'Your locations have been successfully saved '

export const LOCATION_WEEK_DAY = {
  START_WORK: 'Opening time',
  START_LUNCH: 'Start lunch',
  FINISH_LUNCH: 'Finish lunch',
  FINISH_WORK: 'Closing time',
}

export const LOCATION_TABS = {
  INFORMATION: 'Details',
  WORKING_HOURS: 'Opening hours',
  RESERVATION: 'Reservation',
  WAITING_HOURS: 'Waiting hours',
}
export const PICKUP_OPTIONS = {
  DEFAULT: {
    ID: 'Default',
    LABEL: 'No option selected',
  },
  DESK_IN_TERMINAL: {
    ID: 'DeskInTerminal',
    LABEL: 'Desk in terminal',
  },
  SHUTTLE_TO_LOCATION: {
    ID: 'ShuttleToLocation',
    LABEL: 'Shuttle to location',
  },
  MEETING_IN_AIRPORT: {
    ID: 'MeetingInAirport',
    LABEL: 'Meeting in airport',
  },
}

export const WAITING_HOURS_RANGE = {
  BOTH: {
    ID: 'Both',
    LABEL: 'Both',
  },
  PICK_UP: {
    ID: 'PickUp',
    LABEL: 'Pick-up',
  },
  DROP_OFF: {
    ID: 'DropOff',
    LABEL: 'Drop-off',
  },
}

export const NO_COORDINATES =
  'Unable to get latitude and longitude. Please enter the values manually.'
