import { FormStep } from 'modules/auth/ResetPassword/types'

export const WELCOME_BLOCK = {
  HEADER: {
    SIGN_IN: {
      TITLE: 'Welcome to <mark>Marketplace</mark>',
      SUBTITLE:
        'Please enter and confirm your password to complete registration.',
    },
    FINISH_REGISTRATION: {
      TITLE: 'Hello,',
      SUBTITLE:
        'Let’s get you all set up so you can verify your personal account and begin setting up your profile',
    },
  },
  FOOTER: {
    TEXT: `© ${new Date().getFullYear()} Booking Group Corporation Ltd. `,
    MARK_TEXT: 'All rights reserved',
  },
}

export const WELCOME_BLOCK_HEADER: {
  [key in FormStep]: { TITLE: string; SUBTITLE: string }
} = {
  'recieve-code': {
    TITLE: 'Having trouble <mark>signing in</mark>?',
    SUBTITLE: 'Please provide your email to recover access to your account.',
  },
  'verify-account': {
    TITLE: 'Check your <mark>email</mark>',
    SUBTITLE: 'We’ve sent you a code so you can verify your account.',
  },
  'new-password': {
    TITLE: '<mark>Recover access</mark> to your account',
    SUBTITLE: 'To reset your password, please enter and confirm a new one.',
  },
}

export const sliderItems = [
  {
    title: 'Marketplace gives you',
    items: [
      '24/7 online access to all your data',
      'Dedicated account manager',
      'Self-serve flexibility',
    ],
  },
  {
    title: 'What you can do',
    items: [
      'Update key information',
      'Manage your locations and fleet',
      'Set prices and add stop sales',
    ],
  },
  {
    title: 'Benefits of cooperation with us',
    items: ['Boost bookings', 'Grow your audience', 'Increase brand awareness'],
  },
]
