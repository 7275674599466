import { Typography } from '@frontend/design_system'
import { Props } from './types'
import styles from './styles.module.scss'

export const Header = ({ title, subtitle }: Props) => (
  <>
    <Typography
      color="whiteMaster"
      markColor="yellow400Master"
      Tag="h3"
      name="H4"
      className={styles.title}
      html={title}
      dataTestId="header-title"
    />
    {subtitle && (
      <Typography
        color="whiteMaster"
        name="body2WMedium"
        Tag="p"
        className={styles.subtitle}
        dataTestId="header-subtitle"
      >
        {subtitle}
      </Typography>
    )}
  </>
)
