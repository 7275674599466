import { fleetApi, FleetApiTypes } from 'api'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { VEHICLE_ADD_NOTIFICATION } from 'texts/vehicleConfirmModal'
import { VEHICLE_ADDING_BREADCRUMB, VEHICLE_TITLE } from 'texts/vehicleDetails'
import { Container } from 'ui/molecules/Container'
import { VehicleDetailsForm } from '../VehicleDetailsForm'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useNavigate } from 'react-router-dom'
import { useApiRequest } from 'hooks/useApiRequest'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import { ApproveBtn } from 'ui/components/ApproveBtn'
import { useEffect } from 'react'
import { ACCOUNT_STATUSES, REGISTRATION_STATUSES } from 'api/auth/constants'
import { URLS } from 'constants/urls'
import { VEHICLES } from 'texts/vehicles'
import { useInitVehicleData } from '../../hooks/useInitVehicleData'
import { useCompanyId } from 'hooks/useCompanyId'

export const VehicleAdd = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isOperator = useAppSelector(isOperatorSelector)
  const { accountStatus, sectionsStatuses } = useAppSelector(userDataSelector)
  const isAccountApproved =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED
  const isAccountPending = accountStatus === ACCOUNT_STATUSES.PENDING

  const { apiRequest, loading } = useApiRequest(
    (companyId, data) => fleetApi.addVehicle(Number(companyId), data),
    undefined,
    true,
    undefined,
    false
  )

  const companyId = useCompanyId()

  const { vehicleDetailsData, isLoaded } = useInitVehicleData({
    supplierId: String(companyId),
    isAccountPending,
  })

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'vehicles'
  )

  useEffect(() => {
    if (
      !isOperator &&
      sectionsStatuses?.carsStatus === REGISTRATION_STATUSES.PENDING
    ) {
      navigate(`${URLS.FLEET}${URLS.VEHICLES}`)
    }
  })

  const handleSubmit = async (data: FleetApiTypes.AddVehicleRequest) => {
    const response = await apiRequest(Number(companyId), data)
    if (response) {
      dispatch(
        setNotificationMessage({
          notificationMessage: VEHICLE_ADD_NOTIFICATION.TITLE,
        })
      )
      GTM.dataLayer({
        event: DATA_LAYER_EVENT.CAR_ADD,
        car_type: response.data.vehicleInfo.carCategoryName,
        car_sipp: response.data.vehicleInfo.sippCode,
      })
      if (isAccountApproved) {
        const path = isOperator
          ? `${URLS.FLEET}${URLS.VEHICLES}/${companyId}`
          : `${URLS.FLEET}${URLS.VEHICLES}`
        navigate(path)
      } else {
        navigate(0)
      }
    }
  }

  return (
    <Container
      breadcrumbList={breadcrumbList}
      currentPageLabel={VEHICLE_ADDING_BREADCRUMB}
      title={VEHICLE_TITLE}
      withNavigation={isOperator}
      tooltip={
        accountStatus !== ACCOUNT_STATUSES.APPROVED
          ? VEHICLES.TOOLTIP_CONTENT
          : undefined
      }
      rightBlock={(id: string) => <ApproveBtn companyId={id} />}
      loading={!isLoaded}
    >
      <VehicleDetailsForm
        handleSubmit={handleSubmit}
        isLoading={loading}
        vehicleDetailsData={vehicleDetailsData}
      />
    </Container>
  )
}
