import { ReactNode, useEffect, useState } from 'react'
import { Typography } from 'ui/atoms/Typography'
import { ModalCloseIcon } from 'ui/icons'
import { joinClasses } from 'utils/joinClasses'
import { useSearchParams } from 'react-router-dom'
import { SP_TIPS } from 'constants/searchParams'
import styles from './styles.module.scss'

interface IPopupProps {
  className?: string
  children: (onToggle: () => void) => ReactNode
  title: string
  icon: ReactNode
  indicator?: ReactNode
}

export const Popup = ({
  className = '',
  children,
  title,
  icon,
  indicator,
}: IPopupProps) => {
  const [isTipsOpen, setTipsOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const onToggle = () => setTipsOpen(!isTipsOpen)
  const handleCloseTips = () => setTipsOpen(false)

  const tipsSearchParam = searchParams.get(SP_TIPS)

  useEffect(() => {
    if (tipsSearchParam) {
      setTipsOpen(true)
      searchParams.delete(SP_TIPS)
      setSearchParams(searchParams)
    }
  }, [tipsSearchParam])

  return (
    <>
      <div
        className={joinClasses(
          styles.btn,
          [styles.active, isTipsOpen],
          className
        )}
        onClick={onToggle}
      >
        {icon}
        {indicator}
      </div>
      {isTipsOpen && (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Typography name="H5">{title}</Typography>
            <ModalCloseIcon
              size="large"
              className={styles['close-icon']}
              onClick={handleCloseTips}
            />
          </div>
          {children(onToggle)}
        </div>
      )}
    </>
  )
}
