export const SIGNUP_TEXT_FIELDS = {
  TITLE: 'Finish registration',
  SUBTITLE: 'Please create and confirm a password to complete registration.',
  BUTTON: 'Register',
  BACK_TO_SIGN_IN: 'Back to sign in',
}

export const CONFIRMATION_CODE_FIELDS = {
  TITLE: 'Please click Continue to register an account',
  SUBTITLE: 'Your account registration code is:',
  BUTTON: 'Continue',
}

export const SIGNUP_NOTIFICATION_MESSAGE = 'Registration completed successfully'
