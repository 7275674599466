import { MENU_NAMES } from 'texts/menuNames'
import { Container } from 'ui/molecules/Container'
import { Table } from 'ui/molecules/Table'
import { TABLE_HEAD_ITEMS } from './constants'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { useEffect, useMemo, useState } from 'react'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { Pagination, Typography, Modal } from '@frontend/design_system'
import { MaximizeIcon, ModalCloseIcon } from 'ui/icons'
import { useApiRequest } from 'hooks/useApiRequest'
import { bookingHistoryApi, BookingHistoryTypes } from 'api'
import { prepareColumnsWidth } from './utils'
import { useCompanyId } from 'hooks/useCompanyId'
import styles from './styles.module.scss'

export const BookingHistoryList = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [bookingHistory, setBookingHistory] = useState<
    BookingHistoryTypes.GetBookingHistoryListResponse['reservations'] | null
  >(null)

  const companyId = useCompanyId()

  const getBookingHistoryListRequest = useApiRequest((companyId: string) =>
    bookingHistoryApi.getBookingHistoryList(
      Number(pageSize),
      page - 1,
      companyId
    )
  )

  useEffect(() => {
    const fetchBookingHistory = async () => {
      const response = await getBookingHistoryListRequest.apiRequest(companyId)
      if (response) {
        setBookingHistory(response.data.reservations)
      }
    }
    fetchBookingHistory()
  }, [page, pageSize])

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const onOpenModal = () => {
    setIsFullScreen(true)
  }

  const onCloseModal = () => {
    setIsFullScreen(false)
  }

  const formattedBodyItems = useMemo(
    () =>
      bookingHistory?.pageItems.map((booking) => ({
        items: [
          booking.reservationNumber,
          booking.partnerNumber,
          booking.reservationStatus,
          booking.reservedDate,
          booking.partnerApproveDate,
          booking.pickUpLocationName,
          booking.pickUpDate,
          booking.dropOffLocationName,
          booking.dropOffDate,
          booking.duration,
          booking.netPrice,
          booking.companyCarClass,
          booking.sippCode,
          [...booking.prepaidExtras, ...booking.supplierExtras].map(
            (el, index) => (
              <Typography
                name="Subtitle7"
                className={styles.extra}
                key={index}
              >{`${el.price} ${el.currencyCode} - ${el.extraName} (x${el.quantity})`}</Typography>
            )
          ),
          booking.clientFirstName + ' ' + booking.clientLastName,
        ],
      })),
    [bookingHistory]
  )

  const renderTable = () => (
    <>
      <div className={styles.list}>
        <div className={styles.header}>
          {isFullScreen ? (
            <ModalCloseIcon
              size="medium"
              color="blue700"
              onClick={onCloseModal}
              dataTestId="close-modal-button"
            />
          ) : (
            <MaximizeIcon
              color={
                bookingHistory?.pageItems.length === 0
                  ? 'blueSecondary'
                  : 'blue700'
              }
              size="large"
              onClick={onOpenModal}
              disabled={bookingHistory?.pageItems.length === 0}
              dataTestId="open-modal-button"
            />
          )}
        </div>
        <Table
          isStickyHeader
          headItems={TABLE_HEAD_ITEMS}
          bodyItems={formattedBodyItems}
          initItemsWidth={prepareColumnsWidth()}
          dataTestId="booking-history-table"
        />
      </div>
      <footer className={styles.pagination}>
        <Pagination
          current={page}
          pageItemCount={bookingHistory?.pageItems.length}
          total={bookingHistory?.totalItems || 0}
          pageSize={pageSize}
          changePageSize={changePageSize}
          changePage={handleChangePage}
          dataTestId="booking-history-list-pagination"
          pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
        />
      </footer>
    </>
  )

  return (
    <>
      <Container
        title={MENU_NAMES.BOOKING_HISTORY}
        loading={getBookingHistoryListRequest.loading}
      >
        {renderTable()}
      </Container>
      {isFullScreen && (
        <Modal
          position="center-horizontal"
          onClose={onCloseModal}
          offsetVariant="none"
          invisibleTitle
          invisibleHeader
          invisibleClose
          headerSize="large"
          dataTestId="table-modal"
        >
          <div className={styles['full-screen-modal']}>{renderTable()}</div>
        </Modal>
      )}
    </>
  )
}
