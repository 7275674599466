import { fleetApi, FleetApiTypes } from 'api'
import { breadcrumbs } from 'constants/breadcrumbs'
import { Pagination } from '@frontend/design_system'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector } from 'redux/login/selectors'
import { MENU_NAMES } from 'texts/menuNames'
import { Button } from 'ui/atoms/Button'
import { PlusIcon } from 'ui/icons'
import { Confirmation } from 'ui/molecules/Confirmation'
import { TableTooltip } from 'ui/molecules/TableTooltip'
import { Table } from 'ui/molecules/Table'
import { Equipment } from './types'
import {
  EQUIPMENTS,
  EQUIPMENTS_HEAD_ITEMS,
  EQUIPMENT_DELETE_CONFIRMATION,
} from 'texts/equipments'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { Container } from 'ui/molecules/Container'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { useApiRequest } from 'hooks/useApiRequest'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import { useCompanyId } from 'hooks/useCompanyId'
import styles from './styles.module.scss'
import { prepareFiltersForReq, prepareSortingForReq } from 'utils/table'
import { EQUIPMENTS_FILTER_KEY } from 'constants/filters'
import { sortingSelector } from 'redux/sorting/selectors'
import {
  filterItemsSelector,
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import { useFilterSettings } from 'hooks/useFilterSettings'
import { setFilterItems } from 'redux/filters/slice'
import { prepareFilterItems } from 'utils/filters'
import { TableHeadProps } from 'ui/molecules/Table/TableHead/types'
import { EQUIPMENT_INDEX, MAX_PRICE_INDEX, PRICE_INDEX } from './constants'
import { ResetFiltersButton } from 'ui/components/ResetFiltersButton'

export const Equipments = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [equipmentsList, setEquipmentsList] =
    useState<FleetApiTypes.GetEquipmentsListResponse | null>(null)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment>()
  const isOperator = useAppSelector(isOperatorSelector)
  const sortingData = useAppSelector(sortingSelector)
  const selectedFiltersCount = useAppSelector(selectedFiltersCountSelector)
  const selectedFilters = useAppSelector(selectedFiltersSelector)
  const filterItems = useAppSelector(filterItemsSelector)

  const companyId = useCompanyId()

  const breadcrumbsList = [breadcrumbs.profileList]

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const deleteEquipmentRequest = useApiRequest((selectedEquipment) =>
    fleetApi.deleteEquipment(selectedEquipment.groupId)
  )

  const fetchEquipmentsRequest = useApiRequest((companyId) =>
    fleetApi.getEquipmetsList(
      page - 1,
      Number(pageSize),
      Number(companyId),
      prepareFiltersForReq(
        selectedFilters.equipments,
        selectedFiltersCount.equipments,
        EQUIPMENTS_FILTER_KEY
      ),
      prepareSortingForReq(sortingData.equipments, EQUIPMENTS_FILTER_KEY)
    )
  )

  const getFilterSettings = useFilterSettings(EQUIPMENTS_FILTER_KEY, setPage)

  const getEquipmentsFiltersRequest = useApiRequest((companyId) =>
    fleetApi.getEquipmentsFilters(companyId)
  )

  const fetchFilters = async () => {
    const getEquipmentsFilters = await getEquipmentsFiltersRequest.apiRequest(
      companyId
    )

    if (getEquipmentsFilters) {
      dispatch(
        setFilterItems({
          items: prepareFilterItems(getEquipmentsFilters.data),
          key: EQUIPMENTS_FILTER_KEY,
        })
      )
    }
  }

  useEffect(() => {
    !selectedFiltersCount.equipments && fetchFilters()
  }, [selectedFiltersCount.equipments])

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const fetchEquipments = async (companyId: string | number) => {
    const equipmentsListResponse = await fetchEquipmentsRequest.apiRequest(
      companyId
    )
    if (equipmentsListResponse) {
      setEquipmentsList(equipmentsListResponse.data)
    }
  }

  useEffect(() => {
    if (companyId) fetchEquipments(companyId)
  }, [page, pageSize, companyId, sortingData, selectedFiltersCount.equipments])

  const handleDeleteEquipment = (equipment: Equipment) => {
    setConfirmationModalOpen(true)
    setSelectedEquipment(equipment)
  }

  const handleConfirm = async () => {
    if (selectedEquipment && companyId) {
      const response = await deleteEquipmentRequest.apiRequest(
        selectedEquipment
      )
      if (response) {
        dispatch(
          setNotificationMessage({
            notificationMessage:
              EQUIPMENT_DELETE_CONFIRMATION.SUCCESS_MODAL.TITLE,
          })
        )
        GTM.dataLayer({
          event: DATA_LAYER_EVENT.EQUIPMENT_REMOVED,
          equipment_name: selectedEquipment.name,
          value: selectedEquipment.count,
        })
        fetchEquipments(companyId)
        setConfirmationModalOpen(false)
      }
    }
  }

  const handleModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const handleAddEquipment = () => navigate('add')

  const handleRowClick = (id: string) =>
    navigate(`../equipments/${companyId}/${id}/edit`)

  const formattedHeadItems = useMemo(() => {
    const newHeadItems: TableHeadProps['item'][] = Object.values(
      EQUIPMENTS_HEAD_ITEMS
    ).map((header) => ({ value: header }))

    newHeadItems[EQUIPMENT_INDEX].filterSettings =
      getFilterSettings('equipment')
    newHeadItems[PRICE_INDEX].filterSettings = getFilterSettings('sum')
    newHeadItems[MAX_PRICE_INDEX].filterSettings = getFilterSettings('maxsum')

    return newHeadItems
  }, [filterItems.equipments, getFilterSettings])

  const formattedBodyItems = equipmentsList?.extras.pageItems?.map(
    (equipment) => ({
      items: [
        equipment.name,
        equipment.sum,
        equipment.maxSum ?? '-',
        <TableTooltip
          title={equipment.refersToLocation}
          onClick={() => handleRowClick(equipment.groupId)}
        />,
      ],
      onClick: () => handleRowClick(equipment.groupId),
      removeRowClick: () => handleDeleteEquipment(equipment),
    })
  )

  return (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: `${EQUIPMENT_DELETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${selectedEquipment?.name}?`,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: `${EQUIPMENT_DELETE_CONFIRMATION.CONFIRMATION_MODAL.DELETE} ${selectedEquipment?.name}?`,
          }}
        />
      )}
      <Container
        breadcrumbList={isOperator ? breadcrumbsList : []}
        title={MENU_NAMES.EQUIPMENTS}
        currentPageLabel={MENU_NAMES.EQUIPMENTS}
        tooltip={EQUIPMENTS.TOOLTIP_CONTENT}
        withNavigation={isOperator}
        loading={
          fetchEquipmentsRequest.loading && !fetchEquipmentsRequest.isLoaded
        }
      >
        <div className={styles.list}>
          <div className={styles.header}>
            <ResetFiltersButton
              selectedFiltersCount={selectedFiltersCount.equipments}
            />
            <div className={styles.button}>
              <Button
                size="small"
                typographyName="Button2"
                variant="outline"
                label={EQUIPMENTS.ADD_BUTTON}
                onClick={handleAddEquipment}
                iconPosition="right"
                icon={<PlusIcon color="blue700" />}
              />
            </div>
          </div>
          {formattedBodyItems && (
            <div className={styles.table}>
              <Table
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
              />
            </div>
          )}
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            pageItemCount={equipmentsList?.extras.pageItems.length}
            total={equipmentsList?.extras.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </Container>
    </>
  )
}
