import formReducer from '@frontend/design_system/src/redux/forms/forms.reducer'
import { activityHistoryReducer } from './activityHistory/slice'
import { filtersReducer } from './filters/slice'
import { notificationReducer } from './notifications/slice'
import { locationsReducer } from './locations/slice'
import { fleetReducer } from './fleet/slice'
import { profileReducer } from './profile/slice'
import { authReducer } from './login/slice'
import { registrationReducer } from './registration/slice'
import { commonReducer } from './common/slice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { applicationsReducer } from './applications/slice'
import { sortingReducer } from './sorting/slice'

const rootReducer = combineReducers({
  registrationReducer,
  authReducer,
  profileReducer,
  fleetReducer,
  locationsReducer,
  notificationReducer,
  filtersReducer,
  activityHistoryReducer,
  commonReducer,
  applicationsReducer,
  sortingReducer,
  forms: formReducer,
})

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = ReturnType<typeof setupStore>['dispatch']

export const store = setupStore()
