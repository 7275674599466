import { useLocation } from 'react-router-dom'
import { CodeConfirmation } from 'modules/auth/Signup/CodeConfirmation/CodeConfirmation'
import { SignUpForm } from 'modules/auth/Signup/SignupForm'
import { WelcomeBlockView } from 'modules/auth/WelcomeBlockView'
import { Header } from 'modules/auth/WelcomeBlockView/components/Header'
import { useAppSelector } from 'redux/hooks'
import {
  companyNameSelector,
  isRegistrationCodeValidSelector,
} from 'redux/registration/selectors'
import { WELCOME_BLOCK } from 'texts/welcomeBlock'
import { AuthLayout } from 'layouts/AuthLayout/AuthLayout'
import { useMemo } from 'react'

export const SignUpPage = () => {
  const isRegistrationCodeValid = useAppSelector(
    isRegistrationCodeValidSelector
  )

  const companyName = useAppSelector(companyNameSelector)
  const location = useLocation()

  const getInitialCode = () => location.search.split('=')[1]?.split('')

  const headerProps = useMemo(
    () => ({
      title: isRegistrationCodeValid
        ? `${WELCOME_BLOCK.HEADER.FINISH_REGISTRATION.TITLE} <mark>${companyName}</mark>`
        : `${WELCOME_BLOCK.HEADER.SIGN_IN.TITLE}`,
    }),
    [isRegistrationCodeValid]
  )

  const Form = () =>
    isRegistrationCodeValid ? (
      <SignUpForm />
    ) : (
      <CodeConfirmation initialCode={getInitialCode()} />
    )

  return (
    <AuthLayout
      WelcomeBlock={
        <WelcomeBlockView Header={() => <Header {...headerProps} />} />
      }
      Form={<Form />}
    />
  )
}
