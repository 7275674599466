import { createSlice } from '@reduxjs/toolkit'
import { LocationsState } from './types'

const initialState: LocationsState = {
  errorMessage: '',
  countries: [],
  cities: [],
  locations: [],
  locationType: '',
  mapData: null,
  markerPosition: {
    lat: 0,
    lng: 0,
  },
}

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocationsErrorMessage(state, action) {
      state.errorMessage = action.payload
    },
    setLocationsData(state, action) {
      state.locations = action.payload
    },
    setCountriesData(state, action) {
      state.countries = action.payload
    },
    setCitiesData(state, action) {
      state.cities = action.payload
    },
    setMapData(state, action) {
      state.mapData = action.payload
    },
    setMarkerPosition(state, action) {
      state.markerPosition = action.payload
        ? { ...state.markerPosition, ...action.payload }
        : null
    },
    setLocationType(state, action) {
      state.locationType = action.payload
    },
  },
})

export const locationsReducer = locationsSlice.reducer
export const {
  setLocationsErrorMessage,
  setLocationsData,
  setCountriesData,
  setCitiesData,
  setMapData,
  setMarkerPosition,
  setLocationType,
} = locationsSlice.actions
