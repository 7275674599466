import { profileApi, authApi, ProfileApiTypes } from 'api'
import { ErrorResponse } from 'api/types'
import { URLS } from 'constants/urls'
import { Confirmation } from 'ui/molecules/Confirmation'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PROFILE_ARCHIVE } from 'texts/profileArchive'
import { SEND_CODE, SEND_CODE_CONFIRMATION } from 'texts/profileSendCode'
import { PROFILE_DETAILS, PROFILE_DETAILS_TITLE } from 'texts/profileDetails'
import { PROFILE_LIST, PROFILE_LIST_CONFIRMATION } from 'texts/profileList'
import { REGISTRATION_STATUSES, ACCOUNT_STATUSES } from 'api/auth/constants'
import { Button } from 'ui/atoms/Button'
import { Container } from 'ui/molecules/Container'
import { ArchiveIcon, StreamlineIcon } from 'ui/icons'
import { breadcrumbs } from 'constants/breadcrumbs'
import { setNotificationMessage } from 'redux/notifications/slice'
import { ProfileDetailsFormWrapper } from '../ProfileDetailsFormWrapper'
import { useAppSelector } from 'redux/hooks'
import {
  isOperatorSelector,
  userDataSelector,
  supplierCompanyIdSelector,
} from 'redux/login/selectors'
import { ProfileFormValues } from '../../../../../ui/components/ProfileDetailsForm/types'
import { Typography } from 'ui/atoms/Typography'
import countryOfResidence from 'constants/countryOfResidence'
import { initialFormValues } from '../../../../../ui/components/ProfileDetailsForm/constants'
import { useApiRequest } from 'hooks/useApiRequest'
import { scrollTop } from 'utils/scrollTop'
import { deactivateProfileSubtitle } from 'texts/profileConfirmModal'
import { setAccountStatus, setPageStatus } from 'redux/login/slice'
import styles from './styles.module.scss'
import { ApproveBtn } from 'ui/components/ApproveBtn'
import { getErrorMessage } from 'utils/getErrorMessage'
import { errorCodes } from 'constants/errorCodes'
import { resetSelectedFilters } from 'redux/filters/slice'

export const ProfileEdit = () => {
  const [initialData, setInitialData] = useState<Partial<ProfileFormValues>>({})
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isSendCodeModalOpen, setIsSendCodeModalOpen] = useState(false)
  const supplierCompanyId = useSelector(supplierCompanyIdSelector)
  const [companyName, setCompanyName] = useState('')
  const [showSendCode, setShowSendCode] = useState(false)
  const { accountStatus, sectionsStatuses } = useAppSelector(userDataSelector)
  const isOperator = useAppSelector(isOperatorSelector)
  const isUserRegistered =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED
  const isShowPendingForApproval =
    !isUserRegistered &&
    sectionsStatuses?.profileStatus === REGISTRATION_STATUSES.PENDING

  const params = useParams()

  const breadcrumbList = [breadcrumbs.profileList]

  const companyId = params.companyId || supplierCompanyId
  const { apiRequest, loading } = useApiRequest(
    (data) =>
      profileApi.updateCompany(Number(companyId), {
        ...data,
        logo: data.logo?.[0],
      }),
    undefined,
    true,
    undefined,
    false
  )

  const dispatch = useDispatch()

  const { apiRequest: getCompanyInfo, loading: getCompanyInfoLoading } =
    useApiRequest(
      (companyId) => profileApi.getCompanyInfo(companyId),
      undefined,
      undefined,
      {
        isThrowError: true,
        withoutNotification: true,
        onError: (error) =>
          dispatch(
            setNotificationMessage({
              notificationMessage:
                getErrorMessage(
                  (error as ErrorResponse).responseStatus?.errorCode
                ) || errorCodes.Default,
              type: 'error',
            })
          ),
      }
    )

  const completeProfileRequest = useApiRequest((companyId) =>
    profileApi.completeProfile(companyId)
  )

  const closeProfileRequest = useApiRequest((companyId) =>
    profileApi.closeCompany(Number(companyId))
  )

  const navigate = useNavigate()

  const handleSendCode = async () => {
    try {
      await authApi.sendRegistrationCode(Number(companyId))
      dispatch(
        setNotificationMessage({
          notificationMessage:
            SEND_CODE_CONFIRMATION.CONFIRMATION_MODAL.SUCCESS_MESSAGE,
        })
      )
    } catch (e) {
      dispatch(
        setNotificationMessage({
          notificationMessage: (e as ErrorResponse).responseStatus.message,
          type: 'error',
        })
      )
    }
    setIsSendCodeModalOpen(false)
  }

  const handleArchiveCompany = () => {
    setIsConfirmationModalOpen(true)
  }

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleSendModalOpen = () => {
    setIsSendCodeModalOpen(true)
  }

  const handleSendModalClose = () => {
    setIsSendCodeModalOpen(false)
  }

  const handleConfirm = async () => {
    await closeProfileRequest.apiRequest(companyId)
    dispatch(resetSelectedFilters())
    return true
  }

  const handleRedirectToArchive = () => {
    navigate(`..${URLS.ARCHIVE}`)
  }

  const setInitialValues = (
    companyInfo: ProfileApiTypes.GetCompanyInfoResponse['companyInfo']
  ) => {
    setInitialData({
      ...initialFormValues,
      ...companyInfo,
      companyName: isOperator
        ? companyInfo.companyName
        : companyInfo.companyName?.split('#')[0],
      phoneCor: countryOfResidence.find(
        ({ code }) => code === companyInfo.phoneCountryCode
      ),
      companyCountryID: companyInfo.companyCountryID
        ? String(companyInfo.companyCountryID)
        : '',
      companyCurrencyID: String(companyInfo.companyCurrencyID),
      logo: companyInfo.logo
        ? [
            {
              ...new File(['file'], 'logo'),
              url: companyInfo.logo,
            },
          ]
        : [],
    })
  }

  useEffect(() => {
    if (isShowPendingForApproval) {
      navigate(`..${URLS.PENDING}`)
    }
    const getInfo = async () => {
      if (companyId) {
        const response = await getCompanyInfo(Number(companyId))

        if (!response) {
          return null
        }

        const { companyInfo } = response.data

        setCompanyName(
          (isOperator
            ? companyInfo.companyName
            : companyInfo.companyName?.split('#')[0]) || ''
        )
        if (companyInfo.accountStatus === 'Unconfirmed') {
          setShowSendCode(true)
        }

        dispatch(setAccountStatus(companyInfo.accountStatus))
        setInitialValues(companyInfo)
      }
    }
    getInfo()
  }, [companyId, isShowPendingForApproval])

  const handleCompleteConfirm = async () => {
    if (companyId) {
      const response = await completeProfileRequest.apiRequest(companyId)
      if (response) {
        dispatch(
          setNotificationMessage({
            notificationMessage: PROFILE_DETAILS.SUCCESS_UPDATE_MESSAGE,
          })
        )
        dispatch(
          setPageStatus({ profileStatus: REGISTRATION_STATUSES.PENDING })
        )
      }
    }
  }

  const handleSubmit = async (data: Partial<ProfileFormValues>) => {
    delete data.phoneCor
    const response = await apiRequest(data)
    if (response) {
      setCompanyName(response.data.companyInfo.companyName || '')
      if (isUserRegistered) {
        setInitialValues(response.data.companyInfo)
        dispatch(
          setNotificationMessage({
            notificationMessage: PROFILE_DETAILS.SUCCESS_UPDATE_MESSAGE,
          })
        )
      } else {
        handleCompleteConfirm()
      }
      scrollTop()
    }
  }

  const handleSuccessModalClose = () =>
    navigate(`${URLS.PROFILE}${URLS.PROFILE_LIST}`)

  const header = (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: `${PROFILE_LIST_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${initialData?.companyName}?`,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: `${PROFILE_LIST.ARCHIVE} ${companyName}`,
          }}
          successModalProps={{
            onClose: handleSuccessModalClose,
            title: PROFILE_LIST_CONFIRMATION.SUCCESS_MODAL.TITLE,
            buttonLabel: PROFILE_LIST_CONFIRMATION.SUCCESS_MODAL.BUTTON_LABEL,
            onConfirm: handleRedirectToArchive,
            subTitle: deactivateProfileSubtitle(initialData?.companyName),
          }}
        />
      )}
      {isSendCodeModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: (
              <>
                {SEND_CODE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION}
                <Typography name="Button1" color="grey900">
                  {initialData?.email}
                </Typography>
                ?
              </>
            ),
            onConfirm: handleSendCode,
            onClose: handleSendModalClose,
            title: `${SEND_CODE_CONFIRMATION.CONFIRMATION_MODAL.TITLE}`,
            confirmLabel: SEND_CODE.SEND,
          }}
        />
      )}
      <Typography Tag="h5" name="H5">
        {PROFILE_DETAILS_TITLE}
      </Typography>
    </>
  )

  const containerProps = isOperator
    ? {
        breadcrumbList: breadcrumbList,
        currentPageLabel: PROFILE_DETAILS_TITLE,
        title: header,
        companyName: companyName,
        withNavigation: true,
        loading: getCompanyInfoLoading,
        rightBlock: (id: string) => (
          <div className={styles['right-block']}>
            {showSendCode && (
              <Button
                label={SEND_CODE.BUTTON}
                size="large"
                typographyName="Button2"
                variant="link"
                icon={<StreamlineIcon color="blue700" />}
                iconPosition="right"
                onClick={handleSendModalOpen}
              />
            )}
            <ApproveBtn companyId={id} />
            <Button
              label={PROFILE_ARCHIVE.ADD}
              size="large"
              typographyName="Button2"
              variant="link"
              icon={<ArchiveIcon color="blue700" />}
              iconPosition="right"
              onClick={handleArchiveCompany}
            />
          </div>
        ),
      }
    : {
        breadcrumbList: [],
        title: PROFILE_DETAILS_TITLE,
        tooltip: PROFILE_LIST.TOOLTIP_CONTENT,
        loading: getCompanyInfoLoading,
      }

  return (
    <Container {...containerProps}>
      <ProfileDetailsFormWrapper
        initialValues={initialData}
        handleSubmit={handleSubmit}
        isUserApproved={isUserRegistered}
        withActionButtons={isUserRegistered}
        readOnly={false}
        isLoading={loading}
      />
    </Container>
  )
}
