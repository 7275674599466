import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useNavigate } from 'react-router-dom'
import { authApi, profileApi } from 'api'
import { ErrorResponse } from 'api/types'
import { PROFILE_ADD_TITLE } from 'texts/profileDetails'
import { Typography } from 'ui/atoms/Typography'
import { breadcrumbs } from 'constants/breadcrumbs'
import { CONFIRM_INVITE_MODAL } from 'texts/profileConfirmModal'
import { Container } from 'ui/molecules/Container'
import { initialFormValues } from '../../../../../ui/components/ProfileDetailsForm/constants'
import { ProfileDetailsFormWrapper } from '../ProfileDetailsFormWrapper'
import { ProfileFormValues } from '../../../../../ui/components/ProfileDetailsForm/types'
import { setNotificationMessage } from 'redux/notifications/slice'
import { URLS } from 'constants/urls'
import { useApiRequest } from 'hooks/useApiRequest'
import { ACCOUNT_STATUSES } from 'api/auth/constants'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { resetSelectedFilters } from 'redux/filters/slice'

export const ProfileAdd = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isOperator = useAppSelector(isOperatorSelector)
  const { accountStatus } = useAppSelector(userDataSelector)
  const isUserRegistered =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED

  const { apiRequest, loading } = useApiRequest(
    (data) =>
      profileApi.createNewCompany({
        ...data,
        logo: data.logo?.[0],
      }),
    undefined,
    true,
    undefined,
    false
  )

  const breadcrumbList = [breadcrumbs.profileList]

  const handleSubmit = async (data: Partial<ProfileFormValues>) => {
    try {
      delete data.phoneCor
      const response = await apiRequest(data)
      if (response?.data.companyInfo.companyID) {
        await authApi.sendRegistrationCode(response.data.companyInfo.companyID)
        dispatch(resetSelectedFilters())
        navigate(URLS.PROFILE + URLS.PROFILE_LIST)
        dispatch(
          setNotificationMessage({
            notificationMessage: CONFIRM_INVITE_MODAL.SUCCESS_NOTIFICATION,
          })
        )
      }
    } catch (e) {
      dispatch(
        setNotificationMessage({
          notificationMessage: (e as ErrorResponse).responseStatus.message,
          type: 'error',
        })
      )
    }
  }

  const header = (
    <Typography Tag="h5" name="H5">
      {PROFILE_ADD_TITLE}
    </Typography>
  )
  return (
    <Container
      breadcrumbList={breadcrumbList}
      currentPageLabel={PROFILE_ADD_TITLE}
      title={header}
      withCompanyName={false}
    >
      <ProfileDetailsFormWrapper
        withActionButtons={true}
        initialValues={initialFormValues}
        handleSubmit={handleSubmit}
        isUserApproved={isUserRegistered}
        isLoading={loading}
        isProfileAddForm
      />
    </Container>
  )
}
