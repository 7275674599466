export const headItems = [
  'Company ID',
  'Company name',
  'Country',
  'Status',
  'Actions',
]

export const COMPANY_ID_INDEX = 0
export const COMPANY_NAME_INDEX = 1
export const COUNTRY_INDEX = 2
export const STATUS_INDEX = 3
