export const SUCCESS_UPDATE_MESSAGE =
  'Your changes have been successfully saved'
export const ALL = 'All'
export const SELECT_ALL = 'Select all'
export const SEARCH_FOR = 'Search for'
export const SELECT = 'Select'
export const ENTER = 'Enter'
export const ASCENDING = 'Asc'
export const DESCENDING = 'Desc'
export const ADD = 'Add'
export const NEXT = 'Next'
export const RESET_ALL = 'Reset all'

export const PAGE_NAMES = {
  ACTIVITY_HISTORY_DETAILS: 'Activity history details',
  STOP_SALE: 'Stop sale',
  PRICE_LIST: 'Price list',
  RATES: 'Rates',
}

export const COMPLETE_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    QUESTION:
      'By clicking “Complete” you agree that you will not be able to edit your details until we have verified the information you provided.',
    TITLE: 'Ready to complete?',
    BUTTON_LABEL: 'Complete',
  },
  EDIT_MODAL: {
    QUESTION:
      'Do you want to go back to adding/editing the location or complete the process? In the second case, the details of your last location will not be saved.',
    TITLE: 'You have not finished adding/editing your last location.',
    CANCEL_LABEL: 'Go back',
    BUTTON_LABEL: 'Complete',
  },
}
