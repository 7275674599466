import { useMemo } from 'react'
import {
  Form,
  FormItem,
  RadioButton,
  Typography,
} from '@frontend/design_system'
import {
  ADDED_SUCCESS,
  CROSS_BORDER_POLICY,
  CROSS_BORDER_POLICY_DESCRIPTION,
  CROSS_BORDER_POLICY_FORM_VALUES,
  UPDATED_SUCCESS,
} from 'texts/termsAndConditions'
import { COR_ITEMS } from 'constants/countryOfResidence'
import { IFormValues } from './types'
import { Block } from '../Block'
import { FormChildren, FormItemChildren } from 'types/form'
import { CorBtn } from '../CorBtn'
import { ActionButtons } from '../ActionButtons'
import { Row } from '../Row'
import { RADIO_BUTTONS } from './constants'
import styles from './styles.module.scss'
import { Countries } from './components/Countries'
import { useCountriesRules } from './hooks/useCountriesRules'
import { IContentProps } from 'modules/TermsAndConditions/types'
import { useApiRequest } from 'hooks/useApiRequest'
import { termsApi } from 'api'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { prepareCorForSelect, prepareResponseItems } from 'utils/form'
import { useCompanyId } from 'hooks/useCompanyId'

export const CrossBorderPolicy = ({
  reload,
  data,
  isHistoryExist,
}: IContentProps) => {
  const dispatch = useDispatch()
  const companyId = useCompanyId()
  const newTermConditionRequest = useApiRequest(
    (body) => termsApi.newTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const updateTermConditionRequest = useApiRequest(
    (body) => termsApi.updateTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const countriesRules = useCountriesRules()

  const initValues = useMemo(() => {
    const initData: IFormValues = {
      cor: !isHistoryExist ? COR_ITEMS : [],

      crossBorderPolicy: CROSS_BORDER_POLICY_FORM_VALUES.ALLOWED_TO.ID,
      countries: [
        {
          id: Date.now(),
          countryList: COR_ITEMS,
          price: '',
        },
      ],
    }
    if (data) {
      initData.cor = prepareCorForSelect(data.countriesOfResidence)
      initData.crossBorderPolicy =
        data.crossBorderPolicy || initData.crossBorderPolicy
      initData.countries =
        data.details?.map(({ countries, crossBorderFee }, id) => ({
          id,
          countryList: prepareCorForSelect(countries),
          price: String(crossBorderFee || ''),
        })) || initData.countries
    }
    return initData
  }, [data])

  const onSubmit = async (values: IFormValues, validate: boolean) => {
    if (validate) {
      const details = values.countries.map(({ countryList, price }) => ({
        countries: prepareResponseItems(countryList),
        crossBorderFee: +price || undefined,
      }))
      const body = {
        termConditionTypeId: TERM_CONDITIONS_TYPES.CrossBorderPolicy,
        companyId,
        countriesOfResidence: prepareResponseItems(values.cor),
        crossBorderPolicy: values.crossBorderPolicy,
        details,
      }

      if (data) {
        await updateTermConditionRequest.apiRequest({
          ...body,
          termConditionSettingId: data.id,
        })
        dispatch(
          setNotificationMessage({
            notificationMessage: UPDATED_SUCCESS,
          })
        )
      } else {
        await newTermConditionRequest.apiRequest(body)
        dispatch(
          setNotificationMessage({
            notificationMessage: ADDED_SUCCESS,
          })
        )
      }

      reload()
    }
  }

  return (
    <Block title={CROSS_BORDER_POLICY}>
      <Form initValues={initValues} onSubmit={onSubmit} key={data?.id}>
        {({ isDirty, values }: FormChildren) => {
          const isAllowedTo =
            values[CROSS_BORDER_POLICY_FORM_VALUES.ALLOWED.ID] ===
            CROSS_BORDER_POLICY_FORM_VALUES.ALLOWED_TO.ID
          return (
            <>
              <CorBtn />
              <FormItem id={CROSS_BORDER_POLICY_FORM_VALUES.ALLOWED.ID}>
                {({ value, onChange }: FormItemChildren) => (
                  <Row>
                    {RADIO_BUTTONS.map(({ label, id }) => (
                      <RadioButton
                        labelTypographyName="body2WMedium"
                        key={id}
                        id={id}
                        label={label}
                        onChange={onChange}
                        checked={id === value}
                      />
                    ))}
                  </Row>
                )}
              </FormItem>
              {isAllowedTo && (
                <>
                  <Typography
                    name="body2WMedium"
                    Tag="p"
                    className={styles.description}
                  >
                    {CROSS_BORDER_POLICY_DESCRIPTION}
                  </Typography>
                  <FormItem
                    id={CROSS_BORDER_POLICY_FORM_VALUES.COUNTRIES.ID}
                    rules={countriesRules}
                    isCleanUpOnUnmount={false}
                  >
                    {({ value, onChange, error, onBlur }: FormItemChildren) => (
                      <Countries
                        values={value}
                        onChange={onChange}
                        error={error}
                        onBlur={onBlur}
                      />
                    )}
                  </FormItem>
                </>
              )}

              <ActionButtons
                isOffset={!isAllowedTo}
                disabled={data && !isDirty}
                onCancel={data && reload}
                loading={
                  newTermConditionRequest.loading ||
                  updateTermConditionRequest.loading
                }
              />
            </>
          )
        }}
      </Form>
    </Block>
  )
}
