import { EmptyResponse, ErrorResponse } from 'api/types'
import { addQueryString, Filter } from 'utils/addQueryString'
import { request } from './../utils'
import { PATH } from './constants'
import {
  AvailableEquipmentListResponse,
  AvailableVehicleListResponse,
  GetHirePointDataResponse,
  GetHirePointsResponse,
  GetHirePointListDataResponse,
  HirePointData,
  LocationsFilters,
} from './types'
import { EnvironmentVariablesService } from 'utils/env'

class Api {
  public baseUrl = EnvironmentVariablesService.getEnv('REACT_APP_API_URL') || ''

  async getHirePointsList(
    pageIndex: number,
    pageSize: number,
    companyId: number,
    filters?: Filter[],
    sorting?: string
  ) {
    return request<GetHirePointsResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(
        PATH.HIRE_POINTS_LIST,
        {
          companyId,
          pageSize,
          pageIndex,
        },
        filters,
        sorting
      ),
      { method: 'GET' }
    )
  }

  async changeHirePointState(hirePointId: number, active: boolean) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `hirepoint/${hirePointId}/state`,
      { method: 'POST' },
      { active }
    )
  }

  async addHirePoint(hirePointData: HirePointData) {
    return request<{ hirePointInfo: HirePointData }, ErrorResponse>(
      this.baseUrl,
      PATH.HIRE_POINTS_ADD,
      { method: 'POST' },
      hirePointData
    )
  }

  async updateHirePoint(hirePointId: number, hirePointData: HirePointData) {
    return request<HirePointData, ErrorResponse>(
      this.baseUrl,
      `hirepoint/${hirePointId}`,
      { method: 'PUT' },
      hirePointData
    )
  }

  async getHirePointInfo(hirePointId: number) {
    return request<GetHirePointDataResponse, ErrorResponse>(
      this.baseUrl,
      `hirepoint/${hirePointId}`,
      { method: 'GET' }
    )
  }

  async deleteHirePoint(hirePointId: number) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `hirepoint/${hirePointId}`,
      { method: 'DELETE' }
    )
  }

  async hirePointComplete(companyId: string) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      'hirepoint/complete',
      { method: 'POST' },
      { companyId }
    )
  }

  async getHireBasicList({ companyId = '' }: { companyId: string }) {
    return request<GetHirePointListDataResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString('/hirepoint/basicList', { companyId }),
      { method: 'GET' }
    )
  }

  async getAvailableVehicleList(
    companyId: number,
    hirePointId: number,
    pageSize: number,
    pageIndex: number
  ) {
    return request<AvailableVehicleListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.AVAILABLE_VEHICLE_LIST, {
        pageIndex,
        pageSize,
        companyId,
        hirePointId,
      }),
      { method: 'GET' }
    )
  }

  async getAvailableEquipmentList(
    companyId: number,
    hirePointId: number,
    pageSize: number,
    pageIndex: number
  ) {
    return request<AvailableEquipmentListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.AVAILABLE_EQUIPMENT_LIST, {
        pageIndex,
        pageSize,
        companyId,
        hirePointId,
      }),
      { method: 'GET' }
    )
  }

  async selectVehiclesForHirePoint(hirePointId: number, vehicleIds: string[]) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `hirepoint/${hirePointId}/selectVehicles`,
      { method: 'POST' },
      { vehicleIds }
    )
  }

  async selectEquipmentsForHirePoint(
    hirePointId: number,
    equipmentIds: number[]
  ) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `hirepoint/${hirePointId}/selectEquipments`,
      { method: 'POST' },
      { equipmentIds }
    )
  }

  async getLocationsFilters(companyId = '') {
    return request<LocationsFilters, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.LOCATIONS_FILTERS, {
        companyId,
      })
    )
  }
}

export const locationsApi = new Api()
