import { useMemo } from 'react'
import { Typography, DaysPicker } from '@frontend/design_system'
import { STOP_SALES_DATE_FORMAT } from 'constants/date'
import { format } from 'date-fns'
import { formatPerUnitData } from '../../utils'
import { DIAGRAMS, REVENUE_PER_UNIT_TABLE_ITEMS } from 'texts/dashboard'
import { EMPTY_GROUP } from '../../constants'
import { RevenuePerUnitProps } from './types'
import { InfoTable } from '../InfoTable'
import { Diagram } from '../Diagram'
import { formatNumberWithCommas } from 'utils/formatters'
import styles from './styles.module.scss'

export const RevenuePerUnit = ({
  dateRange,
  handleDateRange,
  initialDates,
  data,
  currency,
}: RevenuePerUnitProps) => {
  const { revenuePerDay, reservations, revenue, categories } =
    formatPerUnitData(data)

  const formattedBodyItems = useMemo(
    () =>
      data?.items.map((item) => ({
        items: [
          item.carType,
          `${formatNumberWithCommas(item.revenuePerReservation)} ${currency}`,
          `${formatNumberWithCommas(item.revenue)} ${currency}`,
          item.reservations,
        ],
      })),
    [data]
  )

  const formattedFooterItem = useMemo(() => {
    if (!data) {
      return []
    }
    return [
      `${formatNumberWithCommas(data.avgRevenuePerReservation)} ${currency}`,
      `${formatNumberWithCommas(data.totalRevenue)} ${currency}`,
      data.totalReservations,
    ]
  }, [data])

  return (
    <div className={styles['revenue-per-day']}>
      <div className={styles['revenue-per-day-header']}>
        <div className={styles['date-picker']}>
          <DaysPicker
            range={dateRange}
            size="small"
            initialFrom={format(
              new Date(initialDates.start),
              STOP_SALES_DATE_FORMAT
            )}
            initialTo={format(
              new Date(initialDates.end),
              STOP_SALES_DATE_FORMAT
            )}
            handleRangeSelect={handleDateRange}
            variant="secondary"
          />
        </div>
      </div>

      <div className={styles['diagram']}>
        <div className={styles['diagram-header']}>
          <Typography name="subtitleWBold">
            {DIAGRAMS.REVENUE_PER_INIT}
          </Typography>
        </div>
        <Diagram
          groupCount={EMPTY_GROUP}
          categories={categories}
          series={revenuePerDay}
        />
      </div>
      <div className={styles['diagram']}>
        <div className={styles['diagram-header']}>
          <Typography name="subtitleWBold">{DIAGRAMS.REVENUE}</Typography>
        </div>
        <Diagram
          groupCount={EMPTY_GROUP}
          categories={categories}
          series={revenue}
        />
      </div>
      <div className={styles['diagram']}>
        <div className={styles['diagram-header']}>
          <Typography name="subtitleWBold">{DIAGRAMS.RESERVATIONS}</Typography>
        </div>
        <Diagram
          groupCount={EMPTY_GROUP}
          categories={categories}
          series={reservations}
        />
      </div>
      <InfoTable
        bodyItems={formattedBodyItems}
        detailsHeadItems={REVENUE_PER_UNIT_TABLE_ITEMS}
        footerItems={formattedFooterItem}
      />
    </div>
  )
}
