import { fleetApi, FleetApiTypes } from 'api'
import { breadcrumbs } from 'constants/breadcrumbs'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector } from 'redux/login/selectors'
import { MENU_NAMES } from 'texts/menuNames'
import { DATEPICKER_DEFAULT_FORMAT } from 'constants/date'
import { formatDateFromIso } from 'utils/dateFormat'
import { STOP_SALES, STOP_SALES_ADD_CONFIRMATION } from 'texts/stopSells'
import { Button } from 'ui/atoms/Button'
import { PlusIcon } from 'ui/icons'
import { Confirmation } from 'ui/molecules/Confirmation'
import { Table } from 'ui/molecules/Table'
import { StopSale } from './types'
import {
  CAR_CLASS_INDEX,
  CITY_INDEX,
  COUNTRY_INDEX,
  CREATED_DATE_INDEX,
  headItems,
  LOCATION_INDEX,
} from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { Container } from 'ui/molecules/Container'
import { Label, Typography, Pagination } from '@frontend/design_system'
import { TableTooltip } from 'ui/molecules/TableTooltip'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import styles from './styles.module.scss'
import { URLS } from 'constants/urls'
import { useApiRequest } from 'hooks/useApiRequest'
import { isNotEmptyValueObject } from 'utils/object'
import { Filter } from 'ui/atoms/Filter'
import { useFilterSettings } from 'hooks/useFilterSettings'
import {
  filterItemsSelector,
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import {
  resetSelectedFilters,
  resetStopSalesFilters,
  setFilterItems,
} from 'redux/filters/slice'
import { STOP_SALES_FILTER_KEY } from 'constants/filters'
import { prepareFiltersForReq, prepareSortingForReq } from 'utils/table'
import { sortingSelector } from 'redux/sorting/selectors'
import { checkOnlyYearFilterSelected, prepareFilterItems } from 'utils/filters'
import { TableHeadProps } from 'ui/molecules/Table/TableHead/types'
import { ResetFiltersButton } from 'ui/components/ResetFiltersButton'
import { useCompanyId } from 'hooks/useCompanyId'

export const StopSales = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [stopSalesList, setStopSalesList] =
    useState<FleetApiTypes.GetStopSalesListResponse | null>(null)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [selectedStopSale, setSelectedStopSale] = useState<StopSale>()
  const sortingData = useSelector(sortingSelector)
  const selectedFiltersCount = useSelector(selectedFiltersCountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const filterItems = useSelector(filterItemsSelector)

  const navigate = useNavigate()

  const isOperator = useAppSelector(isOperatorSelector)

  const breadcrumbsList = [breadcrumbs.profileList]

  const companyId = useCompanyId()

  const deleteStopSaleResponse = useApiRequest((id) =>
    fleetApi.deleteStopSale(Number(id))
  )
  const fetchStopSaleFiltersResponse = useApiRequest((companyId) =>
    fleetApi.getStopSalesFilters(companyId)
  )
  const fetchFilteredStopSalesResponse = useApiRequest((companyId) =>
    fleetApi.getStopSalesList(
      page - 1,
      Number(pageSize),
      companyId,
      prepareFiltersForReq(
        selectedFilters.stopSales,
        selectedFiltersCount.stopSales,
        STOP_SALES_FILTER_KEY
      ),
      prepareSortingForReq(sortingData.stopSales, STOP_SALES_FILTER_KEY)
    )
  )

  const getFilterSettings = useFilterSettings(STOP_SALES_FILTER_KEY, setPage)

  useEffect(() => {
    dispatch(resetStopSalesFilters())
  }, [])

  const handleChange = (page: number) => {
    setPage(page)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const fetchStopSales = (companyId: number) => {
    const fetchStopSalesList = async () => {
      const stopSaleFilters = await fetchStopSaleFiltersResponse.apiRequest(
        companyId
      )
      if (stopSaleFilters) {
        const preparedFilters = prepareFilterItems(stopSaleFilters.data)
        const filterYearItems = stopSaleFilters.data.years.map((year) => ({
          value: year,
          selected: Number(year) === new Date().getFullYear(),
        }))
        dispatch(
          setFilterItems({
            items: { ...preparedFilters, years: filterYearItems },
            key: STOP_SALES_FILTER_KEY,
          })
        )
      }
    }

    const fetchFilteredStopSalesList = async () => {
      const response = await fetchFilteredStopSalesResponse.apiRequest(
        companyId
      )

      if (response) {
        setStopSalesList(response.data)
      }
    }

    isNotEmptyValueObject(selectedFilters.stopSales)
      ? fetchFilteredStopSalesList()
      : fetchStopSalesList()
  }

  useEffect(() => {
    if (companyId) fetchStopSales(Number(companyId))
  }, [page, pageSize, selectedFilters.stopSales, companyId, sortingData])

  const handleDeleteStopSale = (stopSale: StopSale) => {
    setConfirmationModalOpen(true)
    setSelectedStopSale(stopSale)
  }

  const handleAddStopSale = () => navigate('add')

  const handleConfirm = async () => {
    if (selectedStopSale) {
      const response = await deleteStopSaleResponse.apiRequest(
        selectedStopSale.id
      )
      if (response) {
        dispatch(resetSelectedFilters())
        dispatch(
          setNotificationMessage({
            notificationMessage:
              STOP_SALES_ADD_CONFIRMATION.SUCCESS_MODAL.TITLE,
          })
        )
        fetchStopSales(Number(companyId))
        setConfirmationModalOpen(false)
      }
    }
  }

  const handleModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const handleRowClick = (id: string) => {
    navigate(`${URLS.FLEET}${URLS.STOP_SALES}/${companyId}/${id}/edit`)
  }

  const formattedHeadItems = useMemo(() => {
    const newHeadItems: TableHeadProps['item'][] = headItems.map(
      (header, i) => {
        const CAR_CLASS_COLUMN_INDEX = 3
        if (i === CAR_CLASS_COLUMN_INDEX) {
          return {
            value: (
              <Label
                typographyName="Subtitle3"
                position="bottom"
                label={header}
                tooltip={
                  <Typography name="caption1WMedium">
                    {STOP_SALES.CAR_CLASS_TOOLTIP}
                  </Typography>
                }
              />
            ),
          }
        }
        return { value: header }
      }
    )

    newHeadItems[COUNTRY_INDEX].filterSettings = getFilterSettings('countries')
    newHeadItems[CITY_INDEX].filterSettings = getFilterSettings('cities')
    newHeadItems[LOCATION_INDEX].filterSettings = getFilterSettings('locations')
    newHeadItems[CAR_CLASS_INDEX].filterSettings =
      getFilterSettings('carClasses')
    newHeadItems[CREATED_DATE_INDEX].filterSettings =
      getFilterSettings('created')
    return newHeadItems
  }, [filterItems.profileList, getFilterSettings])

  const formattedBodyItems = stopSalesList?.stopSells.pageItems?.map(
    (stopSale) => ({
      items: [
        stopSale.country,
        <TableTooltip
          title={stopSale.city}
          onClick={() => handleRowClick(stopSale.id)}
        />,
        <TableTooltip
          title={stopSale.location}
          onClick={() => handleRowClick(stopSale.id)}
        />,
        stopSale.carClass,
        formatDateFromIso(stopSale.created, DATEPICKER_DEFAULT_FORMAT),
        formatDateFromIso(stopSale.from, DATEPICKER_DEFAULT_FORMAT),
        formatDateFromIso(stopSale.to, DATEPICKER_DEFAULT_FORMAT),
      ],
      onClick: () => handleRowClick(stopSale.id),
      removeRowClick: () => handleDeleteStopSale(stopSale),
    })
  )

  return (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: STOP_SALES_ADD_CONFIRMATION.CONFIRMATION_MODAL.QUESTION,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: STOP_SALES.DELETE,
          }}
        />
      )}
      <Container
        breadcrumbList={isOperator ? breadcrumbsList : []}
        currentPageLabel={MENU_NAMES.STOP_SALES}
        title={MENU_NAMES.STOP_SALES}
        tooltip={STOP_SALES.TOOLTIP}
        withNavigation={isOperator}
        loading={
          fetchFilteredStopSalesResponse.loading &&
          !fetchFilteredStopSalesResponse.isLoaded
        }
      >
        <div className={styles.list}>
          <div className={styles.header}>
            <ResetFiltersButton
              selectedFiltersCount={
                checkOnlyYearFilterSelected(selectedFilters.stopSales)
                  ? 0
                  : selectedFiltersCount.stopSales
              }
            />
            <div className={styles['button-wrapper']}>
              <Button
                size="small"
                typographyName="Button2"
                variant="outline"
                label={STOP_SALES.ADD_BUTTON}
                onClick={handleAddStopSale}
                iconPosition="right"
                icon={<PlusIcon color="blue700" />}
              />
            </div>
            <div className={styles['filter-icon']}>
              <Filter
                variant="secondary"
                items={getFilterSettings('years').items}
                onSelectAllItems={getFilterSettings('years').onSelectAllItems}
                onReset={getFilterSettings('years').onReset}
              />
            </div>
          </div>
          {formattedBodyItems && (
            <div className={styles.table}>
              <Table
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
              />
            </div>
          )}
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            pageItemCount={stopSalesList?.stopSells.pageItems.length}
            total={stopSalesList?.stopSells.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </Container>
    </>
  )
}
