const ENVIRONMENT_VARIABLES = {
  NODE_ENV: 'NODE_ENV',
  REACT_APP_GTM_KEY: 'REACT_APP_GTM_KEY',
  REACT_APP_API_URL: 'REACT_APP_API_URL',
  REACT_APP_GOOGLE_API_KEY: 'REACT_APP_GOOGLE_API_KEY',
  REACT_APP_SOCIAL_CAPTCHA_GOOGLE_ID: 'REACT_APP_SOCIAL_CAPTCHA_GOOGLE_ID',
}

class EnvironmentVariables {
  getEnv = (
    env: keyof typeof ENVIRONMENT_VARIABLES,
    required?: boolean
  ): string => {
    if (process.env[env]) {
      return process.env[env] || ''
    }

    if (required) {
      console.error(`Environment variable ${env} is required`)
      process.exit(1)
    }

    return process.env[env] || ''
  }
}

export const EnvironmentVariablesService = new EnvironmentVariables()

export const isDev =
  EnvironmentVariablesService.getEnv('NODE_ENV') === 'development'
