import { useEffect, useMemo, useState } from 'react'
import { Block } from '../Block'
import { Form } from '@frontend/design_system'
import {
  ADDED_SUCCESS,
  CAR_TYPE,
  DEPOSIT,
  EXCESS,
  EXCESS_DEPOSIT,
  UPDATED_SUCCESS,
} from 'texts/termsAndConditions'
import { COR_ITEMS } from 'constants/countryOfResidence'
import { IFormValues } from './types'
import { CorBtn } from '../CorBtn'
import { EditableCell, Table } from 'ui/molecules/Table'
import styles from './styles.module.scss'
import { ActionButtons } from '../ActionButtons'
import { FormChildren } from 'types/form'
import {
  CAR_TYPE_FOR_DEPOSIT,
  CAR_TYPE_FOR_DEPOSIT_TABLE_ITEMS,
  TERM_CONDITIONS_TYPES,
} from 'constants/termsAndConditions'
import { IContentProps } from 'modules/TermsAndConditions/types'
import { useCompanyId } from 'hooks/useCompanyId'
import { prepareCorForSelect, prepareResponseItems } from 'utils/form'
import { useApiRequest } from 'hooks/useApiRequest'
import { termsApi } from 'api'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'

const formattedHeadItems = [
  { value: CAR_TYPE },
  { value: EXCESS },
  { value: DEPOSIT },
]

export const ExcessAndDeposit = ({
  reload,
  data,
  isHistoryExist,
}: IContentProps) => {
  const dispatch = useDispatch()
  const [isDirtyTable, setIsDirtyTable] = useState(false)
  const [tableData, setTableData] = useState<{
    tableKey: number
    items: Array<{
      label: string
      carGroup: CAR_TYPE_FOR_DEPOSIT
      excess?: number
      deposit?: number
    }>
  }>({
    tableKey: 0,
    items: CAR_TYPE_FOR_DEPOSIT_TABLE_ITEMS.map(({ value, label }) => ({
      carGroup: value,
      label,
    })),
  })

  const newTermConditionRequest = useApiRequest(
    (body) => termsApi.newTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const updateTermConditionRequest = useApiRequest(
    (body) => termsApi.updateTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const initValues = useMemo(() => {
    const initData = {
      cor: !isHistoryExist ? COR_ITEMS : [],
    }

    if (data) {
      initData.cor = prepareCorForSelect(data.countriesOfResidence)
    }

    return initData
  }, [data])
  const companyId = useCompanyId()

  const onSubmit = async (values: IFormValues, validate: boolean) => {
    if (validate) {
      const body = {
        termConditionTypeId: TERM_CONDITIONS_TYPES.ExcessDeposit,
        companyId,
        countriesOfResidence: prepareResponseItems(values.cor),
        details: tableData.items.map(({ label, ...rest }) => rest),
      }

      if (data) {
        await updateTermConditionRequest.apiRequest({
          ...body,
          termConditionSettingId: data.id,
        })
        dispatch(
          setNotificationMessage({
            notificationMessage: UPDATED_SUCCESS,
          })
        )
      } else {
        await newTermConditionRequest.apiRequest(body)
        dispatch(
          setNotificationMessage({
            notificationMessage: ADDED_SUCCESS,
          })
        )
      }

      reload()
    }
  }

  const onChangeColumn = (
    carType: CAR_TYPE_FOR_DEPOSIT,
    key: 'excess' | 'deposit',
    value?: number | string
  ) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      items: prevTableData.items.map((item) => ({
        ...item,
        [key]: carType === item.carGroup ? value && +value : item[key],
      })),
    }))
  }

  const formattedBodyItems = tableData.items.map(
    ({ label, carGroup, excess, deposit }) => ({
      items: [
        label,
        <EditableCell
          handleUpdate={(value) => {
            setIsDirtyTable(true)
            onChangeColumn(carGroup, 'excess', value)
          }}
          value={excess}
        />,
        <EditableCell
          handleUpdate={(value) => {
            setIsDirtyTable(true)
            onChangeColumn(carGroup, 'deposit', value)
          }}
          value={deposit}
        />,
      ],
    })
  )

  useEffect(() => {
    if (data?.id) {
      setTableData((prevTableData) => ({
        tableKey: prevTableData.tableKey + 1,
        items: CAR_TYPE_FOR_DEPOSIT_TABLE_ITEMS.map(({ value, label }) => ({
          ...(data.details?.find((item) => item.carGroup === value) || {}),
          carGroup: value,
          label,
        })),
      }))
    }
  }, [data?.id])

  return (
    <Block title={EXCESS_DEPOSIT}>
      <Form initValues={initValues} onSubmit={onSubmit} key={data?.id}>
        {({ isDirty }: FormChildren) => (
          <>
            <CorBtn />
            <div className={styles.table}>
              <Table
                key={tableData.tableKey}
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
              />
            </div>
            <ActionButtons
              isOffset
              disabled={data && !(isDirty || isDirtyTable)}
              onCancel={data && reload}
              loading={
                newTermConditionRequest.loading ||
                updateTermConditionRequest.loading
              }
            />
          </>
        )}
      </Form>
    </Block>
  )
}
