import { useState, useEffect, useMemo, memo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { URLS } from 'constants/urls'
import {
  LOCATIONS_TABLE_ITEMS,
  TABLE_INFO,
  LOCATION_DELETE_CONFIRMATION,
  SUCCESS_LOCATION_UPDATE,
} from 'texts/locationDetails'
import { COMPLETE_CONFIRMATION } from 'texts/common'
import { REGISTRATION_STATUSES } from 'api/auth/constants'
import { useAppDispatch } from 'redux/hooks'
import { setPageStatus } from 'redux/login/slice'
import { Confirmation } from 'ui/molecules/Confirmation'
import { useApiRequest } from 'hooks/useApiRequest'
import { setNotificationMessage } from 'redux/notifications/slice'
import { Typography, OriginButton } from '@frontend/design_system'
import { LocationsApiTypes, locationsApi } from 'api'
import { LocationsTableProps } from './types'
import { Table } from 'ui/molecules/Table'
import { SEQUENCE_NUMBER } from 'texts/uiTexts'
import { useCompanyId } from 'hooks/useCompanyId'
import styles from './styles.module.scss'

export const LocationsTable = memo(({ isDirty }: LocationsTableProps) => {
  const params = useParams()
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false)
  const [isEditingModalOpen, setEditingModalOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] =
    useState<LocationsApiTypes.ShortHirePointData | null>(null)
  const [locationsList, setLocationsList] =
    useState<LocationsApiTypes.GetHirePointListDataResponse | null>(null)
  const companyId = useCompanyId()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const deleteHirePointRequest = useApiRequest((hirePointId) =>
    locationsApi.deleteHirePoint(hirePointId)
  )

  const locationsListRequest = useApiRequest((companyId) =>
    locationsApi.getHireBasicList({
      companyId: String(companyId),
    })
  )

  const locationsCompleteRequest = useApiRequest((companyId) =>
    locationsApi.hirePointComplete(String(companyId))
  )

  const getLocationsData = async (companyId: string | number) => {
    const response = await locationsListRequest.apiRequest(companyId)
    if (response && response.data) {
      setLocationsList(response.data)
    }
  }

  const handleRowClick = (id: number) => {
    navigate(
      `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/${companyId}/${id}/edit`
    )
  }

  const handleModalShow = () => {
    if (isDirty) {
      setEditingModalOpen(true)
    } else {
      setConfirmationModalOpen(true)
    }
  }

  const handleDeleteLocation = (id: number) => {
    const location = locationsList?.hirePoints.find((item) => item.id === id)
    if (location) {
      setRemoveModalOpen(true)
      setSelectedLocation(location)
    }
  }

  const handleRemoveConfirm = async () => {
    if (selectedLocation && companyId) {
      const response = await deleteHirePointRequest.apiRequest(
        selectedLocation.id
      )
      if (response) {
        dispatch(
          setNotificationMessage({
            notificationMessage: LOCATION_DELETE_CONFIRMATION.SUCCESS,
          })
        )
        getLocationsData(companyId)
        if (
          params?.id &&
          selectedLocation?.id &&
          Number(params.id) === selectedLocation.id
        ) {
          navigate(`${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/add`)
        }
      }
      setRemoveModalOpen(false)
    }
  }

  const handleModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const handleEditingModalClose = () => {
    setEditingModalOpen(false)
  }

  const handleRemoveModalClose = () => {
    setSelectedLocation(null)
    setRemoveModalOpen(false)
  }

  const handleCompeteConfirm = async () => {
    if (companyId) {
      handleModalClose()
      handleEditingModalClose()
      const response = await locationsCompleteRequest.apiRequest(companyId)
      if (response) {
        dispatch(
          setNotificationMessage({
            notificationMessage: SUCCESS_LOCATION_UPDATE,
          })
        )
        dispatch(
          setPageStatus({ locationsStatus: REGISTRATION_STATUSES.PENDING })
        )
        navigate(`${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}`)
      }
      handleRemoveModalClose()
    }
  }

  const formattedBodyItems = useMemo(() => {
    if (!locationsList) return []
    return locationsList.hirePoints.map((item, i) => {
      const sequenceNumber = i + 1
      return {
        items: [
          sequenceNumber,
          item.country,
          item.city,
          item.locationName,
          item.street,
          item.building,
          item.pickUpOption || '-',
        ],
        onClick: () => {
          handleRowClick(item.id)
        },
        removeRowClick: () => {
          handleDeleteLocation(item.id)
        },
      }
    })
  }, [locationsList])

  const formattedHeadItems = [SEQUENCE_NUMBER, ...LOCATIONS_TABLE_ITEMS].map(
    (el) => ({
      value: <Typography name="Subtitle3">{el}</Typography>,
    })
  )

  useEffect(() => {
    if (companyId) {
      getLocationsData(companyId)
    }
  }, [companyId])

  return (
    <>
      {isEditingModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: COMPLETE_CONFIRMATION.EDIT_MODAL.QUESTION,
            onConfirm: handleCompeteConfirm,
            onClose: handleEditingModalClose,
            title: COMPLETE_CONFIRMATION.EDIT_MODAL.TITLE,
            confirmLabel: COMPLETE_CONFIRMATION.EDIT_MODAL.BUTTON_LABEL,
            declineLabel: COMPLETE_CONFIRMATION.EDIT_MODAL.CANCEL_LABEL,
          }}
        />
      )}
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION,
            onConfirm: handleCompeteConfirm,
            onClose: handleModalClose,
            title: COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.TITLE,
            confirmLabel: COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.BUTTON_LABEL,
          }}
        />
      )}
      {isRemoveModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: `${LOCATION_DELETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${selectedLocation?.locationName}?`,
            onConfirm: handleRemoveConfirm,
            onClose: handleRemoveModalClose,
            title: `${LOCATION_DELETE_CONFIRMATION.CONFIRMATION_MODAL.TITLE} ${selectedLocation?.locationName}?`,
            confirmLabel:
              LOCATION_DELETE_CONFIRMATION.CONFIRMATION_MODAL.BUTTON_LABEL,
          }}
        />
      )}
      <div className={styles['location-block']}>
        <div className={styles['location-header']}>
          <div className={styles['location-title']}>
            <Typography name="H5">{TABLE_INFO.TITLE}</Typography>
            <Typography name="Button2">{TABLE_INFO.SUBTITLE}</Typography>
          </div>
          <div className={styles['location-btn']}>
            <OriginButton
              onClick={handleModalShow}
              label={TABLE_INFO.BUTTON_LABEL}
              disabled={!formattedBodyItems.length}
              variant="green"
              size="large"
            />
          </div>
        </div>
        <div className={styles['info-table']}>
          <div className={styles.table}>
            <Table
              headItems={formattedHeadItems}
              bodyItems={formattedBodyItems}
              isStickyHeader
              initItemsWidth={['53']}
            />
          </div>
        </div>
      </div>
    </>
  )
})
