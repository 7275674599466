import { useState } from 'react'
import { authApi } from 'api'
import { ADMIN, MAIN_LAYOUT } from 'texts/mainLayout'
import { Typography } from 'ui/atoms/Typography'
import { LightIcon, LogoutIcon } from 'ui/icons'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import Logo from 'assets/img/BookingLogo.svg'
import { authIsLoadedSelector, userDataSelector } from 'redux/login/selectors'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import { useNavigate } from 'react-router-dom'
import { UserRoles } from 'constants/roles'
import { useAvatar } from './hooks/useAvatar'
import { resetUserData } from 'redux/login/slice'
import { joinClasses } from 'utils/joinClasses'
import { resetShortSupplierData } from 'redux/profile/slice'
import styles from './styles.module.scss'
import { Notifications } from './components/Notifications'
import { Popup } from './components/Popup'
import { Tips } from './components/Tips'
import { resetSelectedFilters } from 'redux/filters/slice'

export const Header = () => {
  const userData = useAppSelector(userDataSelector)
  const isLoaded = useAppSelector(authIsLoadedSelector)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [isLogoutDropdownOpened, setLogoutDropdownOpened] = useState(false)
  const [userDataRef, setUserDataRef] = useState<HTMLDivElement | null>(null)

  const avatar = useAvatar()

  const isSupplier = userData.roles[0] === UserRoles.Supplier

  const handleUserDataClick = () => {
    setLogoutDropdownOpened((prevState) => !prevState)
  }

  const handleLogout = async () => {
    await authApi.logout()
    dispatch(resetUserData())
    dispatch(resetShortSupplierData())
    dispatch(resetSelectedFilters())
    navigate('/', { replace: true })
  }

  const getDisplayingRole = () => (isSupplier ? UserRoles.Supplier : ADMIN)

  const handleOutsideClick = () => {
    setLogoutDropdownOpened(false)
  }

  useOutsideClick(userDataRef, handleOutsideClick)

  if (!isLoaded) {
    return null
  }

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <img src={Logo} width="136px" height="24px" />
        <div className={styles['info-block']}>
          {isSupplier ? (
            <div className={styles.tutorial}>
              <Popup
                className={styles['tips-button']}
                icon={<LightIcon size="large" />}
                title={MAIN_LAYOUT.HEADER.TIPS}
              >
                {(onToggle) => <Tips onToggle={onToggle} />}
              </Popup>
            </div>
          ) : (
            <Notifications />
          )}

          <div
            ref={setUserDataRef}
            className={styles['user-data-wrapper']}
            onClick={handleUserDataClick}
            role="button"
          >
            <div
              className={joinClasses(
                styles['user-data'],
                [styles.active, isLogoutDropdownOpened],
                [styles['without-border'], isSupplier]
              )}
            >
              <div className={styles.info}>
                <Typography color="whiteMaster" name="Button2">
                  {userData.displayName}
                </Typography>
                <Typography color="whiteMaster" name="Subtitle4">
                  {getDisplayingRole()}
                </Typography>
              </div>
              <div className={styles['user-avatar']}>
                {avatar && (
                  <Typography
                    name="Subtitle3"
                    className={styles['user-avatar-label']}
                    color="grey500"
                  >
                    {avatar}
                  </Typography>
                )}
              </div>
            </div>
            {isLogoutDropdownOpened && (
              <div onClickCapture={handleLogout} className={styles.logout}>
                <Typography name="Button2" className={styles.label}>
                  {MAIN_LAYOUT.HEADER.LOGOUT}
                </Typography>
                <LogoutIcon size="medium" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
