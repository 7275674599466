export const RESET_PASSWORD_TEXT_FIELDS = {
  LINK: 'Back to sign in',
  NEW_PASSWORD_FORM: {
    TITLE: 'Reset password',
    BUTTON_LABEL: 'Reset password',
  },
  VERIFY_ACCOUNT_FORM: {
    TITLE: 'Verify your account',
    SUBTITLE_ADDITIONAL:
      'In order to verify your account, please enter the verification code in the field below.',
    EXPIRE_INFO: 'Verification code will expire in 20 minutes.',
    TIMER_LABLE: 'Resend code in ',
    SUBTITLE: 'We have sent you an email with a verification code to',
    BUTTON_LABELS: {
      SEND_CODE: 'Verify your account',
      RESEND: 'Resend code',
    },
  },
  RECIEVE_CODE_FORM: {
    TITLE: 'Forgot your password',
    SUBTITLE:
      'Please enter the email address you use to sign in to your Marketplace account, click "Send verification code" button below, and we will email you a verification code.',
    BUTTON_LABEL: 'Send verification code',
    LINK_LABEL: 'I already have verifiсation code',
    REACHED_MAX_ATTEMPTS_COUNT:
      'You’ve reached the maximum number of tries to get a verification code.',
    CONTACT_SUPPORT:
      'Please contact support: <a href="mailto: marketplace@bookinggroup.com">marketplace@bookinggroup.com</a>',
  },
}

export const RESET_PASSWORD_NOTIFICATION_MESSAGE =
  'Password successfully changed'

export const getAttemptsLeftMessage = (attemptsLeft: number) =>
  `You have ${attemptsLeft} attempt(s) left to receive a verification code`

export const emailNotFoundMessage = (email: string) =>
  `Company with email '${email}' not found`
