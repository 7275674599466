import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { login } from 'redux/login/thunks'
import { Typography } from 'ui/atoms/Typography'
import { Form } from 'ui/molecules/Form'
import { Badge, Button } from '@frontend/design_system'
import { errorCodeSelector, errorMessageSelector } from 'redux/login/selectors'
import { SIGNIN_TEXT_FIELDS } from 'texts/signin'
import { URLS } from 'constants/urls'
import { handleRedirectToMain } from 'utils/handleRedirectToMain'
import { SignInFormData } from '../types'
import { AuthFormFields } from '../components/AuthFormFields'
import {
  initialFormValues,
  LOGIN_REJECTED_TYPE,
  MAX_ATTEMPS_COUNT,
} from './constants'
import { setErrorMessage } from 'redux/login/slice'
import { UserRoles } from 'constants/roles'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import { useEffect, useState } from 'react'
import { INVALID_CAPTCHA_CODE } from 'constants/errorCodes'
import { RejectValue } from 'redux/login/types'
import styles from './styles.module.scss'

export const SignInForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)
  const [attempts, setAttempts] = useState(0)

  const errorMessage = useAppSelector(errorMessageSelector)
  const errorCode = useAppSelector(errorCodeSelector)

  const handleSuccess = (role: UserRoles, id?: number) => {
    handleRedirectToMain(role, navigate)
    if (role === UserRoles.Supplier) {
      GTM.dataLayer({
        event: DATA_LAYER_EVENT.LOGIN,
        user_id: id,
      })
    }
  }

  const onSubmit = async (data: SignInFormData, validate: boolean) => {
    dispatch(setErrorMessage({ message: '' }))
    const { email, password } = data
    if (validate && email && password) {
      const loginResult = await dispatch(
        login({
          email,
          password,
          captchaToken,
          onSuccess: handleSuccess,
        })
      )

      if (
        loginResult.type === LOGIN_REJECTED_TYPE &&
        (loginResult.payload as RejectValue['rejectValue']).code !==
          INVALID_CAPTCHA_CODE
      ) {
        setAttempts(attempts + 1)
      }
    }
  }
  useEffect(() => {
    if (errorCode === INVALID_CAPTCHA_CODE) {
      setAttempts(MAX_ATTEMPS_COUNT + 1)
    }
  }, [errorCode])

  const handleForgotPasswordClick = () => {
    dispatch(setErrorMessage({ message: '' }))
    navigate(URLS.FORGOT_PASSWORD)
  }

  return (
    <div className={styles['signin-form']} data-testid="signin-form">
      <Typography Tag="h3" name="H3" className={styles.header}>
        {SIGNIN_TEXT_FIELDS.TITLE}
      </Typography>
      <Typography Tag="span" name="Button2" color="grey500">
        {SIGNIN_TEXT_FIELDS.SUBTITLE}
      </Typography>
      <Form
        onSubmit={onSubmit}
        className={styles['form-fields']}
        initValues={initialFormValues}
      >
        <AuthFormFields
          formType="signin"
          isError={!!errorMessage}
          setCaptchaToken={setCaptchaToken}
          attempts={attempts}
        />
        {errorMessage && (
          <Badge
            message={errorMessage}
            className={styles.badge}
            type="error"
            dataTestId="error-badge"
          />
        )}
        <div className={styles.buttons}>
          <div className={styles['button-wrapper']}>
            <Button
              label={SIGNIN_TEXT_FIELDS.BUTTON}
              htmlType="submit"
              size="large"
              dataTestId="signin-form-submit-button"
            />
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              label={SIGNIN_TEXT_FIELDS.LINK}
              onClick={handleForgotPasswordClick}
              variant="link"
              typographyName="Button1"
              dataTestId="reset-password-button"
            />
          </div>
        </div>
      </Form>
    </div>
  )
}
