import { DriverAgeRange, DriverAgeRangeType } from 'api/fleet/types'
import { WEEKDAYS } from 'texts/uiTexts'
import {
  LOCATION_ADDING_FORM_VALUES,
  PICKUP_OPTIONS,
  WAITING_HOURS_RANGE,
} from 'texts/locationDetails'
import { Cor } from 'types'
import { initialFirstRange } from 'ui/components/DriverAgeRanges/constants'

export const initialFormValues = {
  locationName: '',
  latitude: '',
  longitude: '',
  region: '',
  building: '',
  countryName: '',
  countryId: '',
  cityId: '',
  currencyName: '',
  phone: '',
  locationId: '',
  street: '',
  waitingHours: '',
  waitingHourPrice: '',
  waitingHourFeeType: 'NoFee',
  waitingHourRange: '',
  postalCode: '',
  phoneCountryCode: '',
  phoneAreaCode: '',
  hirePointName: '',
  phoneCor: {} as Cor,
  email: '',
  maximumPeriodDay: '',
  minimumPeriodDay: '1',
  pickupOption: 'Default',
  pickUpInstruction: '',
  dropOffInstruction: '',
  startReservationHour: '',
  driverAgeRange: {
    items: [initialFirstRange],
    rangeType: DriverAgeRangeType.NoFee,
  } as DriverAgeRange,
  locationType: '',
}

export const RESERVATION_FORM_FIELDS = {
  [LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.ID]:
    LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.LABEL,
  [LOCATION_ADDING_FORM_VALUES.MAXIMUM_PERIOD_DAY.ID]:
    LOCATION_ADDING_FORM_VALUES.MAXIMUM_PERIOD_DAY.LABEL,
  [LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.ID]:
    LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.LABEL,
}

export const WAITING_HOURS_FORM_FIELDS = {
  [LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.ID]:
    LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.LABEL,
  [LOCATION_ADDING_FORM_VALUES.PRICE.ID]:
    LOCATION_ADDING_FORM_VALUES.PRICE.LABEL,
  [LOCATION_ADDING_FORM_VALUES.WAITING_HOURS_RANGE.ID]:
    LOCATION_ADDING_FORM_VALUES.WAITING_HOURS_RANGE.LABEL,
}

export const WAITING_HOUR_RANGE_VALUES = {
  [WAITING_HOURS_RANGE.BOTH.ID]: WAITING_HOURS_RANGE.BOTH.LABEL,
  [WAITING_HOURS_RANGE.PICK_UP.ID]: WAITING_HOURS_RANGE.PICK_UP.LABEL,
  [WAITING_HOURS_RANGE.DROP_OFF.ID]: WAITING_HOURS_RANGE.DROP_OFF.LABEL,
}

export const PICKUP_OPTIONS_VALUES = {
  [PICKUP_OPTIONS.DEFAULT.ID]: PICKUP_OPTIONS.DEFAULT.LABEL,
  [PICKUP_OPTIONS.MEETING_IN_AIRPORT.ID]:
    PICKUP_OPTIONS.MEETING_IN_AIRPORT.LABEL,
  [PICKUP_OPTIONS.SHUTTLE_TO_LOCATION.ID]:
    PICKUP_OPTIONS.SHUTTLE_TO_LOCATION.LABEL,
  [PICKUP_OPTIONS.DESK_IN_TERMINAL.ID]: PICKUP_OPTIONS.DESK_IN_TERMINAL.LABEL,
}

export const CONTACT_FORM_FIELDS = {
  [LOCATION_ADDING_FORM_VALUES.COUNTRY.ID]:
    LOCATION_ADDING_FORM_VALUES.COUNTRY.LABEL,
  [LOCATION_ADDING_FORM_VALUES.CITY.ID]: LOCATION_ADDING_FORM_VALUES.CITY.LABEL,
  [LOCATION_ADDING_FORM_VALUES.LOCATION.ID]:
    LOCATION_ADDING_FORM_VALUES.LOCATION.LABEL,
  [LOCATION_ADDING_FORM_VALUES.STATE.ID]:
    LOCATION_ADDING_FORM_VALUES.STATE.LABEL,
  [LOCATION_ADDING_FORM_VALUES.STREET.ID]:
    LOCATION_ADDING_FORM_VALUES.STREET.LABEL,
  [LOCATION_ADDING_FORM_VALUES.BUILDING.ID]:
    LOCATION_ADDING_FORM_VALUES.BUILDING.LABEL,
  [LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.ID]:
    LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.LABEL,
  [LOCATION_ADDING_FORM_VALUES.LATITUDE.ID]:
    LOCATION_ADDING_FORM_VALUES.LATITUDE.LABEL,
  [LOCATION_ADDING_FORM_VALUES.LONGITUDE.ID]:
    LOCATION_ADDING_FORM_VALUES.LONGITUDE.LABEL,
  [LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.ID]:
    LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.LABEL,
  [LOCATION_ADDING_FORM_VALUES.EMAIL.ID]:
    LOCATION_ADDING_FORM_VALUES.EMAIL.LABEL,
  [LOCATION_ADDING_FORM_VALUES.PICKUP_OPTION.ID]:
    LOCATION_ADDING_FORM_VALUES.PICKUP_OPTION.LABEL,
  [LOCATION_ADDING_FORM_VALUES.PICKUP_INSTRUCTIONS.ID]:
    LOCATION_ADDING_FORM_VALUES.PICKUP_INSTRUCTIONS.LABEL,
  [LOCATION_ADDING_FORM_VALUES.DROPOFF_INSTRUCTIONS.ID]:
    LOCATION_ADDING_FORM_VALUES.DROPOFF_INSTRUCTIONS.LABEL,
  [LOCATION_ADDING_FORM_VALUES.PHONE_COR.ID]:
    LOCATION_ADDING_FORM_VALUES.PHONE_COR.ID,
}

export const REQUIRED_FIELDS = [
  LOCATION_ADDING_FORM_VALUES.COUNTRY.ID,
  LOCATION_ADDING_FORM_VALUES.CITY.ID,
  LOCATION_ADDING_FORM_VALUES.LOCATION.ID,
  LOCATION_ADDING_FORM_VALUES.STREET.ID,
  LOCATION_ADDING_FORM_VALUES.BUILDING.ID,
  LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.ID,
  LOCATION_ADDING_FORM_VALUES.LATITUDE.ID,
  LOCATION_ADDING_FORM_VALUES.LONGITUDE.ID,
  LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.ID,
  LOCATION_ADDING_FORM_VALUES.PHONE_COR.ID,
  LOCATION_ADDING_FORM_VALUES.EMAIL.ID,
  LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.ID,
  LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.ID,
]

export const WORKING_HOURS_INITIAL_VALUES = [
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.MONDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.TUESDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.WEDNESDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.THURSDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.FRIDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.SATURDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.SUNDAY,
  },
]
