import { AuditApiTypes, auditApi } from 'api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container } from 'ui/molecules/Container'
import { Table } from 'ui/molecules/Table'
import { Typography } from 'ui/atoms/Typography'
import { Button } from 'ui/atoms/Button'
import { breadcrumbs } from 'constants/breadcrumbs'
import {
  ACTIVITY_HISTORY,
  detailsHeadItems,
  ACTIVITY_HISTORY_FIELDS,
} from 'texts/activityHistory'
import { isNotEmptyValueObject } from 'utils/object'
import { useApiRequest } from 'hooks/useApiRequest'
import { PRICE_LIST_CATEGORY } from './constants'
import styles from './styles.module.scss'

export const ActivityHistoryDetails = () => {
  const { auditId } = useParams()
  const [auditData, setAuditData] = useState<AuditApiTypes.AuditLog | null>(
    null
  )
  const [isRatesExist, setIsRatesExist] = useState(false)

  const breadcrumbsList = [breadcrumbs.activityHistory]
  const navigate = useNavigate()

  const { apiRequest, loading } = useApiRequest((auditId) =>
    auditApi.getAuditDetailsInfo(auditId)
  )

  useEffect(() => {
    if (auditId) {
      const getAuditInfo = async () => {
        const auditInfoResponse = await apiRequest(auditId)
        if (auditInfoResponse) {
          const auditData = { ...auditInfoResponse.data }
          if (
            auditData.auditLog.changes?.rates &&
            isNotEmptyValueObject(auditData.auditLog.changes?.rates)
          ) {
            setIsRatesExist(true)
            delete auditData.auditLog.changes.rates
          }
          setAuditData(auditData)
        }
      }
      getAuditInfo()
    }
  }, [auditId])

  const formattedHeadItems = detailsHeadItems.map((el) => ({
    value: <Typography name="Subtitle3">{el}</Typography>,
  }))

  const formatValue = (
    value: AuditApiTypes.Entity[keyof AuditApiTypes.Entity]
  ) => {
    const stringifiedValue = JSON.stringify(value)
    if (stringifiedValue && stringifiedValue !== '""') {
      return stringifiedValue.replaceAll(',', ', ')
    } else {
      return '[]'
    }
  }

  const formattedBodyItems = useMemo(() => {
    if (auditData?.auditLog.changes) {
      const { changes, newEntity, oldEntity } = auditData.auditLog
      const params = Object.keys(changes)
      const arr = params.map((el) => ({
        items: [
          ACTIVITY_HISTORY_FIELDS[el],
          formatValue(oldEntity[el]),
          formatValue(newEntity[el]),
        ],
      }))

      return arr
    }
  }, [auditData])

  const goToRates = () => {
    navigate('rates')
  }

  useEffect(() => {
    if (
      auditData?.auditLog.category === PRICE_LIST_CATEGORY &&
      auditData?.auditLog.changes
    ) {
      !isNotEmptyValueObject(auditData?.auditLog.changes) && goToRates()
    }
  }, [auditData])

  return (
    <Container
      title={`${ACTIVITY_HISTORY.TITLE} ${auditData?.auditLog.actionType}`}
      rightBlock={
        <Typography
          className={styles.timestamp}
          name="body2WBold"
          markColor="grey500"
        >
          {ACTIVITY_HISTORY.TIMESTAMP}{' '}
          <mark>{auditData?.auditLog.auditDate}</mark>
        </Typography>
      }
      breadcrumbList={breadcrumbsList}
      currentPageLabel={auditData?.auditLog.element}
      companyName={auditData?.auditLog.companyName}
      loading={loading}
    >
      <div className={styles.list}>
        <div className={styles.header}>
          <div className={styles.right}>
            {isRatesExist && (
              <div className={styles.button}>
                <Button
                  size="small"
                  label={ACTIVITY_HISTORY.GO_TO_RATES}
                  variant="secondary"
                  onClick={goToRates}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.table}>
          <Table
            headItems={formattedHeadItems}
            bodyItems={formattedBodyItems}
            rowItemWrap
          />
        </div>
      </div>
    </Container>
  )
}
