import { locationsApi } from 'api'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/hooks'
import { LOCATION_ADD } from 'texts/locationAdd'
import { Typography } from 'ui/atoms/Typography'
import { URLS } from 'constants/urls'
import { LIST_OF_LOCATIONS } from 'texts/locationList'
import { Container } from 'ui/molecules/Container'
import { ErrorResponse } from 'api/types'
import { FormValues } from '../LocationDetailsForm/types'
import { LocationDetailsForm } from '../LocationDetailsForm'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useNavigate } from 'react-router-dom'
import { useApiRequest } from 'hooks/useApiRequest'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import { ACCOUNT_STATUSES, REGISTRATION_STATUSES } from 'api/auth/constants'
import { useEffect, useState } from 'react'
import { ApproveBtn } from 'ui/components/ApproveBtn'
import { useCompanyId } from 'hooks/useCompanyId'
import { resetSelectedFilters } from 'redux/filters/slice'

export const LocationAdd = () => {
  const [responseError, setResponseError] = useState('')
  const { accountStatus, sectionsStatuses } = useAppSelector(userDataSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { apiRequest, loading } = useApiRequest(
    (values) => locationsApi.addHirePoint(values),
    undefined,
    undefined,
    {
      withoutNotification: false,
      isThrowError: false,
      onError: (error) => {
        const errorResponse = error as ErrorResponse
        if (errorResponse?.responseStatus?.errorCode === 'NotAllowedAction') {
          setResponseError(errorResponse.responseStatus.message)
        }
      },
    },
    false
  )

  const isOperator = useAppSelector(isOperatorSelector)
  const isAccountApproved =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED

  const handleResetError = () => {
    setResponseError('')
  }

  const companyId = useCompanyId()

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'locations'
  )

  useEffect(() => {
    if (
      !isOperator &&
      sectionsStatuses?.locationsStatus === REGISTRATION_STATUSES.PENDING
    ) {
      navigate(`${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}`)
    }
  }, [])

  const handleSubmit = async (data: Partial<FormValues>) => {
    const values = {
      ...data,
      waitingHours: {
        waitingHours: data.waitingHours,
        waitingHourPrice: data.waitingHourPrice,
        waitingHourRange: data.waitingHourRange,
        waitingHourFeeType: data.waitingHourFeeType,
      },
      companyId: Number(companyId),
    }
    delete values.phoneCor

    const response = await apiRequest(values)
    if (response) {
      dispatch(resetSelectedFilters())
      dispatch(
        setNotificationMessage({
          notificationMessage: LOCATION_ADD.MODAL_TITLE,
        })
      )
      GTM.dataLayer({
        event: DATA_LAYER_EVENT.LOCATION_ADD,
        location_name: response.data.hirePointInfo.locationName,
        location_country: response.data.hirePointInfo.countryName,
        location_city: response.data.hirePointInfo.cityName,
      })
      if (isAccountApproved) {
        const path = isOperator
          ? `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/${companyId}`
          : `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}`
        navigate(path)
      } else {
        navigate(0)
      }
    }
  }

  return (
    <Container
      title={<Typography name="H5">{LOCATION_ADD.TITLE}</Typography>}
      breadcrumbList={breadcrumbList}
      currentPageLabel={LOCATION_ADD.BREADCRUMB}
      withNavigation={isOperator}
      tooltip={
        accountStatus !== ACCOUNT_STATUSES.APPROVED
          ? LIST_OF_LOCATIONS.TOOLTIP_CONTENT
          : undefined
      }
      rightBlock={(id: string) => <ApproveBtn companyId={id} />}
    >
      <LocationDetailsForm
        handleSubmit={handleSubmit}
        isLoading={loading}
        error={responseError}
        resetError={handleResetError}
      />
    </Container>
  )
}
