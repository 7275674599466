import { VEHICLE_DETAILS } from 'texts/vehicleDetails'
import { Typography } from '@frontend/design_system'
import { DRIVER_AGE_RANGE } from 'texts/driverAgeRange'
import { DriverAgeRangeItem, DriverAgeRangeType } from 'api/fleet/types'
import { CheckedIcon } from 'ui/icons'
import { FormValues } from '../../types'
import styles from './styles.module.scss'

export const DriverAgeRangeBlock = ({
  values,
}: {
  values: Partial<FormValues>
}) => {
  const renderDriverAge = (
    item: DriverAgeRangeItem,
    index: number,
    rangeType?: DriverAgeRangeType
  ) => {
    const checked = item.ageEnd && item.ageStart

    return (
      <div className={styles['driver-age-range']} key={index}>
        <CheckedIcon
          color={checked ? 'green700Master' : 'grey500'}
          size="medium"
        />
        <div className={styles['driver-age-range-info']}>
          <div>
            <Typography
              name="body1WBold"
              className={styles['driver-age-range-info-item']}
            >
              {`${DRIVER_AGE_RANGE.START.LABEL} - ${DRIVER_AGE_RANGE.END.LABEL}:`}
            </Typography>
            <Typography
              name="body1WMedium"
              className={styles['driver-age-range-info-value']}
            >
              {item.ageStart && item.ageEnd
                ? `${item.ageStart} - ${item.ageEnd}`
                : '-'}
            </Typography>
          </div>
          <Typography
            name="body1WBold"
            className={styles['driver-age-range-info-item']}
          >
            {DRIVER_AGE_RANGE.FEE.LABEL}:
            <Typography
              name="body1WMedium"
              className={styles['driver-age-range-info-value']}
            >
              {rangeType !== DriverAgeRangeType.NoFee && item.price !== ''
                ? String(item.price)
                : '-'}
            </Typography>
          </Typography>
          <Typography
            name="body1WBold"
            className={styles['driver-age-range-info-item']}
          >
            {DRIVER_AGE_RANGE.MAX_PRICE.LABEL}:
            <Typography
              name="body1WMedium"
              className={styles['driver-age-range-info-value']}
            >
              {rangeType == DriverAgeRangeType.PerDay && item.maxPrice !== ''
                ? String(item.maxPrice)
                : '-'}
            </Typography>
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <>
      {!!values.driverAgeRange?.items.length && (
        <div className={styles['driver-age-range-block']}>
          <Typography
            name="body1WBold"
            className={styles['driver-age-range-title']}
          >
            {VEHICLE_DETAILS.DRIVER_AGE_RANGE_TITLE}
          </Typography>
          {values.driverAgeRange.items.map((item, i) =>
            renderDriverAge(item, i, values.driverAgeRange?.rangeType)
          )}
        </div>
      )}
    </>
  )
}
