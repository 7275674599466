import { breadcrumbs } from 'constants/breadcrumbs'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector } from 'redux/login/selectors'
import { MENU_NAMES } from 'texts/menuNames'
import { Button } from 'ui/atoms/Button'
import { InfoIcon, PlusIcon } from 'ui/icons'
import { Confirmation } from 'ui/molecules/Confirmation'
import { Pagination, Tooltip, Typography } from '@frontend/design_system'
import { Table } from 'ui/molecules/Table'
import { Container } from 'ui/molecules/Container'
import { useDispatch, useSelector } from 'react-redux'
import {
  PRICE_LIST,
  PRICE_LIST_DELETE_CONFIRMATION,
  RATES,
  headItems,
} from 'texts/priceList'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { FleetApiTypes, fleetApi } from 'api'
import { PriceList } from 'api/fleet/types'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import styles from './styles.module.scss'
import { TableTooltip } from 'ui/molecules/TableTooltip'
import { useApiRequest } from 'hooks/useApiRequest'
import { Filter } from 'ui/atoms/Filter'
import { isNotEmptyValueObject } from 'utils/object'
import { PRICE_LISTS } from 'texts/priceLists'
import { setNotificationMessage } from 'redux/notifications/slice'
import { URLS } from 'constants/urls'
import { format } from 'date-fns'
import { DATEPICKER_DEFAULT_FORMAT } from 'constants/date'
import {
  formatSeasonRangesFromString,
  getSeasonFromSeasonRanges,
} from './utils/getSeasonFromSeasonRange'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import {
  filterItemsSelector,
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import {
  resetPriceListFilters,
  resetSelectedFilters,
  setFilterItems,
} from 'redux/filters/slice'
import { useFilterSettings } from 'hooks/useFilterSettings'
import { PRICE_LIST_FILTER_KEY } from 'constants/filters'
import { checkOnlyYearFilterSelected, prepareFilterItems } from 'utils/filters'
import { sortingSelector } from 'redux/sorting/selectors'
import { prepareFiltersForReq, prepareSortingForReq } from 'utils/table'
import { COUNTRY_INDEX, LOCATION_INDEX } from './constants'
import { TableHeadProps } from 'ui/molecules/Table/TableHead/types'
import { ResetFiltersButton } from 'ui/components/ResetFiltersButton'
import { useCompanyId } from 'hooks/useCompanyId'

export const PriceLists = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [priceLists, setPriceLists] =
    useState<FleetApiTypes.PriceListResponse | null>(null)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [selectedPriceList, setSelectedPriceList] = useState<PriceList>()
  const isOperator = useAppSelector(isOperatorSelector)
  const sortingData = useSelector(sortingSelector)
  const selectedFiltersCount = useSelector(selectedFiltersCountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const filterItems = useSelector(filterItemsSelector)

  const navigate = useNavigate()

  const breadcrumbsList = [breadcrumbs.profileList]

  const companyId = useCompanyId()

  const priceListFiltersRequest = useApiRequest((companyId) =>
    fleetApi.getPriceListFilters(Number(companyId))
  )

  const filteredPriceListRequest = useApiRequest((companyId) =>
    fleetApi.getPriceLists(
      Number(companyId),
      page - 1,
      Number(pageSize),
      prepareFiltersForReq(
        selectedFilters.priceList,
        selectedFiltersCount.priceList,
        PRICE_LIST_FILTER_KEY
      ),
      prepareSortingForReq(sortingData.priceList, PRICE_LIST_FILTER_KEY)
    )
  )
  const deletePriceListRequest = useApiRequest((id) =>
    fleetApi.deletePriceList(id)
  )

  const getFilterSettings = useFilterSettings(PRICE_LIST_FILTER_KEY, setPage)

  useEffect(() => {
    dispatch(resetPriceListFilters())
  }, [])

  const fetchPriceLists = () => {
    const fetchPriceListFilters = async () => {
      const priceListFilters = await priceListFiltersRequest.apiRequest(
        companyId
      )

      if (priceListFilters) {
        const preparedFilters = prepareFilterItems(priceListFilters.data)
        const filterYearItems = priceListFilters.data.years.map((year) => ({
          value: year,
          selected: Number(year) === new Date().getFullYear(),
        }))
        dispatch(
          setFilterItems({
            items: { ...preparedFilters, years: filterYearItems },
            key: PRICE_LIST_FILTER_KEY,
          })
        )
      }
    }

    const fetchFilteredPriceLists = async () => {
      const response = await filteredPriceListRequest.apiRequest(companyId)

      if (response) {
        setPriceLists(response.data)
      }
    }

    isNotEmptyValueObject(selectedFilters.priceList)
      ? fetchFilteredPriceLists()
      : fetchPriceListFilters()
  }

  useEffect(() => {
    if (companyId) {
      fetchPriceLists()
    }
  }, [page, pageSize, selectedFilters.priceList, companyId, sortingData])

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const handleRowClick = (id: number) => {
    navigate(`${URLS.FLEET}${URLS.PRICE_LIST}/${companyId}/${id}/edit`)
  }

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const handleAddPriceList = () => navigate('add')

  const handleDeletePriceList = (priceList: PriceList) => {
    setConfirmationModalOpen(true)
    setSelectedPriceList(priceList)
  }

  const handleConfirm = async () => {
    if (selectedPriceList) {
      const seasonRangesFromString = formatSeasonRangesFromString(
        selectedPriceList.seasonRange
      )
      const joinedSeasons = getSeasonFromSeasonRanges(
        seasonRangesFromString
      ).join('_')
      const response = await deletePriceListRequest.apiRequest(
        selectedPriceList.id
      )
      if (response) {
        dispatch(resetSelectedFilters())
        fetchPriceLists()
        dispatch(
          setNotificationMessage({
            notificationMessage:
              PRICE_LIST_DELETE_CONFIRMATION.SUCCESS_MODAL.TITLE,
          })
        )
        GTM.dataLayer({
          event: DATA_LAYER_EVENT.PRICE_LIST_REMOVED,
          season_range: joinedSeasons,
        })
      }
      setConfirmationModalOpen(false)
    }
  }

  const goToRates = (id: number, e: React.MouseEvent) => {
    e.stopPropagation()
    navigate(`${URLS.FLEET}${URLS.PRICE_LIST}/${companyId}/${id}/rates`)
  }

  const formattedHeadItems = useMemo(() => {
    const newHeadItems: TableHeadProps['item'][] = headItems.map(
      (header, i) => {
        const COR_COLUMN_INDEX = 3
        if (i === COR_COLUMN_INDEX) {
          return {
            value: (
              <>
                <Typography name="Subtitle3">{header}</Typography>
                <Tooltip
                  arrowPosition="center"
                  popupContent={
                    <Typography name="caption1WMedium">
                      {PRICE_LIST.COR_TOOLTIP}
                    </Typography>
                  }
                  placement="bottom"
                >
                  <InfoIcon color="blue700" size="small" />
                </Tooltip>
              </>
            ),
          }
        }
        return { value: header }
      }
    )

    newHeadItems[COUNTRY_INDEX].filterSettings = getFilterSettings('countries')
    newHeadItems[LOCATION_INDEX].filterSettings = getFilterSettings('locations')
    newHeadItems[4].filterSettings = getFilterSettings('startperiod')

    return newHeadItems
  }, [filterItems.profileList, getFilterSettings])

  const formattedBodyItems = useMemo(
    () =>
      priceLists?.priceList.pageItems?.map((priceList) => ({
        items: [
          <TableTooltip
            title={priceList.country}
            onClick={() => handleRowClick(priceList.id)}
          />,
          <TableTooltip
            title={priceList.location}
            onClick={() => handleRowClick(priceList.id)}
          />,
          <div className={styles['prices-button']}>
            <Button
              variant="link"
              alignLabel="left"
              label={RATES}
              typographyName="Subtitle7"
              onClick={(e) => goToRates(priceList.id, e)}
            />
          </div>,
          <TableTooltip
            title={priceList.countriesOfResidence}
            onClick={() => handleRowClick(priceList.id)}
          />,
          <TableTooltip
            title={priceList.seasonRange}
            onClick={() => handleRowClick(priceList.id)}
          />,
          <TableTooltip
            title={priceList.dailyRange}
            onClick={() => handleRowClick(priceList.id)}
            tooltip={priceList.dailyRange.replaceAll(',', ', ')}
          />,
          format(new Date(priceList.startPeriod), DATEPICKER_DEFAULT_FORMAT),
          format(new Date(priceList.endPeriod), DATEPICKER_DEFAULT_FORMAT),
        ],
        onClick: () => handleRowClick(priceList.id),
        removeRowClick: () => handleDeletePriceList(priceList),
      })),
    [priceLists]
  )

  return (
    <div>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question:
              PRICE_LIST_DELETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: PRICE_LISTS.DELETE,
          }}
          successModalProps={{
            onClose: handleModalClose,
            title: PRICE_LIST_DELETE_CONFIRMATION.SUCCESS_MODAL.TITLE,
          }}
        />
      )}
      <Container
        breadcrumbList={isOperator ? breadcrumbsList : []}
        currentPageLabel={MENU_NAMES.PRICE_LISTS}
        title={MENU_NAMES.PRICE_LISTS}
        tooltip={PRICE_LIST.TOOLTIP}
        withNavigation={isOperator}
        loading={
          filteredPriceListRequest.loading && !filteredPriceListRequest.isLoaded
        }
      >
        <div className={styles.list}>
          <div className={styles.header}>
            <ResetFiltersButton
              selectedFiltersCount={
                checkOnlyYearFilterSelected(selectedFilters.priceList)
                  ? 0
                  : selectedFiltersCount.priceList
              }
            />
            <div className={styles.buttons}>
              <div className={styles.button}>
                <Button
                  size="small"
                  typographyName="Button2"
                  variant="outline"
                  label={PRICE_LIST.ADD_BUTTON}
                  onClick={handleAddPriceList}
                  iconPosition="right"
                  icon={<PlusIcon color="blue700" />}
                />
              </div>
              <div className={styles['filter-icon']}>
                <Filter
                  variant="secondary"
                  items={getFilterSettings('years').items}
                  onSelectAllItems={getFilterSettings('years').onSelectAllItems}
                  onReset={getFilterSettings('years').onReset}
                />
              </div>
            </div>
          </div>
          {formattedBodyItems && (
            <div className={styles.table}>
              <Table
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
              />
            </div>
          )}
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            pageItemCount={priceLists?.priceList.pageItems.length}
            total={priceLists?.priceList.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </Container>
    </div>
  )
}
