export const headItems = [
  'Country',
  'Location',
  'Car models',
  'Equipment',
  'Status',
]

export const COUNTRY_INDEX = 0
export const LOCATION_INDEX = 1
