import { phoneNumberRules } from 'constants/form'
import {
  allSymbolsAndEnglishLettersRegex,
  latitudeRegex,
  longitudeRegex,
  mailRegex,
} from 'constants/regex'
import { LOCATION_ADDING_FORM_VALUES } from 'texts/locationDetails'
import { FormValuesSetting } from 'types/form'

export const formValuesSetting: FormValuesSetting = {
  hirePointName: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.HIRE_POINT_NAME.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: LOCATION_ADDING_FORM_VALUES.HIRE_POINT_NAME.VALIDATION_MESSAGE,
      },
    ],
  },
  state: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: LOCATION_ADDING_FORM_VALUES.STATE.VALIDATION_MESSAGE,
      },
    ],
  },
  street: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.STREET.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: LOCATION_ADDING_FORM_VALUES.STREET.VALIDATION_MESSAGE,
      },
    ],
  },
  building: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.BUILDING.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: LOCATION_ADDING_FORM_VALUES.BUILDING.VALIDATION_MESSAGE,
      },
    ],
  },
  latitude: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.LATITUDE.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: latitudeRegex,
        message: LOCATION_ADDING_FORM_VALUES.LATITUDE.VALIDATION_MESSAGE,
      },
      {
        type: 'custom',
        message: LOCATION_ADDING_FORM_VALUES.LATITUDE.MIN_VALUE_MESSAGE,
        value: (value: string) => !Number(value),
      },
    ],
  },
  countryId: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.COUNTRY.REQUIRED_MESSAGE,
      },
    ],
  },
  longitude: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.LONGITUDE.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: longitudeRegex,
        message: LOCATION_ADDING_FORM_VALUES.LONGITUDE.VALIDATION_MESSAGE,
      },
      {
        type: 'custom',
        message: LOCATION_ADDING_FORM_VALUES.LONGITUDE.MIN_VALUE_MESSAGE,
        value: (value: string) => !Number(value),
      },
    ],
  },
  postalCode: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.VALIDATION_MESSAGE,
      },
    ],
  },
  cityId: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.CITY.REQUIRED_MESSAGE,
      },
    ],
  },
  locationId: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.LOCATION.REQUIRED_MESSAGE,
      },
    ],
  },
  currencyID: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.CURRENCY.REQUIRED_MESSAGE,
      },
    ],
  },
  phone: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: phoneNumberRules,
  },
  email: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: LOCATION_ADDING_FORM_VALUES.EMAIL.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: mailRegex,
        message: LOCATION_ADDING_FORM_VALUES.EMAIL.VALIDATION_MESSAGE,
      },
    ],
  },
  pickUpInstruction: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.VALIDATION_MESSAGE,
      },
    ],
  },
  dropOffInstruction: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.VALIDATION_MESSAGE,
      },
    ],
  },
}

export const LOCATION_TYPE_AIRPORT = 'Airport'
