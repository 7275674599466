import { Typography } from '@frontend/design_system'
import { PENDING_INFO } from 'texts/pendingForApproval'
import styles from './styles.module.scss'

export const PendingBlock = () => (
  <div className={styles.container} data-testid="pending-block">
    <Typography name="subtitleWBold" className={styles.title}>
      {PENDING_INFO.title}
    </Typography>
    <Typography name="body2WMedium">{PENDING_INFO.description}</Typography>
  </div>
)
