import { VEHICLE_DETAILS_FORM_VALUES } from 'texts/vehicleDetails'
import mini from 'assets/img/carTypes/Mini.webp'
import economy from 'assets/img/carTypes/Economy.webp'
import compact from 'assets/img/carTypes/Compact.webp'
import intermediate from 'assets/img/carTypes/Intermediate.webp'
import fullsize from 'assets/img/carTypes/Fullsize.webp'
import special from 'assets/img/carTypes/Special.webp'
import luxury from 'assets/img/carTypes/Luxury.webp'
import suv from 'assets/img/carTypes/SUV.webp'
import van from 'assets/img/carTypes/VAN.webp'
import other from 'assets/img/carTypes/Other.webp'
import electric from 'assets/img/carTypes/Electric.webp'
import estate from 'assets/img/carTypes/Estate.webp'
import convertible from 'assets/img/carTypes/Convertible.webp'

export const VEHICLE_BLOCK_FIELDS = {
  [VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.ID]:
    VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.ID]:
    VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.ID]:
    VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.FUEL.ID]: VEHICLE_DETAILS_FORM_VALUES.FUEL.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.SIPP.ID]: VEHICLE_DETAILS_FORM_VALUES.SIPP.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.SEATS.ID]:
    VEHICLE_DETAILS_FORM_VALUES.SEATS.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.DOORS.ID]:
    VEHICLE_DETAILS_FORM_VALUES.DOORS.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.ID]:
    VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.AIR_CONDITIONING.ID]:
    VEHICLE_DETAILS_FORM_VALUES.AIR_CONDITIONING.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.QUANTITY.ID]:
    VEHICLE_DETAILS_FORM_VALUES.QUANTITY.LABEL,
}

import {
  CAR_TYPE_ELECTRIC,
  CAR_TYPE_SUV,
  CAR_TYPE_MINI,
  CAR_TYPE_COMPACT,
  CAR_TYPE_INTERMEDIATE,
  CAR_TYPE_ECONOMY,
  CAR_TYPE_SPECIAL,
  CAR_TYPE_LUXURY,
  CAR_TYPE_FULLSIZE,
  CAR_TYPE_VAN,
  CAR_TYPE_OTHER,
  CAR_TYPE_ESTATE,
  CAR_TYPE_CONVERTIBLE,
  CAR_TYPE_ECONOMY_ELITE,
  CAR_TYPE_LUXURY_ELITE,
  CAR_TYPE_FULLSIZE_ELITE,
} from 'constants/carTypes'

export const carTypeImages = {
  [CAR_TYPE_ELECTRIC]: electric,
  [CAR_TYPE_SUV]: suv,
  [CAR_TYPE_MINI]: mini,
  [CAR_TYPE_ECONOMY]: economy,
  [CAR_TYPE_ECONOMY_ELITE]: economy,
  [CAR_TYPE_COMPACT]: compact,
  [CAR_TYPE_INTERMEDIATE]: intermediate,
  [CAR_TYPE_SPECIAL]: special,
  [CAR_TYPE_LUXURY]: luxury,
  [CAR_TYPE_LUXURY_ELITE]: luxury,
  [CAR_TYPE_VAN]: van,
  [CAR_TYPE_OTHER]: other,
  [CAR_TYPE_ESTATE]: estate,
  [CAR_TYPE_CONVERTIBLE]: convertible,
  [CAR_TYPE_FULLSIZE]: fullsize,
  [CAR_TYPE_FULLSIZE_ELITE]: fullsize,
}
