import { DriverAgeRange, DriverAgeRangeType } from 'api/fleet/types'
import { MAX_AGE, MIN_AGE } from 'constants/form'
import { DRIVER_AGE_RANGE_VALIDATIONS } from 'texts/driverAgeRange'

export const useDriverAgeRangeValidation = (isRequired?: boolean) => {
  const checkOverlap = (driverAgeRanges: DriverAgeRange) => {
    for (let i = 0; i < driverAgeRanges.items.length - 1; i++) {
      for (let j = i + 1; j < driverAgeRanges.items.length; j++) {
        const firstAgeStart = Number(driverAgeRanges.items[i].ageStart)
        const firstAgeEnd = Number(driverAgeRanges.items[i].ageEnd)
        const secondAgeStart = Number(driverAgeRanges.items[j].ageStart)
        const secondAgeEnd = Number(driverAgeRanges.items[j].ageEnd)

        if (!(firstAgeEnd < secondAgeStart || secondAgeEnd < firstAgeStart)) {
          return true
        }
      }
    }
  }

  const checkRangesGap = (driverAgeRanges: DriverAgeRange) => {
    for (let i = 0; i < driverAgeRanges.items.length - 1; i++) {
      const firstAgeEnd = Number(driverAgeRanges.items[i].ageEnd)
      const secondAgeStart = Number(driverAgeRanges.items[i + 1].ageStart)

      if (secondAgeStart - firstAgeEnd > 1) {
        return true
      }
    }
    return false
  }

  const checkEmptyFields = (driverAgeRanges: DriverAgeRange) => {
    let isError = false
    if (driverAgeRanges.rangeType === DriverAgeRangeType.PerDay) {
      const isAllFieldFilled = driverAgeRanges.items.every(
        ({ ageStart, ageEnd }) => ageStart && ageEnd
      )
      isError = !isAllFieldFilled
    } else {
      const isAllFieldFilled = driverAgeRanges.items.every(
        ({ ageStart, ageEnd }) => ageStart && ageEnd
      )
      isError = !isAllFieldFilled
    }
    return isError
  }

  const checkDriverAgeRange = (driverAgeRanges?: DriverAgeRange) => {
    let error = ''

    if (!driverAgeRanges) {
      if (isRequired) {
        error = DRIVER_AGE_RANGE_VALIDATIONS.REQUIRED
      } else {
        return error
      }
    } else {
      const { items: driverAgeRange, rangeType } = driverAgeRanges
      if (!driverAgeRange.length && isRequired) {
        error = DRIVER_AGE_RANGE_VALIDATIONS.REQUIRED
      } else if (checkEmptyFields(driverAgeRanges)) {
        error = DRIVER_AGE_RANGE_VALIDATIONS.EMPTY_FIELD
      } else if (checkOverlap(driverAgeRanges)) {
        error = DRIVER_AGE_RANGE_VALIDATIONS.RANGES_OVERLAP
      } else if (checkRangesGap(driverAgeRanges)) {
        error = DRIVER_AGE_RANGE_VALIDATIONS.RANGES_GAPS
      } else {
        driverAgeRanges.items.forEach(
          ({ ageStart, ageEnd, maxPrice, price }) => {
            if (Number(ageStart) < MIN_AGE) {
              error = DRIVER_AGE_RANGE_VALIDATIONS.MIN_AGE
            } else if (Number(ageEnd) > MAX_AGE) {
              error = DRIVER_AGE_RANGE_VALIDATIONS.MAX_AGE
            } else if (Number(ageEnd) < Number(ageStart)) {
              error = DRIVER_AGE_RANGE_VALIDATIONS.END_MORE_START
            } else if (
              Number(price) > Number(maxPrice) &&
              rangeType === DriverAgeRangeType.PerDay
            ) {
              error = DRIVER_AGE_RANGE_VALIDATIONS.MAX_PRICE_MORE_FEE
            }
          }
        )
      }
    }
    return error
  }
  return checkDriverAgeRange
}
