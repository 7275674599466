import { Modal, Typography, Button } from '@frontend/design_system'
import {
  APPROVED_MODAL_DESCRIPTION,
  APPROVED_MODAL_SUCCESS_NOTIFICATION,
  APPROVED_MODAL_TITLE,
} from 'texts/approval'
import styles from './styles.module.scss'
import { CANCEL, CONFIRM } from 'texts/uiTexts'
import { useApiRequest } from 'hooks/useApiRequest'
import { profileApi } from 'api'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { setAccountStatus } from 'redux/login/slice'

interface IApprovedModalProps {
  onClose: (e?: MouseEvent) => void
  companyId: string
}

export const ApprovedModal = ({ onClose, companyId }: IApprovedModalProps) => {
  const dispatch = useDispatch()

  const { apiRequest: approveCompany, loading } = useApiRequest(
    () => profileApi.approveCompany(companyId),
    undefined,
    true,
    {
      withoutNotification: false,
      isThrowError: true,
    },
    false
  )

  const onApprove = async () => {
    try {
      await approveCompany()
      dispatch(
        setNotificationMessage({
          notificationMessage: APPROVED_MODAL_SUCCESS_NOTIFICATION,
        })
      )
      dispatch(setAccountStatus('Approved'))
    } catch (err) {}
  }

  return (
    <Modal
      onClose={onClose}
      position="center-horizontal"
      title={APPROVED_MODAL_TITLE}
      popupClassName={styles.popup}
      headerSize="large"
      isDelimiter
    >
      <div className={styles.content}>
        <Typography name="Button1" color="grey500" Tag="p">
          {APPROVED_MODAL_DESCRIPTION}
        </Typography>
        <div className={styles.actions}>
          <Button
            label={CANCEL}
            variant="secondary-border"
            size="large"
            onClick={onClose}
          />
          <Button
            label={CONFIRM}
            size="large"
            onClick={onApprove}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  )
}
