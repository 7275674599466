import { Input, InputPassword } from '@frontend/design_system'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { FORM_FIELDS_VALUES } from 'texts/formFields'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormFieldsProps } from './types'
import {
  confirmPasswordRules,
  emailRules,
  passwordRules,
} from 'texts/validateRules'
import { EnvironmentVariablesService } from 'utils/env'
import { MAX_ATTEMPS_COUNT } from 'modules/auth/Signin/constants'
import styles from './styles.module.scss'

export const AuthFormFields = ({
  formType,
  passwordScale,
  isError,
  setCaptchaToken,
  attempts,
}: FormFieldsProps) => {
  const isSigninForm = formType === 'signin'

  return (
    <>
      <div className={styles.form}>
        {isSigninForm && (
          <div className={styles['input-wrapper']}>
            <FormItem id={FORM_FIELDS_VALUES.EMAIL.ID} rules={emailRules}>
              {({ value, error, onChange }) => (
                <Input
                  value={value}
                  size="large"
                  iconLeft="MailIcon"
                  label={FORM_FIELDS_VALUES.EMAIL.LABEL}
                  onChange={onChange}
                  isError={isError}
                  iconSize="small"
                  spaceForError="default"
                  placeholder={FORM_FIELDS_VALUES.EMAIL.PLACEHOLDER}
                  typographyName="Button2"
                  error={error}
                  dataTestId="auth-form-input-username"
                />
              )}
            </FormItem>
          </div>
        )}
        <div className={styles['input-wrapper']}>
          <FormItem id={FORM_FIELDS_VALUES.PASSWORD.ID} rules={passwordRules}>
            {({ value, error, onChange }) => (
              <InputPassword
                value={value}
                size="large"
                label={FORM_FIELDS_VALUES.PASSWORD.LABEL}
                onChange={onChange}
                isError={isError}
                placeholder={FORM_FIELDS_VALUES.PASSWORD.PLACEHOLDER}
                error={error}
                passwordScale={passwordScale}
                withCompare
                dataTestId="auth-form-input-password"
              />
            )}
          </FormItem>
        </div>
        {!isSigninForm && (
          <div className={styles['input-wrapper']}>
            <FormItem
              id={FORM_FIELDS_VALUES.CONFIRM_PASSWORD.ID}
              rules={confirmPasswordRules}
            >
              {({ value, error, onChange }) => (
                <InputPassword
                  size="large"
                  value={value}
                  error={error}
                  isError={isError}
                  onChange={onChange}
                  label={FORM_FIELDS_VALUES.CONFIRM_PASSWORD.LABEL}
                  placeholder={FORM_FIELDS_VALUES.CONFIRM_PASSWORD.PLACEHOLDER}
                  dataTestId="auth-form-input-confirm-password"
                />
              )}
            </FormItem>
          </div>
        )}
      </div>
      {isSigninForm && Number(attempts) > MAX_ATTEMPS_COUNT && (
        <div
          key={attempts}
          className={styles.captcha}
          data-testid="captcha-wrapper"
        >
          <ReCAPTCHA
            sitekey={EnvironmentVariablesService.getEnv(
              'REACT_APP_SOCIAL_CAPTCHA_GOOGLE_ID'
            )}
            onChange={setCaptchaToken}
          />
        </div>
      )}
    </>
  )
}
