export const PROFILE_LIST = {
  BUTTON_LABEL: 'Add profile',
  SEARCH_INPUT_PLACEHOLDER: 'Find by company name',
  SEARCH_DROPDOWN_EMPTY_VALUE: 'Not found',
  ARCHIVE: 'Archive',
  TOOLTIP_CONTENT: 'Here you can find a list of all active profiles.',
}

export const PROFILE_LIST_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    QUESTION: 'Are you sure that you want to archive',
  },
  SUCCESS_MODAL: {
    TITLE: 'Profile was successfully archived',
    BUTTON_LABEL: 'Go to Profile archive page',
    SUBTITLE: 'profile was moved to the Archive',
  },
}
