import { SignInForm } from 'modules/auth/Signin/SigninForm'
import { WelcomeBlockView } from 'modules/auth/WelcomeBlockView'
import { Header } from 'modules/auth/WelcomeBlockView/components/Header'
import { AuthLayout } from 'layouts/AuthLayout'
import { WELCOME_BLOCK } from 'texts/welcomeBlock'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useApiRequest } from 'hooks/useApiRequest'
import { authApi } from 'api'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'

export const SignInPage = () => {
  const [searchParams] = useSearchParams()
  const navigation = useNavigate()

  const confirmRegistrationRequest = useApiRequest(
    (code) => authApi.confirmRegistration(code),
    '',
    true
  )

  useEffect(() => {
    const code = searchParams.get('code')
    if (code) {
      const confirmRegistration = async () => {
        const response = await confirmRegistrationRequest.apiRequest(code)
        if (response?.data.companyId) {
          GTM.dataLayer({
            user_id: response?.data.companyId,
            event: DATA_LAYER_EVENT.GENERATE_LEAD,
          })
          navigation(0)
        }
      }

      confirmRegistration()
    }
  }, [])

  return (
    <AuthLayout
      WelcomeBlock={
        <WelcomeBlockView
          Header={() => <Header title={WELCOME_BLOCK.HEADER.SIGN_IN.TITLE} />}
        />
      }
      Form={<SignInForm />}
    />
  )
}
