import { LOCATION_WEEK_DAY } from 'texts/locationDetails'
import { Input, Typography } from '@frontend/design_system'
import { WeekDayProps } from './types'
import { WEEKDAYS, WEEKDAYS_SHORT } from 'texts/uiTexts'
import styles from './styles.module.scss'
import { formatTime } from 'utils/dateFormat'

export const WeekDay = ({
  day,
  workingHours,
  handleChange,
  onBlur,
}: WeekDayProps) => {
  const handleTimeChange = (value: string, key: string) => {
    handleChange({ ...workingHours, [key]: value })
  }

  const isShouldRenderLabel = day === WEEKDAYS.MONDAY

  return (
    <div className={styles.item}>
      <Typography
        Tag="div"
        name="Button2"
        markColor="red600Master"
        className={styles.day}
      >
        {WEEKDAYS_SHORT[day]}
      </Typography>
      <div className={styles.items}>
        <div className={styles['form-item']}>
          <Input
            value={formatTime(workingHours.dayStart)}
            placeholder=""
            size="large"
            type="time"
            spaceForError="none"
            onChange={(value: string) => handleTimeChange(value, 'dayStart')}
            label={isShouldRenderLabel ? LOCATION_WEEK_DAY.START_WORK : ''}
            onBlur={onBlur}
            isTimeVariant
          />
        </div>
        <div className={styles['form-item']}>
          <Input
            value={formatTime(workingHours.dayEnd)}
            placeholder=""
            size="large"
            type="time"
            spaceForError="none"
            onChange={(value: string) => handleTimeChange(value, 'dayEnd')}
            onBlur={onBlur}
            label={isShouldRenderLabel ? LOCATION_WEEK_DAY.FINISH_WORK : ''}
            isTimeVariant
          />
        </div>
        <div className={styles['form-item']}>
          <Input
            value={formatTime(workingHours.dayStart2)}
            placeholder=""
            size="large"
            type="time"
            spaceForError="none"
            onChange={(value: string) => handleTimeChange(value, 'dayStart2')}
            onBlur={onBlur}
            label={isShouldRenderLabel ? LOCATION_WEEK_DAY.START_WORK : ''}
            isTimeVariant
          />
        </div>
        <div className={styles['form-item']}>
          <Input
            value={formatTime(workingHours.dayEnd2)}
            placeholder=""
            size="large"
            type="time"
            spaceForError="none"
            onChange={(value: string) => handleTimeChange(value, 'dayEnd2')}
            onBlur={onBlur}
            label={isShouldRenderLabel ? LOCATION_WEEK_DAY.FINISH_WORK : ''}
            isTimeVariant
          />
        </div>
      </div>
    </div>
  )
}
