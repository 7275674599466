import { Container } from 'ui/molecules/Container'
import { MENU_NAMES } from 'texts/menuNames'
import { isOperatorSelector } from 'redux/login/selectors'
import { useAppSelector } from 'redux/hooks'
import { Breadcrumb } from 'ui/atoms/Breadcrumbs/types'
import styles from './styles.module.scss'
import { Navigation } from './components/Navigation'
import { ReactNode, useEffect, useState } from 'react'
import { HistoryList } from '../HistoryList'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { ITermsAndConditionsItem } from 'api/terms/types'
import { useApiRequest } from 'hooks/useApiRequest'
import { useCompanyId } from 'hooks/useCompanyId'
import { termsApi } from 'api'

export const RENTAL_INCLUDES_TYPE = 2

interface TermsAndConditionsProps {
  breadcrumbs?: Breadcrumb[]
  current: string
  children:
    | ReactNode
    | ((data?: ITermsAndConditionsItem, isHistoryExist?: boolean) => ReactNode)
  termConditionTypesId?: TERM_CONDITIONS_TYPES
}

export const TermsAndConditionsContent = ({
  breadcrumbs,
  current,
  children,
  termConditionTypesId,
}: TermsAndConditionsProps) => {
  const isOperator = useAppSelector(isOperatorSelector)
  const companyId = useCompanyId()
  const [editData, setEditData] = useState<ITermsAndConditionsItem>()
  const [historyList, setHistoryList] = useState<ITermsAndConditionsItem[]>([])

  const getTermsListRequest = useApiRequest(
    (termConditionTypesId) =>
      termsApi.getTermConditionListRequest(companyId, termConditionTypesId),
    undefined,
    true
  )
  const getList = async () => {
    const response = await getTermsListRequest.apiRequest(termConditionTypesId)
    if (response) {
      setHistoryList(response.data.termConditions)
    }
  }
  useEffect(() => {
    getList()
  }, [termConditionTypesId])

  return (
    <Container
      title={MENU_NAMES.TERMS_AND_CONDITIONS}
      breadcrumbList={breadcrumbs}
      currentPageLabel={MENU_NAMES.TERMS_AND_CONDITIONS}
      withNavigation={isOperator}
    >
      <div className={styles.container}>
        <Navigation current={current} />
        {!getTermsListRequest.loading && (
          <div className={styles.content}>
            {typeof children === 'function'
              ? children(editData, !!historyList.length)
              : children}
            {termConditionTypesId && (
              <HistoryList
                termConditionTypesId={termConditionTypesId}
                setEditData={setEditData}
                editDataId={editData?.id}
                historyList={historyList}
                getList={getList}
              />
            )}
          </div>
        )}
      </div>
    </Container>
  )
}
