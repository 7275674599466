import { referencesApi, ReferencesApiTypes } from 'api'
import { useApiRequest } from 'hooks/useApiRequest'
import { useEffect, useState } from 'react'
import { MultiselectItem } from 'types/multiselect'

export const useGetLocations = (companyId: string) => {
  const [locations, setLocations] = useState<
    ReferencesApiTypes.LocationsResponse['items']
  >([])

  const { apiRequest, loading: locationsLoading } = useApiRequest(() =>
    referencesApi.getAllCompanyHirePoints(companyId)
  )

  const getCurrentLocations = (currentLocations: number[]) =>
    currentLocations.reduce((acc, currentLocation) => {
      const location = locations.find(
        (location) => Number(location.id) === Number(currentLocation)
      )
      if (location) {
        acc.push({
          label: location.name || '',
          value: location.id || '',
        })
      }
      return acc
    }, [] as MultiselectItem[])

  useEffect(() => {
    const fetchLocations = async () => {
      const response = await apiRequest()
      if (response) {
        setLocations(response.data.items)
      }
    }
    fetchLocations()
  }, [])

  return { locations, getCurrentLocations, locationsLoading }
}
